// Angular Imports
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from "rxjs/operators";
import { environment } from '../../../environments/environment';

// Services
import { UtilityService } from '../static/utility.service';

// Models
import { ProductView, Product } from 'src/platform-app/models/product.model';

@Injectable()
export class ProductService {
    public baseUrl: string = environment.endpoint;

    constructor(
        private http: HttpClient,
        private utilityService: UtilityService
    ) { }

    GetAllProducts(): Observable<ProductView[]> {
        const url: string = this.baseUrl + '/api/Product/GetAll';
        return this.http.get<ProductView[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetProductsByBusinessId(businessId: number): Observable<ProductView[]> {
        const url: string = this.baseUrl + '/api/Product/Business/' + businessId + '/GetAll';
        return this.http.get<ProductView[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetProductsByFamilyId(familyId: number): Observable<ProductView[]> {
        const url: string = this.baseUrl + '/api/Product/Family/' + familyId + '/GetAll';
        return this.http.get<ProductView[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetProductsByFamilyAndLineId(familyId: number, lineId: number): Observable<ProductView[]> {
        const url: string = this.baseUrl + '/api/Product/Family/' + familyId + '/Line/' + lineId + '/GetAll';
        return this.http.get<ProductView[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetActiveProductsByFamilyAndLineId(familyId: number, lineId: number): Observable<any[]> {
        const url: string = this.baseUrl + '/api/Scheduler/Family/' + familyId + '/GetProducts/Line/' + lineId;
        return this.http.get<ProductView[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    CreateProduct(product: Product): Observable<ProductView> {
        const url: string = this.baseUrl + '/api/Product/Create';
        return this.http.post(url, product).pipe(
            map(this.utilityService.extractData)
        );
    }

    ImportProducts(products: any[]): Observable<any> {
        const url: string = this.baseUrl + '/api/Product/Import';
        return this.http.post(url, products).pipe(
            map(this.utilityService.extractData)
        );
    }

    ImportProductInventory(inventoryArray: any[]): Observable<any> {
        const url: string = this.baseUrl + '/api/Inventory/Import';
        return this.http.post(url, inventoryArray).pipe(
            map(this.utilityService.extractData)
        );
    }

    AddProductsToLine(lineId: number, lineProducts: any[]): Observable<ProductView[]> {
        const url: string = this.baseUrl + '/api/Line/' + lineId + '/Product/Add';
        return this.http.post(url, lineProducts).pipe(
            map(this.utilityService.extractData)
        );
    }

    EditProduct(productId: number, product: Product): Observable<ProductView> {
        const url: string = this.baseUrl + '/api/Product/' + productId + '/Edit';
        return this.http.put(url, product).pipe(
            map(this.utilityService.extractData)
        );
    }

    DeleteProduct(productId: number): Observable<any> {
        const url: string = this.baseUrl + '/api/Product/' + productId + '/Delete';
        return this.http.delete(url).pipe(
            map(this.utilityService.extractData)
        );
    }
}