// Model Imports
import { SiteView } from "./site.model";
import { BusinessView } from "./business.model";
import { CompanyView } from "./company.model";
import { AccountSmallView } from "./account.model";
import { LineView } from "./line.model";

// Technology Plan Category
export class TechnologyPlanCategory {
    name: string;
}

export class TechnologyPlanCategoryView {
    id: number;
    name: string;
    siteId: number;
    companyId: number;
}

// Technology Plan Priority
export class TechnologyPlanPriority {
    name: string;
}

export class TechnologyPlanPriorityView {
    id: number;
    name: string;
    siteId: number;
    companyId: number;
}

// Technology Plan Instance
export class TechnologyPlanInstance {
    name: string;
}

export class TechnologyPlanInstanceView {
    id: number;
    name: string;
    years: TechnologyPlanYearView[];
    default: boolean;
}

// Technology Plan Year
export class TechnologyPlanYear {
    name: string;
}

export class TechnologyPlanYearView {
    id: number;
    name: string;
    cards: TechnologyPlanCardView[];
}

// Technology Plan Card
export class TechnologyPlanCard {
    name: string;
    description: string;
    categoryId: number;
    siteId: number;
    businessId: number;
    contributionMargin: number;
    assignedUserId: string;
    priorityId: number;
    companyId: number;
    yearId: number;
    instanceId: number;
    lineId: number;
    isDeleted: boolean;
    startDate: Date;
    endDate: Date;
    creationDate: Date;
    publishDate: Date;
    progress: number;
    active: boolean;

    uptime: number = 0;
    fpfq: number = 0;
    maxCapacity: number = 0;
    fixedCost: number = 0;
    rawMaterialCostPerTon: number = 0;
    otherVariableCostPerTon: number = 0;
    totalCompany: number = 0;
    totalContract: number = 0;
    oshaRecordable: number = 0;

    investment: number;
    rate: number;
    cashFlow1: number;
    cashFlow2: number;
    cashFlow3: number;
    cashFlow4: number;
    cashFlow5: number;
}

export class TechnologyPlanCardView {
    id: number;
    name: string;
    description: string;
    category: TechnologyPlanCategoryView;
    site: SiteView;
    business: BusinessView;
    contributionMargin: number;
    assignedUser: AccountSmallView;
    priority: TechnologyPlanPriorityView;
    company: CompanyView;
    year: TechnologyPlanYearView;
    instance: TechnologyPlanInstanceView;
    line: LineView;
    isDeleted: boolean;
    startDate: Date;
    endDate: Date;
    creationDate: Date;
    publishDate: Date;
    progress: number;
    active: boolean;

    uptime: number = 0;
    fpfq: number = 0;
    maxCapacity: number = 0;
    fixedCost: number = 0;
    rawMaterialCostPerTon: number = 0;
    otherVariableCostPerTon: number = 0;
    totalCompany: number = 0;
    totalContract: number = 0;
    oshaRecordable: number = 0;

    instances: number[];
    investment: number;
    rate: number;
    cashFlow1: number;
    cashFlow2: number;
    cashFlow3: number;
    cashFlow4: number;
    cashFlow5: number;
    npv: number;
}

// Technology Plan Metrics Object
export class TechnologyPlanMetricsObject {
    year: number;
    metrics: any;
}

export class TechnologyPlanTotals {
    prod: number = 0;
    cost: number = 0;
}