// Angular Imports
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

// Services
import { StorageService } from 'src/platform-app/services/static/storage.service';
import { AccountService } from 'src/platform-app/services/apis/account.service';
import { CompanyService } from 'src/platform-app/services/apis/company.service';
import { SpinnerService } from 'src/platform-app/services/static/spinner.service';

// Models
import { CompanyView } from 'src/platform-app/models/company.model';
import { SiteView } from 'src/platform-app/models/site.model';
import { BusinessView } from 'src/platform-app/models/business.model';
import { LineView } from 'src/platform-app/models/line.model';
import { PageView } from 'src/platform-app/models/utilitymodels';
import { AccountSmallView } from 'src/platform-app/models/account.model';

// Thir Party Libraries
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})

export class BreadcrumbsComponent implements OnInit, OnDestroy {
  @Input() public currentRoute: string;
  @Input() public module: PageView;

  public company: CompanyView;
  public site: SiteView;
  public business: BusinessView;
  public line: LineView;
  public isAdmin = false;
  public account: AccountSmallView;
  public companies: CompanyView[];
  public subscriptions = new Subscription();

  constructor(
    private router: Router,
    private storageService: StorageService,
    private accountService: AccountService,
    private companyService: CompanyService,
    private spinnerService: SpinnerService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.subscriptions.add(
      this.storageService.account.subscribe((account: AccountSmallView) => {
        if (account) {
          this.account = account;

          for (let role of account.roles) {
            if (role.name === 'MFG Admin') {
              this.isAdmin = true;

              this.subscriptions.add(
                this.companyService.GetAllCompanies().subscribe((companies: CompanyView[]) => {
                  this.companies = companies;
                })
              );
            }
          }
        }
      })
    );

    this.subscriptions.add(
      this.storageService.company.subscribe((company: CompanyView) => {
        this.company = company;
      })
    );

    this.subscriptions.add(
      this.storageService.currentSite.subscribe((site: SiteView) => {
        this.site = site;
      })
    );

    this.subscriptions.add(
      this.storageService.currentBusiness.subscribe((business: BusinessView) => {
        this.business = business;
      })
    );

    this.subscriptions.add(
      this.storageService.currentLine.subscribe((line: LineView) => {
        this.line = line;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  GetContainerClass() {
    if (this.module) {
      let cssClass: string;
      if (this.module.name === 'Technology Plan' || this.module.name === 'Action Plan' || this.module.name === 'Planner' || this.module.name === 'Scheduler' || this.module.name === 'Polymer GNO' || this.module.name === 'Chemical GNO') {
        cssClass = 'mfg-container-fluid';
      } else if (this.module.name === 'Notifications' || this.module.name === 'Release Notes') {
        cssClass = 'mfg-container-md';
      } else if (this.module.name === 'Account') {
        cssClass = 'mfg-container-sm';
      } else {
        cssClass = 'mfg-container-xl';
      }

      return cssClass;
    }
  }

  EditDefaultCompany(companyId: number) {
    this.spinnerService.showGlobalSpinner.next(true);
    this.subscriptions.add(
      this.accountService.EditDefaultCompany(companyId).subscribe((response: any) => {
        this.account.company = this.companies.filter(company => company.id === companyId)[0];
        this.storageService.ResetCompanyData(); // Resetting company data
        this.storageService.company.next(this.account.company);
        this.storageService.account.next(this.account);
        this.router.navigateByUrl('/');
        this.snackBar.open('Your account company was successfully updated', 'close', { duration: 2500, panelClass: ['mfg-background-success'] });
      }, (error) => {
        console.log(error);
        this.spinnerService.showGlobalSpinner.next(false);
      })
    );
  }
}
