// Angular Imports
import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';

// Services
import { UtilityService } from 'src/platform-app/services/static/utility.service';

// Models
// import { ActionPlanCategory, ActionPlanCategoryView } from 'src/platform-app/components/pages/action-plan/action-plan.component';

// Third Party Imports
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActionPlanCategory, ActionPlanCategoryView } from 'src/platform-app/models/action-plan.model';

@Component({
  selector: 'action-plan-category-modal',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})

export class ActionPlanCategoryModalComponent implements OnInit, OnDestroy {
  public eventFunction: any;
  public categories: ActionPlanCategory[] = [];
  public categoryIdCount = 0;
  @ViewChild('ModalForm', { static: true }) public modalForm: NgForm;
  public subscriptions = new Subscription();

  constructor(
    private dialogRef: MatDialogRef<ActionPlanCategoryModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private utilityService: UtilityService
  ) { }

  ngOnInit() {
    this.categories = this.data.categories;
    this.categoryIdCount = this.data.categoryIdCount;
    this.eventFunction = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        this.Save();
      }
    }

    document.addEventListener('keyup', this.eventFunction, false); // Submitting popup on press of Enter key
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    document.removeEventListener('keyup', this.eventFunction, false); // Removing event listener
  }

  CreateCategory() {
    this.categoryIdCount++;
    let category = new ActionPlanCategoryView();
    category.id = this.categoryIdCount;
    this.categories.push(category);
  }

  DeleteCategory(categoryIndex: number) {
    this.categories.splice(categoryIndex, 1);
  }

  Cancel() {
    this.dialogRef.close();
    document.removeEventListener('keyup', this.eventFunction, false); // Removing event listener
  }

  Save() {
    if (this.modalForm.valid) {
      this.dialogRef.close(this.categories);
      document.removeEventListener('keyup', this.eventFunction, false); // Removing event listener
    }
  }

  TrackByFunction(index, item) {
    return item.id;
  }
}
