// Angular Imports
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from "rxjs/operators";
import { environment } from '../../../environments/environment';

// Services
import { UtilityService } from '../static/utility.service';

// Models
import { CompanyView, Company } from '../../models/company.model';

@Injectable()
export class CompanyService {
    public baseUrl: string = environment.endpoint;

    constructor(
        private http: HttpClient,
        private utilityService: UtilityService
    ) { }

    CreateCompany(company: Company): Observable<CompanyView> {
        const url: string = this.baseUrl + '/api/Company/Create';
        return this.http.post<Company>(url, company).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetAllCompanies(): Observable<CompanyView[]> {
        const url: string = this.baseUrl + '/api/Company/GetAll';
        return this.http.get<CompanyView[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetCompany(): Observable<CompanyView> {
        const url: string = this.baseUrl + '/api/Company/Get';
        return this.http.get<CompanyView>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    EditCompany(companyId: number, company: Company): Observable<CompanyView> {
        const url: string = this.baseUrl + '/api/Company/' + companyId + '/Edit';
        return this.http.put<CompanyView>(url, company).pipe(
            map(this.utilityService.extractData)
        );
    }

    DeleteCompany(companyId: number): Observable<any> {
        const url: string = this.baseUrl + '/api/Company/' + companyId + '/Delete';
        return this.http.delete(url).pipe(
            map(this.utilityService.extractData)
        );
    }
}