// Models
import { BusinessView } from "./business.model";
import { SiteView } from "./site.model";

export class RawMaterial {
    name: string;
    businessId: number;
}

export class RawMaterialView {
    id: number;
    name: string;
    business: BusinessView;
}

export class RawMaterialPrice {
    price: number;
    rawMaterialId: number;
    siteId: number;
}

export class RawMaterialPriceView {
    id: number;
    price: number;
    rawMaterial: RawMaterialView;
    site: SiteView;
}