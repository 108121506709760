// Angular Imports
import { Routes } from '@angular/router';

// Services
import { AuthGuardService } from './services/guards/authguard.service';
import { RoleGuardService } from './services/guards/roleguard.service';
import { SplashGuardService } from './services/guards/splashguard.service';

// Routes
export const AppRoutes: Routes = [
    // Modules

    //// Dashboard
    { path: 'dashboard/business/:businessId/line/:lineId', loadChildren: () => import('./components/pages/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuardService] },
    { path: 'dashboard/site/:siteId/line/:lineId', loadChildren: () => import('./components/pages/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuardService] },
    { path: 'dashboard/line/:lineId', loadChildren: () => import('./components/pages/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuardService] },
    { path: 'dashboard/site/:siteId/business/:businessId/line/:lineId', data: { filterBy: 'site' }, loadChildren: () => import('./components/pages/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuardService] },
    { path: 'dashboard/site/:siteId/business/:businessId', data: { filterBy: 'site' }, loadChildren: () => import('./components/pages/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuardService] },
    { path: 'dashboard/business/:businessId', loadChildren: () => import('./components/pages/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuardService] },
    { path: 'dashboard/site/:siteId', loadChildren: () => import('./components/pages/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuardService] },
    { path: 'dashboard', loadChildren: () => import('./components/pages/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuardService] },

    //// Site Performance
    { path: 'site-performance/site/:siteId', loadChildren: () => import('./components/pages/site-performance/site-performance.module').then(m => m.SitePerformanceModule), canActivate: [AuthGuardService, RoleGuardService], data: { expectedRole: 'Site Performance' } },

    //// Action Plan
    { path: 'action-plan/site/:siteId/instance/:instanceId', loadChildren: () => import('./components/pages/action-plan/action-plan.module').then(m => m.ActionPlanModule), canActivate: [AuthGuardService, RoleGuardService], data: { expectedRole: 'Action Plan' } },
    { path: 'action-plan/site/:siteId', loadChildren: () => import('./components/pages/action-plan/action-plan.module').then(m => m.ActionPlanModule), canActivate: [AuthGuardService, RoleGuardService], data: { expectedRole: 'Action Plan' } },

    //// Technology Plan
    { path: 'technology-plan/business/:businessId', loadChildren: () => import('./components/pages/technology-plan/technology-plan.module').then(m => m.TechnologyPlanModule), canActivate: [AuthGuardService, RoleGuardService, SplashGuardService], data: { expectedRole: 'Technology Plan' } },

    //// Planner
    { path: 'planner/business/:businessId', loadChildren: () => import('./components/pages/planner/planner.module').then(m => m.PlannerModule), canActivate: [AuthGuardService, RoleGuardService, SplashGuardService], data: { expectedRole: 'Planner' } },

    //// Scheduler
    { path: 'scheduler/business/:businessId', loadChildren: () => import('./components/pages/scheduler/scheduler.module').then(m => m.SchedulerModule), canActivate: [AuthGuardService, RoleGuardService], data: { expectedRole: 'Scheduler' } },

    //// Sandbox
    { path: 'sandbox/site/:siteId', loadChildren: () => import('./components/pages/sandbox/sandbox.module').then(m => m.SandboxModule), canActivate: [AuthGuardService, RoleGuardService, SplashGuardService], data: { expectedRole: 'Sandbox' } },

    //// Simulation
    { path: 'simulation', loadChildren: () => import('./components/pages/simulation/simulation.module').then(m => m.SimulationModule), canActivate: [AuthGuardService, RoleGuardService, SplashGuardService], data: { expectedRole: 'Simulation' } },

    //// Profit Thread
    { path: 'profit-thread', loadChildren: () => import('./components/pages/profit-thread/profit-thread.module').then(m => m.ProfitThreadModule), canActivate: [AuthGuardService, RoleGuardService, SplashGuardService], data: { expectedRole: 'ProfitThread' } },

    //// Lab
    { path: 'lab', loadChildren: () => import('./components/pages/lab/lab.module').then(m => m.LabModule), canActivate: [AuthGuardService, RoleGuardService, SplashGuardService], data: { expectedRole: 'Lab' } },

    //// Knowledge Hug
    { path: 'knowledge-hub', loadChildren: () => import('./components/pages/knowledge-hub/knowledge-hub.module').then(m => m.KnowledgeHubModule), canActivate: [AuthGuardService] },

    //// Document Manager
    { path: 'document-manager', loadChildren: () => import('./components/pages/document-manager/document-manager.module').then(m => m.DocumentManagerModule), canActivate: [AuthGuardService] },

    //// Report
    { path: 'report', loadChildren: () => import('./components/pages/report/report.module').then(m => m.ReportModule), canActivate: [AuthGuardService] },

    { path: 'splash/:moduleName', loadChildren: () => import('./components/pages/module-splash/module-splash.module').then(m => m.ModuleSplashModule), canActivate: [AuthGuardService] },
    { path: 'release-notes', loadChildren: () => import('./components/pages/release-notes/release-notes.module').then(m => m.ReleaseNotesModule), canActivate: [AuthGuardService] },
    { path: 'admin-panel', loadChildren: () => import('./components/pages/admin-panel/admin-panel.module').then(m => m.AdminPanelModule), canActivate: [AuthGuardService, RoleGuardService], data: { expectedRole: 'Admin Panel' } },

    { path: 'import', loadChildren: () => import('./components/pages/import/import.module').then(m => m.ImportModule), canActivate: [AuthGuardService] },

    { path: 'notifications', loadChildren: () => import('./components/pages/notifications/notifications.module').then(m => m.NotificationsModule), canActivate: [AuthGuardService] },
    { path: 'account', loadChildren: () => import('./components/pages/account/account.module').then(m => m.AccountModule), canActivate: [AuthGuardService] },
    { path: 'terms-conditions', loadChildren: () => import('./components/pages/terms-conditions/terms-conditions.module').then(m => m.TermsConditionsModule), canActivate: [AuthGuardService] },
    { path: 'privacy-policy', loadChildren: () => import('./components/pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule), canActivate: [AuthGuardService] },
    { path: 'password-reset', loadChildren: () => import('./components/pages/password-reset/password-reset.module').then(m => m.PasswordResetModule) },
    { path: 'login', loadChildren: () => import('./components/pages/login/login.module').then(m => m.LoginModule) },
    { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    { path: 'not-authorized', loadChildren: () => import('./components/pages/not-authorized/not-authorized.module').then(m => m.NotAuthorizedModule) },
    { path: 'not-found', loadChildren: () => import('./components/pages/not-found/not-found.module').then(m => m.NotFoundModule) },
    { path: '**', redirectTo: 'not-found' }
];