// Angular Imports
import { Component } from '@angular/core';

@Component({
  selector: 'spot-spinner',
  templateUrl: './spot-spinner.component.html',
  styleUrls: ['./spot-spinner.component.scss']
})

export class SpotSpinnerComponent { }
