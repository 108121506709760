// Angular Imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Components
import { FileUploadComponent } from './components/partials/file-upload/file-upload.component';
import { SpotSpinnerComponent } from './components/partials/spinners/spot-spinner/spot-spinner.component';

// Services 

// Pipes
import { Age } from './pipes/age.pipe';
import { SafeHtml } from './pipes/safe-html.pipe';
import { ShortNum } from './pipes/short-num.pipe';

// Third Party Imports
import { MatFormFieldModule, MatInputModule, MatSelectModule, MatSlideToggleModule, MatExpansionModule, MatTabsModule, MatDatepickerModule, MatNativeDateModule, MatRadioModule, MatSliderModule, MatProgressBarModule, MatBadgeModule, MatButtonToggleModule, MatCheckboxModule, MatButtonModule, MatTooltipModule, MatDialogModule, MatSnackBarModule, MatChipsModule, MatMenuModule } from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ClickOutsideModule } from 'ng-click-outside';
import { OrderModule } from 'ngx-order-pipe';
import { ColorPickerModule } from 'ngx-color-picker';
import { ResizableModule } from 'angular-resizable-element';
import { DragScrollModule } from 'ngx-drag-scroll';
import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
  declarations: [
    // Components
    FileUploadComponent,
    SpotSpinnerComponent,

    // Pipes
    Age,
    SafeHtml,
    ShortNum,
  ],
  imports: [
    CommonModule,

    // Material
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatTabsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatSliderModule,
    MatProgressBarModule,
    MatBadgeModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatButtonModule,
    MatTooltipModule,
    MatDialogModule,
    MatSnackBarModule,
    MatChipsModule,
    MatMenuModule,

    // Third Party
    DragDropModule,
    ResizableModule,
    ClickOutsideModule,
    OrderModule,
    ColorPickerModule,
    ImageCropperModule,
    DragScrollModule,
    NgxPaginationModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,

    // Material
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatTabsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatSliderModule,
    MatProgressBarModule,
    MatBadgeModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatButtonModule,
    MatTooltipModule,
    MatDialogModule,
    MatSnackBarModule,
    MatChipsModule,
    MatMenuModule,

    // Third Party
    DragDropModule,
    ResizableModule,
    ClickOutsideModule,
    OrderModule,
    ColorPickerModule,
    ImageCropperModule,
    DragScrollModule,
    NgxPaginationModule,

    // Components
    FileUploadComponent,
    SpotSpinnerComponent,

    // Pipes
    Age,
    SafeHtml,
    ShortNum,
  ],
  providers: [
    ShortNum
  ]
})

export class SharedModule { }