// Angular Imports
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';

// Services
import { StorageService} from 'src/platform-app/services/static/storage.service';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr'
import { FamilyScheduleView } from 'src/platform-app/models/scheduler.model';

// Models

// Third Party Imports
import * as signalR from '@aspnet/signalr';

@Injectable()
export class RealTimeService {
    public token: string;
    public hubConnection: HubConnection;
    public connectionCount: number = 0;
    public baseUrl: string = environment.endpoint;
    public schedule: BehaviorSubject<ScheduleObject> = new BehaviorSubject(null);
    public inventory: BehaviorSubject<InventoryObject> = new BehaviorSubject(null);

    constructor(
        private storageService: StorageService
    ) { }

    Connect() {
        this.storageService.accountToken.subscribe(token => { // Getting account token
            if (token) {
                this.token = token;
                this.InitilizeConnection(); // Initilizing real-time connection
            }
        });
    }

    InitilizeConnection() {
        this.connectionCount++; // Incrementing connection attempts
        this.hubConnection = new HubConnectionBuilder().withUrl(this.baseUrl + '/RealTimeHub', { accessTokenFactory: () => this.token }).configureLogging(signalR.LogLevel.None).build();
        this.hubConnection.keepAliveIntervalInMilliseconds = 3000;
        this.hubConnection.serverTimeoutInMilliseconds = 30000;

        this.hubConnection.start().then(() => {
            console.log('Real-time connection started');
            this.connectionCount = 0; // Reseting connection attempts
        }).catch(error => {
            console.log('Error while establishing real-time connection');
            this.AttemptRepeatedly();
        });

        this.hubConnection.onclose(() => {
            this.AttemptRepeatedly();
        });

        this.hubConnection.on('SchedulerScheduleUpdate', (type: string, payload: string) => {
            let scheduleObject = new ScheduleObject();
            scheduleObject.email = type;
            scheduleObject.schedulePayload = JSON.parse(payload);
            this.schedule.next(scheduleObject);
            console.log('Schedule push', scheduleObject);
        });

        this.hubConnection.on('SchedulerInventoryUpdate', (type: string, payload: string) => {
            let inventoryObject = new InventoryObject();
            inventoryObject.email = type;
            inventoryObject.inventoryPayload = JSON.parse(payload);
            this.inventory.next(inventoryObject);
            console.log('Inventory push', inventoryObject);
        });
    }

    AttemptRepeatedly() {
        if (this.connectionCount <= 10) {
            console.log('Real-time connection ended. Reconnecting in 3 minutes.');
            setTimeout(() => {
                this.InitilizeConnection();
            }, 180000);
        } else if (this.connectionCount > 10 && this.connectionCount !== 12) {
            console.log('Real-time connection failed. Please, contact administrator.');
        }
    }

    Disconnect() {
        if (this.hubConnection) {
            this.hubConnection.stop();
            this.hubConnection = null;
            this.connectionCount = 12; // Disable reconnection
        }
    }
}

export class ScheduleObject {
    public email: string;
    public schedulePayload: SchedulePayload;
}

export class SchedulePayload {
    public familyId: number;
    public instanceId: number;
    public lines: any[];
}

export class InventoryObject {
    public email: string;
    public inventoryPayload: InventoryPayload;
}

export class InventoryPayload {
    public familyId: number;
    public instanceId: number;
    public inventory: any[];
}