// Angular Imports
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Services
import { StorageService } from '../static/storage.service';
import { AccountService } from '../apis/account.service';

// Models
import { AccountSmallView } from 'src/platform-app/models/account.model';

@Injectable()
export class RoleGuardService implements CanActivate {
    constructor(
        private storageService: StorageService,
        private accountService: AccountService,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
        const expectedRole = route.data.expectedRole;
        let account: AccountSmallView;

        if (this.storageService.account.getValue()) { // Checking if the account Behavior Subject is set
            account = this.storageService.account.getValue();

            // Checking if role is assigned to the user
            if (account.roles.find((role) => role.name === expectedRole)) {
                return true;
            }

            // If account Behavior Subject is set but role is not found
            this.router.navigate(['/not-authorized']);
            return false;
        }

        return this.accountService.GetLoggedInAccount().pipe( // Getting the logged in account
            map((newAccount: AccountSmallView) => {
                account = newAccount;

                // Checking if role is assigned to the user
                if (account.roles.find((role) => role.name === expectedRole)) {
                    return true;
                }

                this.router.navigate(['/not-authorized']);
                return false;
            })
        );
    }
}