// Angular Imports
import { Component } from '@angular/core';

@Component({
  selector: 'top-spinner',
  templateUrl: './top-spinner.component.html',
  styleUrls: ['./top-spinner.component.scss']
})

export class TopSpinnerComponent { }
