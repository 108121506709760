import { BusinessView } from "./business.model";
import { FamilyView } from "./family.model";
import { Ingredient, IngredientView } from "./ingredient.model";

// Product
export class Product {
    name: string;
    color: string;
    description: string;
    clientId: number;
    businessId: number;
    familyId: number;
    maxRate: number;
    price: number;
    ingredients: Ingredient[];
}

export class ProductView {
    id: number;
    name: string;
    color: string;
    description: string;
    clientId: number;
    business: BusinessView;
    family: FamilyView;
    maxRate: number;
    active?: boolean;
    price: number;
    ingredients: IngredientView[];
}