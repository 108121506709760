// Angular Imports
import { OnInit, Directive, Input, ViewContainerRef, TemplateRef } from '@angular/core';
import { map } from 'rxjs/operators';

// Services
import { AccountService } from 'src/platform-app/services/apis/account.service';
import { StorageService } from 'src/platform-app/services/static/storage.service';

// Models
import { AccountSmallView } from '../models/account.model';

@Directive({
    selector: '[hasRole]'
})

export class HasRoleDirective implements OnInit {
    @Input() public hasRole: string;
    public isVisible = false;

    constructor(
        private accountService: AccountService,
        private storageService: StorageService,
        private viewContainerRef: ViewContainerRef,
        private templateRef: TemplateRef<any>,
    ) { }

    ngOnInit() {
        if (this.hasRole) {
            let account: AccountSmallView;

            if (this.storageService.account.getValue()) { // Checking if the account Behavior Subject is set
                account = this.storageService.account.getValue();
                // Checking if role is assigned to the user
                if (account.roles.find((role) => role.name === this.hasRole)) {
                    if (!this.isVisible) {
                        this.isVisible = true;
                        this.viewContainerRef.createEmbeddedView(this.templateRef);
                    }
                } else {
                    // If account Behavior Subject is set but role is not found
                    this.isVisible = false;
                    this.viewContainerRef.clear();
                }
            } else {
                this.accountService.GetLoggedInAccount().pipe( // Getting the logged in account
                    map((newAccount: AccountSmallView) => {
                        account = newAccount;

                        // Checking if role is assigned to the user
                        if (account.roles.find((role) => role.name === this.hasRole)) {
                            if (!this.isVisible) {
                                this.isVisible = true;
                                this.viewContainerRef.createEmbeddedView(this.templateRef);
                            }
                        } else {
                            // If account Behavior Subject is set but role is not found
                            this.isVisible = false;
                            this.viewContainerRef.clear();
                        }
                    })
                );
            }
        } else {
            this.isVisible = true;
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
    }
}