// Angular Imports
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from "rxjs/operators";
import { environment } from '../../../environments/environment';

// Services
import { UtilityService } from '../static/utility.service';

// Models
import { LineView, Line } from 'src/platform-app/models/line.model';

@Injectable()
export class LineService {
    public baseUrl: string = environment.endpoint;

    constructor(
        private http: HttpClient,
        private utilityService: UtilityService
    ) { }

    CreateLine(line: Line): Observable<LineView> {
        const url: string = this.baseUrl + '/api/Line/Create';
        return this.http.post<Line>(url, line).pipe(
            map(this.utilityService.extractData)
        );
    }

    ImportLineProducts(entry: any[]): Observable<any> {
        const url: string = this.baseUrl + '/api/Line/Product/Import';
        return this.http.post<Line>(url, entry).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetAllLines(): Observable<LineView[]> {
        const url: string = this.baseUrl + '/api/Line/GetAll';
        return this.http.get<LineView[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetLineById(lineId: number): Observable<LineView> {
        const url: string = this.baseUrl + '/api/Line/' + lineId + '/Get';
        return this.http.get<LineView[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetLinesBySiteId(siteId: number): Observable<LineView[]> {
        const url: string = this.baseUrl + '/api/Line/Site/' + siteId + '/GetAll';
        return this.http.get<LineView[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetLinesByBusinessId(businessId: number): Observable<LineView[]> {
        const url: string = this.baseUrl + '/api/Line/Business/' + businessId + '/GetAll';
        return this.http.get<LineView[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetLinesBySiteAndBusinessId(siteId: number, businessId: number): Observable<LineView[]> {
        const url: string = this.baseUrl + '/api/Line/Site/' + siteId + '/Business/' + businessId + '/GetAll';
        return this.http.get<LineView[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetLinesByFamilyId(familyId: number): Observable<LineView[]> {
        const url: string = this.baseUrl + '/api/Scheduler/Family/' + familyId + '/GetLines';
        return this.http.get<LineView[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    EditLine(lineId: number, line: Line): Observable<LineView> {
        const url: string = this.baseUrl + '/api/Line/' + lineId + '/Edit';
        return this.http.put<LineView>(url, line).pipe(
            map(this.utilityService.extractData)
        );
    }

    DeleteLine(lineId: number): Observable<any> {
        const url: string = this.baseUrl + '/api/Line/' + lineId + '/Delete';
        return this.http.delete(url).pipe(
            map(this.utilityService.extractData)
        );
    }
}