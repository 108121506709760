// Angular Imports
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from "rxjs/operators";
import { environment } from '../../../environments/environment';

// Services
import { UtilityService } from '../static/utility.service';

// Models
import { PlannerInstance, PlannerInstanceView } from 'src/platform-app/models/planner.model';

@Injectable()
export class PlannerService {
    public baseUrl: string = environment.endpoint;

    constructor(
        private http: HttpClient,
        private utilityService: UtilityService
    ) { }

    // Planner Instance

    CreatePlannerInstanceByBusinessId(businessId: number, instance: PlannerInstance): Observable<PlannerInstanceView> {
        const url: string = this.baseUrl + '/api/Planner/Business/' + businessId + '/Instance/Create';
        return this.http.post(url, instance).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetPlannerInstancesByBusinessId(businessId: number): Observable<PlannerInstanceView[]> {
        const url: string = this.baseUrl + '/api/Planner/Business/' + businessId + '/Instance/GetAll';
        return this.http.get<PlannerInstanceView[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetPlannerDefaultInstanceByBusinessId(businessId: number): Observable<PlannerInstanceView> {
        const url: string = this.baseUrl + '/api/Planner/Business/' + businessId + '/Instance/GetDefault';
        return this.http.get<PlannerInstanceView>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetPlannerInstanceById(instanceId: number): Observable<PlannerInstanceView> {
        const url: string = this.baseUrl + '/api/Planner/Instance/' + instanceId + '/Get';
        return this.http.get<PlannerInstanceView>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    SetPlannerDefaultInstance(instanceId: number): Observable<PlannerInstanceView> {
        const url: string = this.baseUrl + '/api/ActionPlan/Instance/' + instanceId + '/SetDefault';
        return this.http.put(url, {}).pipe(
            map(this.utilityService.extractData)
        );
    }

    CopyPlannerInstance(instanceId: number, name: string): Observable<PlannerInstanceView> {
        const url: string = this.baseUrl + '/api/Planner/Instance/' + instanceId + '/Copy?name=' + name;
        return this.http.post(url, null).pipe(
            map(this.utilityService.extractData)
        );
    }

    EditPlannerInstance(instanceId: number, instance: PlannerInstance): Observable<PlannerInstanceView> {
        const url: string = this.baseUrl + '/api/Planner/Instance/' + instanceId + '/Edit';
        return this.http.put(url, instance).pipe(
            map(this.utilityService.extractData)
        );
    }

    DeletePlannerInstance(instanceId: number): Observable<PlannerInstanceView> {
        const url: string = this.baseUrl + '/api/Planner/Instance/' + instanceId + '/Delete';
        return this.http.delete(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    EditPlannerByMetricsId(metricsId: number, variable: string, value: number): Observable<any> {
        const url: string = this.baseUrl + '/api/Planner/Id/' + metricsId + '/Variable/' + variable + '/Value/' + value;
        return this.http.put(url, null).pipe(
            map(this.utilityService.extractData)
        );
    }

    ImportPlannerForecastByBusiness(businessId: number, data: any): Observable<any> {
        const url: string = this.baseUrl + '/api/Planner/Business/' + businessId + '/PlannerForecast/Import';
        return this.http.post(url, data).pipe(
            map(this.utilityService.extractData)
        );
    }
}