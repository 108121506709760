// Angular Imports
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class SplashGuardService implements CanActivate {
    constructor(
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
        this.router.navigate(['/splash', route.url[0].path]);
        return false;
    }
}