// Angular Imports
import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { enableProdMode } from '@angular/core';

// Main Component
import { PlatformAppComponent } from './platform-app.component';

// Partials
import { GlobalSpinnerComponent } from './components/partials/spinners/global-spinner/global-spinner.component';
import { FooterComponent } from './components/partials/footer/footer.component';
import { LeftNavigationComponent } from './components/partials/left-navigation/left-navigation.component';
import { BreadcrumbsComponent } from './components/partials/breadcrumbs/breadcrumbs.component';
import { TopSpinnerComponent } from './components/partials/spinners/top-spinner/top-spinner.component';
import { NavigationItemComponent } from './components/partials/left-navigation/navigation-item/navigation-item.component';
import { IconMenuComponent } from './components/partials/icon-menu/icon-menu.component';
import { TopNavigationComponent } from './components/partials/top-navigation/top-navigation.component';

// Modals 
import { ConfirmationDialogModalComponent } from './components/modals/confirmation-dialog/confirmation-dialog.component';
import { TechnologyPlanCardModalComponent } from './components/modals/technology-plan/card/card.component';
import { SchedulerBatchModalComponent } from './components/modals/scheduler/batch/batch.component';
import { SchedulerBatchMultipleEditModalComponent } from './components/modals/scheduler/batch-multiple-edit/batch-multiple-edit.component';
import { AccountModalComponent } from './components/modals/admin-panel/account/account.component';
import { NamingDialogModalComponent } from './components/modals/naming-dialog/naming-dialog.component';
import { FileUploadModalComponent } from './components/modals/file-upload/file-upload.component';
import { InformationDialogModalComponent } from './components/modals/information-dialog/information-dialog.component';
import { DashboardCardModalComponent } from './components/modals/dashboard/card/card.component';
import { ProductModalComponent } from './components/modals/admin-panel/product/product.component';
import { RawMaterialModalComponent } from './components/modals/admin-panel/raw-material/raw-material.component';
import { RawMaterialPriceModalComponent } from './components/modals/admin-panel/raw-material-price/raw-material-price.component';
import { FilterSchedulerLinesModalComponent } from './components/modals/scheduler/filter-scheduler-lines/filter-scheduler-lines.component';
import { SchedulerTransferModalComponent } from './components/modals/scheduler/transfer/transfer.component';
import { BillOfMaterialsModalComponent } from './components/modals/scheduler/analysis/bill-of-materials/bill-of-materials.component';
import { ProductWheelModalComponent } from './components/modals/scheduler/settings/product-wheel/product-wheel.component';

//// Action Plan Modals
import { ActionPlanCategoryModalComponent } from './components/modals/action-plan/category/category.component';
import { ActionPlanProjectModalComponent } from './components/modals/action-plan/project/project.component';
import { ActionPlanTaskModalComponent } from './components/modals/action-plan/project/task/task.component';

// Services 
import { AuthenticationService } from './services/apis/authentication.service';
import { ActionPlanService } from './services/apis/action-plan.service';
import { AdminService } from './services/apis/admin.service';
import { AccountService } from './services/apis/account.service';
import { BlobService } from './services/apis/blob.service';
import { BusinessService } from './services/apis/business.service';
import { CompanyService } from './services/apis/company.service';
import { DashboardService } from './services/apis/dashboard.service';
import { FamilyService } from './services/apis/family.service';
import { RegionService } from './services/apis/region.service';
import { LineService } from './services/apis/line.service';
import { MetricsService } from './services/apis/metrics.service';
import { NotificationService } from './services/apis/notification.service';
import { ProductService } from './services/apis/product.service';
import { RawMaterialService } from './services/apis/raw-material.service';
import { ProfitThreadService } from './services/apis/profit-thread.service';
import { SchedulerService } from './services/apis/scheduler.service';
import { SandboxService } from './services/apis/sandbox.service'
import { PlannerService } from './services/apis/planner.service';
import { RealTimeService } from './services/apis/real-time.service';
import { SitePerformanceService } from './services/apis/site-performance.service';
import { SiteService } from './services/apis/site.service';
import { GradeService } from './services/apis/grade.service';
import { TechnologyPlanService } from './services/apis/technology-plan.service';
import { AuthInterceptorService } from './services/interceptors/authinterceptor.service';
import { ErrorInterceptorService } from './services/interceptors/errorinterceptor.service';
import { ErrorService } from './services/apis/error.service';
import { AuthGuardService } from './services/guards/authguard.service';
import { RoleGuardService } from './services/guards/roleguard.service';
import { SplashGuardService } from './services/guards/splashguard.service';
import { SpinnerService } from './services/static/spinner.service';
import { ErrorHandlerService } from './services/interceptors/errorhandler.service';
import { VersionService } from './services/apis/version.service';

// Static Services
import { UtilityService } from './services/static/utility.service';
import { StorageService } from './services/static/storage.service';
import { ChartService } from './services/static/chart.service';
import { DataService } from './services/static/data.service';

// Modules
import { SharedModule } from './shared.module';

// Third Party Imports
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import more from 'highcharts/highcharts-more.src';
import variwide from 'highcharts/modules/variwide.src';
import * as HighchartsSolidGauge from 'highcharts/modules/solid-gauge'
import exporting from 'highcharts/modules/exporting.src';
import windbarb from 'highcharts/modules/windbarb.src';
import * as histogram from 'highcharts/modules/histogram-bellcurve';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { IntercomModule } from 'ng-intercom';

// Routes
import { AppRoutes } from './routes';

// Directives
import { HasRoleDirective } from './directives/role.directive';

enableProdMode();

@NgModule({
  declarations: [
    // Main Components
    PlatformAppComponent,

    // Partials
    GlobalSpinnerComponent,
    FooterComponent,
    ConfirmationDialogModalComponent,
    LeftNavigationComponent,
    TopNavigationComponent,

    // Modals
    TechnologyPlanCardModalComponent,
    SchedulerBatchModalComponent,
    SchedulerBatchMultipleEditModalComponent,
    NamingDialogModalComponent,
    BreadcrumbsComponent,
    FileUploadModalComponent,
    TopSpinnerComponent,
    AccountModalComponent,
    NavigationItemComponent,
    IconMenuComponent,
    InformationDialogModalComponent,
    DashboardCardModalComponent,
    ProductModalComponent,
    RawMaterialModalComponent,
    RawMaterialPriceModalComponent,
    FilterSchedulerLinesModalComponent,
    SchedulerTransferModalComponent,
    BillOfMaterialsModalComponent,
    ProductWheelModalComponent,
    ActionPlanCategoryModalComponent,
    ActionPlanProjectModalComponent,
    ActionPlanTaskModalComponent,

    // Directives
    HasRoleDirective
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,

    // Modules
    SharedModule,

    // Third Part Libraries
    RouterModule.forRoot(AppRoutes),
    ChartModule,
    ScrollToModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    NgxMaterialTimepickerModule.forRoot(),
    IntercomModule.forRoot({
      appId: 't3d9w1ub', // from your Intercom config
      updateOnRouterChange: true // will automatically run `update` on router event changes. Default: `false`
    })
  ],
  entryComponents: [
    // Modals
    NamingDialogModalComponent,
    InformationDialogModalComponent,
    ConfirmationDialogModalComponent,
    TechnologyPlanCardModalComponent,
    SchedulerBatchModalComponent,
    SchedulerBatchMultipleEditModalComponent,
    FileUploadModalComponent,
    DashboardCardModalComponent,
    AccountModalComponent,
    ProductModalComponent,
    RawMaterialModalComponent,
    RawMaterialPriceModalComponent,
    FilterSchedulerLinesModalComponent,
    SchedulerTransferModalComponent,
    BillOfMaterialsModalComponent,
    ProductWheelModalComponent,
    ActionPlanCategoryModalComponent,
    ActionPlanProjectModalComponent,
    ActionPlanTaskModalComponent,
  ],
  providers: [
    // Services
    AuthenticationService,
    ActionPlanService,
    AdminService,
    AccountService,
    BlobService,
    BusinessService,
    CompanyService,
    DashboardService,
    FamilyService,
    LineService,
    MetricsService,
    NotificationService,
    ProductService,
    RawMaterialService,
    ProfitThreadService,
    SchedulerService,
    SandboxService,
    PlannerService,
    RealTimeService,
    SitePerformanceService,
    SiteService,
    TechnologyPlanService,
    RegionService,
    GradeService,
    ErrorService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true },
    AuthGuardService,
    RoleGuardService,
    SplashGuardService,
    AuthInterceptorService,
    SpinnerService,
    VersionService,

    // Static Services
    UtilityService,
    StorageService,
    ChartService,
    DataService,

    // { provide: HIGHCHARTS_MODULES, useFactory: () => [more, variwide, exporting, windbarb] }
    { provide: HIGHCHARTS_MODULES, useFactory: () => [more, variwide, histogram, HighchartsSolidGauge] }
  ],
  bootstrap: [PlatformAppComponent]
})

export class PlatformAppModule { }