// Angular Imports
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from "rxjs/operators";
import { environment } from '../../../environments/environment';

// Service
import { UtilityService } from '../static/utility.service';

// Models
import { FamilyScheduleView, LineScheduleView, SchedulerInstanceView, SchedulerInstance } from 'src/platform-app/models/scheduler.model';
import { BatchView } from 'src/platform-app/models/batch.model';

@Injectable()
export class ProfitThreadService {
    public baseUrl: string = environment.endpoint;

    constructor(
        private http: HttpClient,
        private utilityService: UtilityService
    ) { }

    GetProfitThread(): Observable<any> {
        const url: string = this.baseUrl + '/api/ProfitThread';
        return this.http.get<any>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    EditSiteBanked(siteId: number, value: number): Observable<any> {
        const url: string = this.baseUrl + '/api/ProfitThread/Site/' + siteId + '/' + value;
        return this.http.put(url, null).pipe(
            map(this.utilityService.extractData)
        );
    }

    EditBusinessBanked(businessId: number, value: number): Observable<any> {
        const url: string = this.baseUrl + '/api/ProfitThread/Business/' + businessId + '/' + value;
        return this.http.put(url, null).pipe(
            map(this.utilityService.extractData)
        );
    }
}