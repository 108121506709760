import { CompanyView } from "./company.model";

// Account 
export class AccountSmall {
    email: string = '';
    firstName: string = '';
    lastName: string = '';
    title: string = '';
    gender: string = '';
    profileImg: string = '';
    emailConfirmed: boolean;
    uiSettings: UiSettings = new UiSettings;
    roles: Role[] = [];
}

export class AccountSmallView {
    id?: string;
    email: string = '';
    firstName: string = '';
    lastName: string = '';
    title: string = '';
    gender: string = '';
    profileImg: string = '';
    emailConfirmed: boolean;
    uiSettings: UiSettings = new UiSettings;
    roles: Role[] = [];
    company: CompanyView;
}

export class AccountView extends AccountSmallView {
    applicationRoles: RoleView[];
    moduleRoles: RoleView[];
}

// Role
export class Role {
    name: string;
}

export class RoleView {
    id: string;
    name: string;
}

export class UiSettings {
    dashboardCharts: DashboardCards = new DashboardCards;
}

export class DashboardCards {
    global: CardOption[] = [];
    site: CardOption[] = [];
    business: CardOption[] = [];
    siteBusiness: CardOption[] = [];
    line: CardOption[] = [];
}

export class CardOption {
    type: number;
    width: number;
}