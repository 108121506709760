// Angular Imports
import { Injectable } from '@angular/core';

// Models
import { PageView, ModuleView } from '../../models/utilitymodels';

// Third Party Imports

@Injectable()
export class DataService {
    GetMapStyle() {
        let mapStyle = [
            {
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#f5f5f5"
                    }
                ]
            },
            {
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#616161"
                    }
                ]
            },
            {
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "color": "#f5f5f5"
                    }
                ]
            },
            {
                "featureType": "administrative.land_parcel",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#bdbdbd"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#eeeeee"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#757575"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#e5e5e5"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#9e9e9e"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#757575"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#dadada"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#616161"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#9e9e9e"
                    }
                ]
            },
            {
                "featureType": "transit.line",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#e5e5e5"
                    }
                ]
            },
            {
                "featureType": "transit.station",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#eeeeee"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#C0C8D0"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#9e9e9e"
                    }
                ]
            }
        ];
        return mapStyle;
    }

    GetMapMarkers() {
        return [
            {
                url: 'assets/img/marker-active.png',
                scaledSize: {
                    height: 25,
                    width: 25
                }
            },
            {
                url: 'assets/img/marker.png',
                scaledSize: {
                    height: 25,
                    width: 25
                }
            }
        ]
    }

    GetMapClusterStyles() {
        return [
            {
                textColor: "#FFFFFF",
                url: "assets/img/m1-orange.png",
                height: 53,
                width: 53,
                backgroundPosition: 'center'

            }
        ]
    }

    GetMonthNames() {
        return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    }

    GetBreakpoints() {
        let breakpoints = [768, 992, 1200];
        return breakpoints;
    }

    GetPages(companyId: number): PageView[] {
        let pages = [
            {
                name: 'Dashboard',
                alias: 'dashboard'
            },
            {
                name: 'Scheduler',
                alias: 'scheduler'
            },
            {
                name: 'Site Performance',
                alias: 'site-performance'
            },
            {
                name: 'Action Plan',
                alias: 'action-plan'
            },
            {
                name: 'Technology Plan',
                alias: 'technology-plan'
            },
            {
                name: 'Planner',
                alias: 'planner'
            },
            {
                name: 'Sandbox',
                alias: 'sandbox'
            },
            {
                name: 'Simulation',
                alias: 'simulation'
            },
            {
                name: 'ProfitThread',
                alias: 'profit-thread'
            },
            {
                name: 'Knowledge Hub',
                alias: 'knowledge-hub'
            },
            {
                name: 'Lab',
                alias: 'lab'
            },
            {
                name: 'Admin Panel',
                alias: 'admin-panel'
            },
            {
                name: 'Account',
                alias: 'account'
            },
            {
                name: 'Notifications',
                alias: 'notifications'
            },
            {
                name: 'Privacy Policy',
                alias: 'privacy-policy'
            },
            {
                name: 'Terms and Conditions',
                alias: 'terms-conditions'
            },
            {
                name: 'Not Found',
                alias: 'not-found'
            },
            {
                name: 'Not Authorized',
                alias: 'not-authorized'
            },
            {
                name: 'Login',
                alias: 'login'
            },
            {
                name: 'Password Reset',
                alias: 'password-reset'
            },
            {
                name: 'Release Notes',
                alias: 'release-notes'
            }
        ];

        if (companyId === 3) {
            for (let page of pages) {
                if (page.alias === 'scheduler') {
                    page.name = 'Polymer GNO';
                } else if (page.alias === 'planner') {
                    page.name = 'Chemical GNO';
                }
            }
        }

        return pages;
    }

    GetNavigationArray(companyId: number): ModuleView[] {
        let modules = [
            {
                name: 'Dashboard',
                alias: 'dashboard',
                active: true,
                role: null,
                global: true,
                sites: true,
                icon: 'fas fa-tachometer-fastest'
            },
            {
                name: 'Scheduler',
                active: true,
                alias: 'scheduler',
                role: 'Scheduler',
                businesses: true,
                assembly: true,
                icon: 'fas fa-calendar-edit'
            },
            {
                name: 'Site Performance',
                active: true,
                alias: 'site-performance',
                role: 'Site Performance',
                sites: true,
                icon: 'fas fa-analytics'
            },
            {
                name: 'Action Plan',
                active: true,
                alias: 'action-plan',
                role: 'Action Plan',
                sites: true,
                icon: 'fas fa-clipboard-list-check'
            },
            {
                name: 'Technology Plan',
                active: false,
                alias: 'technology-plan',
                role: 'Technology Plan',
                businesses: true,
                icon: 'far fa-globe-americas'
            },
            {
                name: 'Planner',
                alias: 'planner',
                role: 'Planner',
                businesses: true,
                disassembly: true,
                icon: 'fas fa-calendar-alt'
            },
            {
                name: 'Sandbox',
                alias: 'sandbox',
                role: 'Sandbox',
                sites: true,
                icon: 'fas fa-cube'
            },
            {
                name: 'Simulation',
                alias: 'simulation',
                role: 'Simulation',
                icon: 'fas fa-vr-cardboard'
            },
            {
                name: 'ProfitThread',
                alias: 'profit-thread',
                role: 'ProfitThread',
                icon: 'fas fa-project-diagram'
            },
            {
                name: 'Lab',
                alias: 'lab',
                role: 'Lab',
                icon: 'fas fa-flask'
            },
            {
                name: 'Document Manager',
                alias: 'document-manager',
                role: 'Document Manager',
                icon: 'fas fa-folders'
            },
            {
                name: 'Report',
                alias: 'report',
                role: 'Report',
                icon: 'fas fa-file-chart-line'
            },
            {
                name: 'Settings',
                active: true,
                alias: 'admin-panel',
                role: 'Admin Panel',
                icon: 'far fa-cog',
                customSubNavs: [
                    {
                        name: 'Account Settings',
                        alias: 'account-settings'
                    },
                    // {
                    //     name: 'Metrics Settings',
                    //     alias: 'metrics-settings'
                    // },
                    {
                        name: 'Product Settings',
                        alias: 'product-settings'
                    },
                    // {
                    //     name: 'Sales Settings',
                    //     alias: 'sales-settings'
                    // }
                ]
            }
        ];

        return modules;
    }

    GetAllDashboardCards(type: string) {
        let cards = {
            global: [
                {
                    type: 1,
                    name: 'Availability',
                    description: 'TBA'
                },
                {
                    type: 2,
                    name: 'Quality',
                    description: 'TBA'
                },
                {
                    type: 3,
                    name: 'OEE',
                    description: 'TBA'
                },
                {
                    type: 4,
                    name: 'TEEP',
                    description: 'TBA'
                },
                {
                    type: 5,
                    name: 'Downtime Forecast',
                    description: 'TBA'
                },
                {
                    type: 6,
                    name: 'Quality Forecast',
                    description: 'TBA'
                }
            ],
            site: [
                {
                    type: 1,
                    name: 'Availability',
                    description: 'TBA'
                },
                {
                    type: 2,
                    name: 'Quality',
                    description: 'TBA'
                },
                {
                    type: 3,
                    name: 'Rate',
                    description: 'TBA'
                },
                {
                    type: 4,
                    name: 'Utilization',
                    description: 'TBA'
                },
                {
                    type: 5,
                    name: 'OEE',
                    description: 'TBA'
                },
                {
                    type: 6,
                    name: 'TEEP',
                    description: 'TBA'
                },
                {
                    type: 7,
                    name: 'Downtime Forecast',
                    description: 'TBA'
                },
                {
                    type: 8,
                    name: 'Quality Forecast',
                    description: 'TBA'
                }
            ],
            business: [
                {
                    type: 1,
                    name: 'Availability',
                    description: 'TBA'
                },
                {
                    type: 2,
                    name: 'Quality',
                    description: 'TBA'
                },
                {
                    type: 3,
                    name: 'OEE',
                    description: 'TBA'
                },
                {
                    type: 4,
                    name: 'TEEP',
                    description: 'TBA'
                },
                {
                    type: 5,
                    name: 'Downtime Forecast',
                    description: 'TBA'
                },
                {
                    type: 6,
                    name: 'Quality Forecast',
                    description: 'TBA'
                }
            ],
            siteBusiness: [
                {
                    type: 1,
                    name: 'Availability',
                    description: 'TBA'
                },
                {
                    type: 2,
                    name: 'Quality',
                    description: 'TBA'
                },
                {
                    type: 3,
                    name: 'Rate',
                    description: 'TBA'
                },
                {
                    type: 4,
                    name: 'Utilization',
                    description: 'TBA'
                },
                {
                    type: 5,
                    name: 'OEE',
                    description: 'TBA'
                },
                {
                    type: 6,
                    name: 'TEEP',
                    description: 'TBA'
                },
                {
                    type: 7,
                    name: 'Downtime Forecast',
                    description: 'TBA'
                },
                {
                    type: 8,
                    name: 'Quality Forecast',
                    description: 'TBA'
                }
            ],
            line: [
                {
                    type: 1,
                    name: 'Production Variability',
                    description: 'TBA'
                },
                {
                    type: 2,
                    name: 'Availability',
                    description: 'TBA'
                },
                {
                    type: 3,
                    name: 'Quality/Yield',
                    description: 'TBA'
                }
            ]
        }

        return cards[type];
    }

    GetDefaultDashboardCards(type: string) {
        let cards = {
            global: [
                {
                    categoryType: 1,
                    cards: [
                        {
                            type: 1,
                            width: 1
                        },
                        {
                            type: 2,
                            width: 1
                        },
                        {
                            type: 3,
                            width: 1
                        },
                        {
                            type: 4,
                            width: 1
                        },
                        {
                            type: 5,
                            width: 1
                        },
                        {
                            type: 6,
                            width: 1
                        },
                    ]
                }
            ],
            site: [
                {
                    categoryType: 1,
                    cards: [
                        {
                            type: 1,
                            width: 1
                        },
                        {
                            type: 2,
                            width: 1
                        },
                        {
                            type: 3,
                            width: 1
                        },
                        {
                            type: 4,
                            width: 1
                        },
                        {
                            type: 5,
                            width: 1
                        },
                        {
                            type: 6,
                            width: 1
                        },
                        {
                            type: 7,
                            width: 1
                        },
                        {
                            type: 8,
                            width: 1
                        },
                    ]
                }
            ],
            business: [
                {
                    categoryType: 1,
                    cards: [
                        {
                            type: 1,
                            width: 1
                        },
                        {
                            type: 2,
                            width: 1
                        },
                        {
                            type: 3,
                            width: 1
                        },
                        {
                            type: 4,
                            width: 1
                        },
                        {
                            type: 5,
                            width: 1
                        },
                        {
                            type: 6,
                            width: 1
                        }
                    ]
                }
            ],
            siteBusiness: [
                {
                    categoryType: 1,
                    cards: [
                        {
                            type: 1,
                            width: 1
                        },
                        {
                            type: 2,
                            width: 1
                        },
                        {
                            type: 3,
                            width: 1
                        },
                        {
                            type: 4,
                            width: 1
                        },
                        {
                            type: 5,
                            width: 1
                        },
                        {
                            type: 6,
                            width: 1
                        },
                        {
                            type: 7,
                            width: 1
                        },
                        {
                            type: 8,
                            width: 1
                        },
                    ]
                }
            ],
            line: [
                {
                    categoryType: 1,
                    cards: [
                        {
                            type: 1,
                            width: 4
                        },
                        {
                            type: 2,
                            width: 4
                        },
                        {
                            type: 3,
                            width: 4
                        }
                    ]
                },
                // {
                //     categoryType: 2,
                //     cards: []
                // },
            ]
        }

        return cards[type];
    }

    GetDashboardCardInformation(type: string, cardType: number) {
        let allCards = this.GetAllDashboardCards(type);
        return allCards.find(card => {
            return card.type === cardType
        })
    }

    GetDashboardCardCategories(cardsType: string, categoryType: number) {
        let categories = {
            global: [],
            site: [],
            business: [],
            siteBusiness: [],
            line: [
                'Time Series',
                'Product'
            ]
        }

        return categories[cardsType][categoryType - 1];
    }
}