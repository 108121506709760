// Angular Imports
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from "rxjs/operators";
import { environment } from '../../../environments/environment';

// Services
import { UtilityService } from '../static/utility.service';

// Models
import { FamilyView } from 'src/platform-app/models/family.model';

@Injectable()
export class FamilyService {
    public baseUrl: string = environment.endpoint;

    constructor(
        private http: HttpClient,
        private utilityService: UtilityService
    ) { }

    GetAllFamilies(): Observable<FamilyView[]> {
        const url: string = this.baseUrl + '/api/Family/GetAll';
        return this.http.get<FamilyView[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetFamiliesByBusinessId(businessId: number): Observable<FamilyView[]> {
        const url: string = this.baseUrl + '/api/Family/Business/' + businessId + '/GetAll';
        return this.http.get<FamilyView[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }
}