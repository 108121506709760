// Angular Imports
import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';

// Services
import { AdminService } from '../../../../services/apis/admin.service';
import { UtilityService } from 'src/platform-app/services/static/utility.service';

// Components

// Models
import { AccountView, RoleView } from 'src/platform-app/models/account.model';

// Third Party Imports
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'edit-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})

export class AccountModalComponent implements OnInit, OnDestroy {
  public eventFunction: any;
  @ViewChild('ModalForm', { static: true }) public modalForm: NgForm;
  public account = new AccountView();
  public roles: RoleView[] = [];
  public genders = ['Male', 'Female'];
  public subscriptions = new Subscription();

  constructor(
    private dialogRef: MatDialogRef<AccountModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private adminService: AdminService,
    private utilityService: UtilityService
  ) { }

  ngOnInit() {
    if (this.data.account) {
      this.account = this.data.account;
    }

    this.subscriptions.add(
      this.adminService.GetAllRoles().subscribe((roles: RoleView[]) => {
        this.roles = roles;
      }, (error) => {
        console.log(error);
      })
    );

    this.eventFunction = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        this.Save();
      }
    }

    document.addEventListener('keyup', this.eventFunction, false); // Submitting popup on press of Enter key
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    document.removeEventListener('keyup', this.eventFunction, false); // Removing event listener
  }

  Cancel() {
    this.dialogRef.close();
    document.removeEventListener('keyup', this.eventFunction, false); // Removing event listener
  }

  Save() {
    if (this.modalForm.valid) {
      this.dialogRef.close(this.account);
      document.removeEventListener('keyup', this.eventFunction, false); // Removing event listener
    }
  }

  TrackByFunction(index, item) {
    return item.id;
  }

  CompareWithFunction(item1, item2) {
    return item1 && item2 ? item1.name === item2.name : item1 === item2;
  }
}
