// Angular Imports
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError, map } from "rxjs/operators";
import { environment } from '../../../environments/environment';

// Services
import { UtilityService } from '../static/utility.service';

// Models
import { SitePerformanceDataView } from 'src/platform-app/models/site-performance.model';

@Injectable()
export class SitePerformanceService {
    public baseUrl: string = environment.endpoint;

    constructor(
        private http: HttpClient,
        private utilityService: UtilityService
    ) { }

    GetSitePerformanceCategoriesBySiteId(siteId: number): Observable<SitePerformanceDataView> {
        const url: string = this.baseUrl + '/api/SitePerformance/Site/' + siteId + '/Get';
        return this.http.get<SitePerformanceDataView>(url).pipe(
            map(this.utilityService.extractData)
        );
    }
}