// Angular Imports
import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

// Third Party Imports
import * as moment from 'moment';

@Pipe({ name: 'age' })

export class Age extends DatePipe implements PipeTransform {
    transform(date: string): string {
        const givenDate = moment(moment(date)).local();
        const currentDate = moment();
        const hours = currentDate.diff(givenDate, 'hours');
        const minutes = currentDate.diff(givenDate, 'minutes');

        if (minutes < 1) {
            return `1min`;
        } else if (hours < 1) {
            return `${minutes}min`;
        } else if (hours <= 24) {
            return `${hours}h`;
        } else {
            return super.transform(date, 'MMM dd');
        }
    }
}