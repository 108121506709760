// Angular Imports
import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';

// Services
import { ProductService } from 'src/platform-app/services/apis/product.service';
import { RegionService } from 'src/platform-app/services/apis/region.service';
import { SchedulerService } from 'src/platform-app/services/apis/scheduler.service';
import { UtilityService } from 'src/platform-app/services/static/utility.service';

// Models
import { SchedulerTransfer } from 'src/platform-app/models/scheduler.model';
import { ProductView } from 'src/platform-app/models/product.model';
import { RegionView } from 'src/platform-app/models/region.model';

// Pipes
import { DatePipe } from '@angular/common';

// Third Party Imports
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';

@Component({
  selector: 'scheduler-transfer-modal',
  templateUrl: './transfer.component.html',
  styleUrls: ['./transfer.component.scss'],
  providers: [DatePipe]
})

export class SchedulerTransferModalComponent implements OnInit, OnDestroy {
  public eventFunction: any;
  public transfer = new SchedulerTransfer;
  public fromDate: string;
  public toDate: Date;
  public businessId: number;
  public products: ProductView[] = [];
  public regions: RegionView[] = [];
  public earliestShippmentDate: Date;
  public details;

  @ViewChild('ModalForm', { static: true }) public modalForm: NgForm;
  public subscriptions = new Subscription();

  constructor(
    private dialogRef: MatDialogRef<SchedulerTransferModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private productService: ProductService,
    private regionService: RegionService,
    private schedulerService: SchedulerService,
    private datePipe: DatePipe,
    private utilityService: UtilityService
  ) { }

  ngOnInit() {
    this.businessId = this.data.businessId;

    this.subscriptions.add(
      this.productService.GetProductsByBusinessId(this.businessId).subscribe((products: ProductView[]) => {
        this.products = products;
      }, (error) => {
        console.log(error);
      })
    );

    this.subscriptions.add(
      this.regionService.GetAllRegions().subscribe((regions: RegionView[]) => {
        this.regions = regions;
      }, (error) => {
        console.log(error);
      })
    );

    this.eventFunction = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        this.Save();
      }
    }

    document.addEventListener('keyup', this.eventFunction, false); // Submitting popup on press of Enter key
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    document.removeEventListener('keyup', this.eventFunction, false); // Removing event listener
  }

  GetTransferDetails() {
    if (this.transfer.productId && this.transfer.quantity && this.transfer.fromRegionId && this.transfer.toRegionId) {
      let information = {
        productId: this.transfer.productId,
        quantity: this.transfer.quantity,
        fromRegionId: this.transfer.fromRegionId,
        toRegionId: this.transfer.toRegionId,
      }

      this.subscriptions.add(
        this.schedulerService.GetSchedulerTransfersDetails(information).subscribe((details: any) => {
          this.transfer.fromDate = null;
          this.fromDate = null;
          this.toDate = null;
          this.earliestShippmentDate = moment().add(details.minimumDays, "days").toDate();
          this.details = details;
        }, (error) => {
          console.log(error);
        })
      );
    }
  }

  GetShippingDate() {
    this.transfer.fromDate = moment(this.toDate).subtract(this.details.minimumDays, "days").toDate();
    this.fromDate = this.datePipe.transform(this.transfer.fromDate, 'MM/dd/yyyy');
  }

  Cancel() {
    this.dialogRef.close();
    document.removeEventListener('keyup', this.eventFunction, false); // Removing event listener
  }

  Save() {
    if (this.modalForm.valid) {
      this.dialogRef.close(this.transfer);
      document.removeEventListener('keyup', this.eventFunction, false); // Removing event listener
    }
  }

  TrackByFunction(index, item) {
    return item.id;
  }
}
