// Angular Imports
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from "rxjs/operators";
import { environment } from '../../../environments/environment';

// Services
import { UtilityService } from '../static/utility.service';

// Models

@Injectable()
export class SandboxService {
    public baseUrl: string = environment.endpoint;

    constructor(
        private http: HttpClient,
        private utilityService: UtilityService
    ) { }

    // Sandbox 

    CalculateSandboxSiteMetricsByLineMetrics(lineMetrics: any, siteId: number, businessId: number = 0): Observable<any> {
        const url: string = this.baseUrl + '/api/SandBox/GetPandL/site/' + siteId + '/business/' + businessId;
        return this.http.post(url, lineMetrics).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetSandboxTechnologyPlanMetrics(siteId: number, businessId: number, round: number = null): Observable<any[]> {
        let url: string = this.baseUrl + '/api/Sandbox/TechnologyPlanLineMetrics/Site/' + siteId + '/Business/' + businessId;

        if (round) {
            url += '/Round/' + round;
        }

        return this.http.get<any[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetSandboxActionPlanMetrics(siteId: number, businessId: number, round: number = null): Observable<any[]> {
        let url: string = this.baseUrl + '/api/Sandbox/ActionPlanLineMetrics/Site/' + siteId + '/Business/' + businessId;

        if (round) {
            url += '/Round/' + round;
        }

        return this.http.get<any[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetSandboxActionPlanAndTechnologyPlanMetrics(siteId: number, businessId: number, round: number = null): Observable<any[]> {
        let url: string = this.baseUrl + '/api/Sandbox/ActionTechnologyPlanLineMetrics/Site/' + siteId + '/Business/' + businessId;

        if (round) {
            url += '/Round/' + round;
        }
        return this.http.get<any[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetSandboxCompetitors(businessId: number): Observable<any[]> {
        const url: string = this.baseUrl + '/api/Competitors/Business/' + businessId;
        return this.http.get<any[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }
}