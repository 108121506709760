// Angular Imports
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';

// Third Party Imports
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'confirmation-dialog-modal',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})

export class ConfirmationDialogModalComponent implements OnInit, OnDestroy {
  public eventFunction: any;

  constructor(
    private dialogRef: MatDialogRef<ConfirmationDialogModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.eventFunction = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        this.Confirm();
      }
    }

    document.addEventListener('keyup', this.eventFunction, false); // Submitting popup on press of Enter key
  }

  ngOnDestroy() {
    document.removeEventListener('keyup', this.eventFunction, false); // Removing event listener
  }

  Cancel() {
    this.dialogRef.close();
    document.removeEventListener('keyup', this.eventFunction, false); // Removing event listener
  }

  Confirm() {
    this.dialogRef.close(true);
    document.removeEventListener('keyup', this.eventFunction, false); // Removing event listener
  }
}
