// Angular Imports
import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';

// Services
import { UtilityService } from 'src/platform-app/services/static/utility.service';

// Components

// Models
import { BusinessView } from 'src/platform-app/models/business.model';
import { RawMaterial } from 'src/platform-app/models/raw-material.model';

// Third Party Imports
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'raw-material-modal',
  templateUrl: './raw-material.component.html',
  styleUrls: ['./raw-material.component.scss']
})

export class RawMaterialModalComponent implements OnInit, OnDestroy {
  public eventFunction: any;
  public rawMaterial: RawMaterial;
  public rawMaterialId: number;
  public businesses: BusinessView[] = [];
  @ViewChild('ModalForm', { static: true }) public modalForm: NgForm;
  public subscriptions = new Subscription();

  constructor(
    private dialogRef: MatDialogRef<RawMaterialModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private utilityService: UtilityService
  ) { }

  ngOnInit() {
    if (this.data.rawMaterial && this.data.rawMaterialId) {
      this.rawMaterial = this.data.rawMaterial;
      this.rawMaterialId = this.data.rawMaterialId;
    } else {
      this.rawMaterial = new RawMaterial();
    }

    this.businesses = this.data.businesses;

    this.eventFunction = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        this.Save();
      }
    }

    document.addEventListener('keyup', this.eventFunction, false); // Submitting popup on press of Enter key
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    document.removeEventListener('keyup', this.eventFunction, false); // Removing event listener
  }

  Cancel() {
    this.dialogRef.close();
    document.removeEventListener('keyup', this.eventFunction, false); // Removing event listener
  }

  Save() {
    if (this.modalForm.valid) {
      this.dialogRef.close(this.rawMaterial);
      document.removeEventListener('keyup', this.eventFunction, false); // Removing event listener
    }
  }

  TrackByFunction(index, item) {
    return item.id;
  }
}
