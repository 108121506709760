export class TableSortOptions {
    order: string;
    reverse: boolean;
}

export class MapOptions {
    public latitude: number;
    public longitude: number;
    public zoom: number;
    public minZoom: number;
    public maxZoom: number;
    public zoomControl: boolean;
    public streetViewControl: boolean;
    public mapTypeId: string;
}

// Page
export class PageView {
    name: string;
    alias: string;
}

// Module
export class ModuleView {
    name: string;
    alias: string;
    active?: boolean;
    global?: boolean;
    sites?: boolean;
    businesses?: boolean;
    customSubNavs?: SubNav[];
    bottom?: boolean;
    assembly?: boolean;
    disassembly?: boolean;
}

export class SubNav {
    name: string;
    alias: string;
}

// Upload
export class UploadSettings {
    allowedExtensions: Array<string>;
    uploadActions: Array<string>;
    maxSize: number;
    crop: boolean;
}