// Angular Imports
import { Injectable } from '@angular/core';

// Pipes
import { ShortNum } from '../../pipes/short-num.pipe';

@Injectable()

export class ChartService {
    constructor(private shortNum: ShortNum) { }

    GetChartColors() {
        let colors = ['#04749B', '#4F9EB9', '#9BC7D7', '#CEE3EB', '#BFCFD5', '#A9B9BF'];

        return colors;
    }

    GetColumnChartOptions(type: string = 'number') {
        let options = {
            chart: {
                type: 'column'
            },
            colors: this.GetChartColors(),
            title: {
                text: ''
            },
            series: [],
            plotOptions: {
                column: {
                    pointWidth: 10,
                    dataLabels: {
                        enabled: false,
                        formatter: null
                    }
                },
                series: {
                    stacking: null
                }
            },
            xAxis: {
                categories: ['']
            },
            yAxis: {
                title: {
                    text: ''
                },
                endOnTick: false,
                startOnTick: false,
                tickInterval: null,
                maxPadding: 0.3
            },
            legend: {
                enabled: false,
                maxHeight: 60
            },
            tooltip: {
                formatter: null
            }
        };

        if (type === 'number') {
            options.plotOptions.column.dataLabels.formatter = options.tooltip.formatter = function () {
                return this.point.series.name + ': ' + this.y.toFixed(2);
            };
        } else if (type === 'currency') {
            options.plotOptions.column.dataLabels.formatter = options.tooltip.formatter = function () {
                return this.point.series.name + ': ' + '$' + Math.round(this.y / 1000000) + 'M';
            }
        } else if (type === 'percent') {
            options.plotOptions.column.dataLabels.formatter = options.tooltip.formatter = function () {
                return this.point.series.name + ': ' + this.y.toFixed(2) + '%';
            }
        }
        return options;
    }

    GetStackedBarChartOptions(type: string = 'number') {
        let options = {
            chart: {
                type: 'bar'
            },
            colors: this.GetChartColors(),
            title: {
                text: ''
            },
            series: [],
            legend: {
                enabled: false,
                maxHeight: 60
            },
            plotOptions: {
                bar: {
                    pointWidth: 10,
                    dataLabels: {
                        enabled: false,
                        formatter: null
                    }
                },
                column: {
                    pointWidth: 10,
                    dataLabels: {
                        enabled: false,
                        formatter: null
                    }
                },
                series: {
                    stacking: 'normal'
                }
            },
            xAxis: {
                categories: ['']
            },
            yAxis: {
                title: {
                    text: ''
                },
                max: 100
            },
            tooltip: {
                formatter: null
            }
        };

        if (type === 'number') {
            options.plotOptions.bar.dataLabels.formatter = options.tooltip.formatter = function () {
                return this.point.series.name + ': ' + this.y.toFixed(2);
            };
        } else if (type === 'currency') {
            options.plotOptions.bar.dataLabels.formatter = options.tooltip.formatter = function () {
                return this.point.series.name + ': ' + '$' + Math.round(this.y / 1000000) + 'M';
            }
        } else if (type === 'percent') {
            options.plotOptions.bar.dataLabels.formatter = options.tooltip.formatter = function () {
                return this.point.series.name + ': ' + this.y.toFixed(2) + '%';
            }
        }
        return options;
    }

    GetVariWideChartOptions() {
        let options = {
            chart: {
                type: 'variwide'
            },
            colors: this.GetChartColors(),
            title: {
                text: ''
            },
            xAxis: {
                type: 'category',
                title: {
                    text: 'Capacity'
                }
            },
            yAxis: {
                title: {
                    text: 'Mfg. Cost/Unit'
                },
                endOnTick: false,
                startOnTick: false,
                maxPadding: 0.2
            },
            series: [{
                name: '',
                data: [],
                dataLabels: {
                    enabled: true,
                    format: '${point.y:.2f}'
                },
                tooltip: {
                    pointFormat: 'Mfg. Cost/Unit: <b>${point.y:.2f}</b><br>' +
                        'Capacity: <b>{point.z:.0f}</b><br>'
                },
                colorByPoint: true,
                showInLegend: false
            }]
        }
        return options;
    }

    GetPieChartOptions() {
        let options = {
            chart: {
                type: 'pie'
            },
            colors: this.GetChartColors(),
            title: {
                text: ''
            },
            series: [],
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: false
                }
            },
            yAxis: {
                title: {
                    text: ''
                }
            },
            xAxis: {
                categories: ['']
            },
            tooltip: {
                formatter: function () {
                    return this.point.name + ' :$' + Math.round(this.y / 1000000) + 'M';
                }
            }
        };
        return options;
    }

    GetSolidGaugeChartOptions() {
        let options = {
            chart: {
                type: 'solidgauge'
            },

            title: null,

            pane: {
                center: ['50%', '77%'],
                size: '145%',
                startAngle: -90,
                endAngle: 90,
                background: {
                    innerRadius: '75%',
                    outerRadius: '100%',
                    shape: 'arc'
                }
            },

            exporting: {
                enabled: false
            },

            tooltip: {
                enabled: false
            },

            legend: {
                enabled: false
            },

            // the value axis
            yAxis: {
                min: 0,
                max: 0,

                stops: [
                    [0, '#04749b'], // Red
                    // [0.5, '#e87c4d'], // yellow
                    // [0.8, '#dd5b40'] // Green
                ],
                lineWidth: 0,
                tickWidth: 0,
                minorTickInterval: null,
                tickAmount: 2,
                title: {
                    y: -70
                },
                labels: {
                    y: 15,
                    distance: -13,
                    formatter: null
                }
            },
            series: [{
                innerRadius: '75%',
                radius: '100%',
                data: [],
                dataLabels: {
                    format:
                        '<div style="text-align:center">' +
                        '<span style="font-size:15px;opacity:0.4">${y} M</span><br/>' +
                        '<span style="font-size:12px;opacity:0.4">Per Year</span>' +
                        '</div>'
                },
                tooltip: {
                    valueSuffix: ' M'
                }
            }],

            plotOptions: {
                solidgauge: {
                    dataLabels: {
                        y: 5,
                        borderWidth: 0,
                        useHTML: true
                    }
                }
            }
        };
        return options;
    }

    GetGaugeChartOptions() {
        let options = {
            chart: {
                type: 'gauge'
            },
            title: {
                text: ''
            },
            plotOptions: {
                gauge: {
                    dataLabels: {
                        enabled: false
                    },
                    dial: {
                        backgroundColor: "#A9A9A9",
                        borderColor: "#A9A9A9"
                    }
                },
            },
            pane: {
                startAngle: -150,
                endAngle: 150,
                background: []
            },
            yAxis: {
                min: 0,
                max: 0,
                minorTickInterval: 'auto',
                minorTickWidth: 1,
                minorTickLength: 5,
                minorTickPosition: 'inside',
                minorTickColor: '#FFFFFF',
                tickPixelInterval: 30,
                tickWidth: 5,
                tickPosition: 'outside',
                tickLength: 5,
                tickColor: '#333333',
                tickPositions: [],
                labels: {
                    distance: 20,
                    max: null
                },
                title: {
                    text: ''
                },
                plotBands: [{
                    from: 0,
                    to: 120,
                    color: '#92000A'
                }, {
                    from: 120,
                    to: 160,
                    color: '#F2B903'
                }, {
                    from: 160,
                    to: 200,
                    color: '#26875F'
                }]
            },
            series: [],
            tooltip: {
                valueDecimals: 0,
                valuePrefix: '$'
            }
        };
        return options;
    }

    GetLineChartOptions(type: string = 'number') {
        let options = {
            chart: {
                type: 'line'
            },
            colors: this.GetChartColors(),
            title: {
                text: ''
            },
            legend: {
                enabled: true,
                maxHeight: 60
            },
            series: [],
            plotOptions: {
                column: {
                    pointWidth: 10,
                    dataLabels: {
                        enabled: true,
                        formatter: null
                    }
                },
                line: {
                    marker: {
                        enabled: false
                    }
                },
                allowPointSelect: false,
                series: null
            },
            xAxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                title: {
                    text: ''
                },
                labels: {
                    enabled: true
                },
            },
            yAxis: {
                title: {
                    text: ''
                },
                endOnTick: false,
                startOnTick: false,
                maxPadding: 0.3,
                plotLines: [],
                min: null,
                max: null
            },
            tooltip: {
                formatter: null
            }
        };

        if (type === 'number') {
            options.plotOptions.column.dataLabels.formatter = options.tooltip.formatter = function () {
                return this.series.name + '(' + this.x + ')' + ': ' + this.y.toFixed(2);
            };
        } else if (type === 'currency') {
            options.plotOptions.column.dataLabels.formatter = options.tooltip.formatter = function () {
                return this.series.name + '(' + this.x + ')' + ': ' + '$' + Math.round(this.y / 1000000) + 'M';
            }
        } else if (type === 'percent') {
            options.plotOptions.column.dataLabels.formatter = options.tooltip.formatter = function () {
                return this.series.name + '(' + this.x + ')' + ': ' + this.y.toFixed(2) + '%';
            }
        }

        return options;
    }

    GetBubbleChartOptions() {
        let options = {
            chart: {
                type: 'bubble',
                plotBorderWidth: 1,
                zoomType: 'xy'
            },
            legend: {
                enabled: false
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                gridLineWidth: 1,
                title: {
                    text: ''
                },
                labels: {
                    format: ''
                }
            },
            yAxis: {
                startOnTick: false,
                endOnTick: false,
                title: {
                    text: ''
                },
                labels: {
                    format: ''
                },
                maxPadding: 0.2,
            },
            tooltip: {
                formatter: null,
                pointFormat: null
            },
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: true,
                        format: '{point.name}'
                    }
                }
            },
            series: [{
                data: [
                ]
            }]
        };

        return options;
    }

    GetLineChartZoomOptions(type: string = 'number') {
        let options = {
            chart: {
                zoomType: 'x'
            },
            colors: this.GetChartColors(),
            title: {
                text: ''
            },
            series: [],
            plotOptions: {
                area: {
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                            x2: 0,
                            y2: 1
                        },
                        stops: [
                            [0, this.GetChartColors()[0]],
                            [1, this.GetChartColors()[3]]
                        ]
                    },
                    marker: {
                        radius: 2
                    },
                    lineWidth: 1,
                    states: {
                        hover: {
                            lineWidth: 1
                        }
                    },
                    threshold: null
                },
                column: {
                    pointWidth: 10,
                    dataLabels: {
                        enabled: true,
                        formatter: null
                    }
                },
                line: {
                    marker: {
                        enabled: false
                    }
                },
                allowPointSelect: false,
                series: null
            },
            xAxis: {
                type: 'datetime',
                endOnTick: false,
                startOnTick: false,
                labels: {
                    enabled: true
                },
                categories: []
            },
            yAxis: {
                title: {
                    text: ''
                },
                endOnTick: false,
                startOnTick: false,
                plotLines: [],
                min: null,
                max: null
            },
            tooltip: {
                formatter: null
            }
        };

        if (type === 'number') {
            options.plotOptions.column.dataLabels.formatter = options.tooltip.formatter = function () {
                return this.series.name + '(' + this.x + ')' + ': ' + this.y.toFixed(2);
            };
        } else if (type === 'currency') {
            options.plotOptions.column.dataLabels.formatter = options.tooltip.formatter = function () {
                return this.series.name + '(' + this.x + ')' + ': ' + '$' + Math.round(this.y / 1000000) + 'M';
            }
        } else if (type === 'percent') {
            options.plotOptions.column.dataLabels.formatter = options.tooltip.formatter = function () {
                return this.series.name + '(' + this.x + ')' + ': ' + this.y.toFixed(2) + '%';
            }
        }

        return options;
    }

    GetHistogramChartOptions() {
        let options = {
            title: {
                text: ''
            },
            legend: {
                enabled: true
            },
            xAxis: [{
                title: { text: '' },
                alignTicks: false
            }, {
                title: { text: '' },
                visible: false
            }],
            yAxis: [{
                title: { text: '' }
            }, {
                title: { text: '' },
                visible: false
            }],
            series: [{
                name: 'Histogram',
                type: 'histogram',
                xAxis: 1,
                yAxis: 1,
                baseSeries: 's1',
                zIndex: -1
            }, {
                name: 'Data',
                type: 'scatter',
                data: [],
                id: 's1',
                visible: false,
                showInLegend: false,
                marker: {
                    radius: 1.5
                }
            }]
        };

        return options;
    }
}