// Angular Imports
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { environment } from '../../../environments/environment';

// Services
import { UtilityService } from '../static/utility.service';

// Models
import { MetricsObjectView } from 'src/platform-app/models/metrics.model';

@Injectable()
export class MetricsService {
    public baseUrl: string = environment.endpoint;

    constructor(
        private http: HttpClient,
        private utilityService: UtilityService
    ) { }

    // Company metrics

    GetGlobalMetrics(type: string = 'yearly', startDate?: string, endDate?: string): Observable<MetricsObjectView> {
        let url: string = this.baseUrl + '/api/NewMetrics/Get?type=' + type;

        if (startDate) {
            url += '&startDate=' + startDate;
        }

        if (endDate) {
            url += '&endDate=' + endDate;
        }

        return this.http.get<MetricsObjectView>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetGlobalMetricsBySite(type: string = 'yearly', startDate?: string, endDate?: string): Observable<MetricsObjectView[]> {
        let url: string = this.baseUrl + '/api/NewMetrics/BySite/Get?type=' + type;

        if (startDate) {
            url += '&startDate=' + startDate;
        }

        if (endDate) {
            url += '&endDate=' + endDate;
        }

        return this.http.get<MetricsObjectView[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetGlobalMetricsByBusiness(type: string = 'yearly', startDate?: string, endDate?: string): Observable<MetricsObjectView[]> {
        let url: string = this.baseUrl + '/api/NewMetrics/ByBusiness/Get?type=' + type;

        if (startDate) {
            url += '&startDate=' + startDate;
        }

        if (endDate) {
            url += '&endDate=' + endDate;
        }

        return this.http.get<MetricsObjectView[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetGlobalMetricsByLine(type: string = 'yearly', startDate?: string, endDate?: string): Observable<MetricsObjectView[]> {
        let url: string = this.baseUrl + '/api/NewMetrics/ByLine/Get?type=' + type;

        if (startDate) {
            url += '&startDate=' + startDate;
        }

        if (endDate) {
            url += '&endDate=' + endDate;
        }

        return this.http.get<MetricsObjectView[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    // Site metrics

    GetSiteMetrics(siteId: number, type: string = 'yearly', startDate?: string, endDate?: string): Observable<MetricsObjectView> {
        let url: string = this.baseUrl + '/api/NewMetrics/Site/' + siteId + '/Get?type=' + type;

        if (startDate) {
            url += '&startDate=' + startDate;
        }

        if (endDate) {
            url += '&endDate=' + endDate;
        }

        return this.http.get<MetricsObjectView>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetSiteMetricsByBusiness(siteId: number, type: string = 'yearly', startDate?: string, endDate?: string): Observable<MetricsObjectView[]> {
        let url: string = this.baseUrl + '/api/NewMetrics/Site/' + siteId + '/ByBusiness/Get?type=' + type;

        if (startDate) {
            url += '&startDate=' + startDate;
        }

        if (endDate) {
            url += '&endDate=' + endDate;
        }

        return this.http.get<MetricsObjectView[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetSiteMetricsByLine(siteId: number, type: string = 'yearly', startDate?: string, endDate?: string): Observable<MetricsObjectView[]> {
        let url: string = this.baseUrl + '/api/NewMetrics/Site/' + siteId + '/ByLine/Get?type=' + type;

        if (startDate) {
            url += '&startDate=' + startDate;
        }

        if (endDate) {
            url += '&endDate=' + endDate;
        }

        return this.http.get<MetricsObjectView[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    // Business metrics

    GetBusinessMetrics(businessId: number, type: string = 'yearly', startDate?: string, endDate?: string): Observable<MetricsObjectView> {
        let url: string = this.baseUrl + '/api/NewMetrics/Business/' + businessId + '/Get?type=' + type;

        if (startDate) {
            url += '&startDate=' + startDate;
        }

        if (endDate) {
            url += '&endDate=' + endDate;
        }

        return this.http.get<MetricsObjectView>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetBusinessMetricsBySite(businessId: number, type: string = 'yearly', startDate?: string, endDate?: string): Observable<MetricsObjectView[]> {
        let url: string = this.baseUrl + '/api/NewMetrics/Business/' + businessId + '/BySite/Get?type=' + type;

        if (startDate) {
            url += '&startDate=' + startDate;
        }

        if (endDate) {
            url += '&endDate=' + endDate;
        }

        return this.http.get<MetricsObjectView[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetBusinessMetricsByLine(businessId: number, type: string = 'yearly', startDate?: string, endDate?: string): Observable<MetricsObjectView[]> {
        let url: string = this.baseUrl + '/api/NewMetrics/Business/' + businessId + '/ByLine/Get?type=' + type;

        if (startDate) {
            url += '&startDate=' + startDate;
        }

        if (endDate) {
            url += '&endDate=' + endDate;
        }

        return this.http.get<MetricsObjectView[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    // Site Business metrics
    GetSiteBusinessMetrics(siteId: number, businessId: number, type: string = 'yearly', startDate?: string, endDate?: string): Observable<MetricsObjectView> {
        let url: string = this.baseUrl + '/api/NewMetrics/Site/' + siteId + '/Business/' + businessId + '/Get?type=' + type;

        if (startDate) {
            url += '&startDate=' + startDate;
        }

        if (endDate) {
            url += '&endDate=' + endDate;
        }

        return this.http.get<MetricsObjectView>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetSiteBusinessMetricsByLine(siteId: number, businessId: number, type: string = 'yearly', startDate?: string, endDate?: string): Observable<MetricsObjectView[]> {
        let url: string = this.baseUrl + '/api/NewMetrics/Site/' + siteId + '/Business/' + businessId + '/ByLine/Get?type=' + type;

        if (startDate) {
            url += '&startDate=' + startDate;
        }

        if (endDate) {
            url += '&endDate=' + endDate;
        }

        return this.http.get<MetricsObjectView[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    // Line metrics

    GetLineMetrics(lineId: number, type: string = 'monthly', startDate?: string, endDate?: string): Observable<MetricsObjectView> {
        let url: string = this.baseUrl + '/api/NewMetrics/Line/' + lineId + '/Get?type=' + type;

        if (startDate) {
            url += '&startDate=' + startDate;
        }

        if (endDate) {
            url += '&endDate=' + endDate;
        }

        return this.http.get<MetricsObjectView>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetLineQualityMetricsOptions(lineId: number): Observable<any> {
        let url: string = this.baseUrl + '/api/NewMetrics/Line/' + lineId + '/Quality/GetAll';

        return this.http.get<any>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetLineQualityMetrics(lineId: number, productId: number, qualityObject): Observable<any> {
        let url: string = this.baseUrl + '/api/NewMetrics/Line/' + lineId + '/Product/' + productId + '/Quality/Get';
        return this.http.post<any>(url, qualityObject).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetLineQualityLossMetrics(lineId: number, qualityObject): Observable<any> {
        let url: string = this.baseUrl + '/api/NewMetrics/Line/' + lineId + '/Losses/Quality/Get';
        return this.http.post<any>(url, qualityObject).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetLineAvailabilityLossMetrics(lineId: number, qualityObject): Observable<any> {
        let url: string = this.baseUrl + '/api/NewMetrics/Line/' + lineId + '/Losses/Availability/Get';
        return this.http.post<any>(url, qualityObject).pipe(
            map(this.utilityService.extractData)
        );
    }

    // Import 

    ImportMetrics(instanceId: number, importData: any): Observable<any> {
        const url: string = this.baseUrl + '/api/Metrics/' + instanceId + '/Import';
        return this.http.post<any>(url, importData).pipe(
            map(this.utilityService.extractData)
        );
    }
}