// Angular Imports
import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';

// Services
import { UtilityService } from 'src/platform-app/services/static/utility.service';
import { ProductService } from 'src/platform-app/services/apis/product.service';

// Third Party Imports
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductView } from 'src/platform-app/models/product.model';
import { ProductWheelBatchView } from 'src/platform-app/models/batch.model';

@Component({
  selector: 'product-wheel',
  templateUrl: './product-wheel.component.html',
  styleUrls: ['./product-wheel.component.scss']
})

export class ProductWheelModalComponent implements OnInit, OnDestroy {
  public eventFunction: any;
  public batch = new ProductWheelBatchView();
  public familyId: number;
  public lineId: number;
  public products: ProductView[] = [];

  @ViewChild('ModalForm', { static: true }) public modalForm: NgForm;
  public subscriptions = new Subscription();

  constructor(
    private dialogRef: MatDialogRef<ProductWheelModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private productService: ProductService,
    private utilityService: UtilityService
  ) { }

  ngOnInit() {
    this.familyId = this.data.familyId;
    this.lineId = this.data.lineId;

    this.subscriptions.add(
      this.productService.GetProductsByFamilyAndLineId(this.familyId, this.lineId).subscribe((products: ProductView[]) => {
        this.products = products;
      }, (error) => {
        console.log(error);
      })
    );

    this.eventFunction = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        this.Save();
      }
    }

    document.addEventListener('keyup', this.eventFunction, false); // Submitting popup on press of Enter key
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    document.removeEventListener('keyup', this.eventFunction, false); // Removing event listener
  }

  Cancel() {
    this.dialogRef.close();
    document.removeEventListener('keyup', this.eventFunction, false); // Removing event listener
  }

  Save() {
    if (this.modalForm.valid) {
      this.dialogRef.close(this.batch);
      document.removeEventListener('keyup', this.eventFunction, false); // Removing event listener
    }
  }

  TrackByFunction(index, item) {
    return item.id;
  }
}
