// Angular Imports
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()

export class SpinnerService {
    public showTopSpinner = new BehaviorSubject<boolean>(false);
    public showGlobalSpinner = new BehaviorSubject<boolean>(false);
    constructor() { }
}
