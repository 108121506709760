// Angular Imports
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';

// Services

// Third Party Imports
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'information-dialog-modal',
  templateUrl: './information-dialog.component.html',
  styleUrls: ['./information-dialog.component.scss']
})

export class InformationDialogModalComponent implements OnInit, OnDestroy {
  public eventFunction: any;

  constructor(
    private dialogRef: MatDialogRef<InformationDialogModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.eventFunction = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        this.CloseModal();
      }
    }

    document.addEventListener('keyup', this.eventFunction, false); // Submitting popup on press of Enter key
  }

  ngOnDestroy() {
    document.removeEventListener('keyup', this.eventFunction, false); // Removing event listener
  }

  // Cancel() {
  //   this.dialogRef.close();
  //   document.removeEventListener('keyup', this.eventFunction, false); // Removing event listener
  // }

  CloseModal() {
    this.dialogRef.close();
    document.removeEventListener('keyup', this.eventFunction, false); // Removing event listener
  }
}
