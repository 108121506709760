// Angular Imports
import { Component, OnInit, OnChanges, Input } from '@angular/core';

// Service 
import { UtilityService } from 'src/platform-app/services/static/utility.service';

// Models
import { SiteView } from 'src/platform-app/models/site.model';
import { BusinessView } from 'src/platform-app/models/business.model';
import { ModuleView } from 'src/platform-app/models/utilitymodels';

@Component({
  selector: 'navigation-item',
  templateUrl: './navigation-item.component.html',
  styleUrls: ['./navigation-item.component.scss']
})

export class NavigationItemComponent implements OnInit, OnChanges {
  @Input() public module: ModuleView;
  @Input() public sites: SiteView[];
  @Input() public businesses: BusinessView[];
  @Input() public currentRoute: string;
  @Input() public icon: string;
  @Input() public rollOut = false;
  public assemblyBusinesses: BusinessView[];
  public disassemblyBusinesses: BusinessView[];
  public showDropDown = false;
  public showSubNav = false;

  constructor(
    private utilityService: UtilityService
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.businesses) {
      this.assemblyBusinesses = this.businesses.filter(business => business.assembly);
      this.disassemblyBusinesses = this.businesses.filter(business => business.disassembly);
    }
  }

  UrlContains(value: string) {
    if (this.currentRoute) {
      if (this.currentRoute.includes(value)) {
        return true;
      }
    }
    return false;
  }

  TrackByFunction(index, item) {
    return item.id;
  }
}
