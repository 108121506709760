// Angular Imports
import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { NgModel, NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { forkJoin } from 'rxjs';

// Services
import { AccountService } from 'src/platform-app/services/apis/account.service';
import { SiteService } from 'src/platform-app/services/apis/site.service';
import { LineService } from 'src/platform-app/services/apis/line.service';
import { MetricsService } from 'src/platform-app/services/apis/metrics.service';
import { TechnologyPlanService } from 'src/platform-app/services/apis/technology-plan.service';
import { UtilityService } from 'src/platform-app/services/static/utility.service';

// Components

// Models
import { AccountSmallView } from 'src/platform-app/models/account.model';
import { SiteView } from 'src/platform-app/models/site.model';
import { TechnologyPlanCard, TechnologyPlanCategoryView, TechnologyPlanPriorityView } from 'src/platform-app/models/technology-plan.model';
import { LineView } from 'src/platform-app/models/line.model';

// Third Party Imports
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'technology-plan-card-modal',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})

export class TechnologyPlanCardModalComponent implements OnInit, OnDestroy {
  public eventFunction: any;
  public businessId: number; // Business id
  public card: TechnologyPlanCard = new TechnologyPlanCard(); // Card object
  public sites: SiteView[] = []; // Array of sites
  public lines: LineView[] = []; // Array of lines
  public categories: TechnologyPlanCategoryView[] = []; // Array of categories
  public priorities: TechnologyPlanPriorityView[] = []; // Array of priorities
  public accounts: AccountSmallView[] = []; // List of accounts
  public metrics: any // Metrics object 
  public selectedMetrics = []; // List of selected metrics
  public npv: number;
  public allMetricSelections = [
    {
      alias: 'uptime',
      name: 'Uptime %'
    },
    {
      alias: 'fpfq',
      name: 'FPFQ / Yield %'
    },
    {
      alias: 'maxCapacity',
      name: 'Max. Capacity'
    },
    {
      alias: 'rawMaterialCostPerTon',
      name: 'Raw Material Cost'
    },
    {
      alias: 'fixedCost',
      name: 'Plant Fixed Cost'
    },
    {
      alias: 'otherVariableCostPerTon',
      name: 'Other Variable Cost'
    },
    {
      alias: 'totalCompany',
      name: 'Total Company'
    },
    {
      alias: 'totalContract',
      name: 'Total Contract'
    },
    {
      alias: 'oshaRecordable',
      name: 'OSHA Recordable'
    }
  ];
  public metricSelections = [];
  @ViewChild('ModalForm', { static: true }) public modalForm: NgForm;
  @ViewChild('selectedMetric', { static: false }) selectedMetric: NgModel;

  public subscriptions = new Subscription();

  constructor(
    private dialogRef: MatDialogRef<TechnologyPlanCardModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private accountService: AccountService,
    private siteService: SiteService,
    private lineService: LineService,
    private metricsService: MetricsService,
    private technologyPlanService: TechnologyPlanService,
    private utilityService: UtilityService
  ) { }

  ngOnInit() {
    this.businessId = this.data.businessId; // Setting the business id

    if (this.data.card) {
      this.card = this.data.card;

      if (!this.card.siteId && !this.card.lineId) {
        // this.subscriptions.add(
        //   this.metricsService.GetMetricsByBusinessId(this.businessId).subscribe((businessMetrics: MetricsView) => { // Getting line metrics
        //     this.metrics = businessMetrics;
        //     this.InitilizeCard();
        //   }, (error) => {
        //     console.log(error);
        //   })
        // );
      } else if (this.card.siteId && !this.card.lineId) {
        // this.subscriptions.add(
        //   this.metricsService.GetMetricsBySiteAndBusinessId(this.card.siteId, this.businessId).subscribe((siteMetrics: MetricsView) => { // Getting line metrics
        //     this.metrics = siteMetrics;
        //     this.InitilizeCard();
        //   }, (error) => {
        //     console.log(error);
        //   })
        // );
      } else if (this.card.siteId && this.card.lineId) {
        // this.subscriptions.add(
        //   this.metricsService.GetLineMetrics(this.card.lineId).subscribe((lineMetrics: MetricsView) => { // Getting line metrics
        //     this.metrics = lineMetrics;
        //     this.InitilizeCard();
        //   }, (error) => {
        //     console.log(error);
        //   })
        // );
      }

      if (this.card.siteId) {
        this.subscriptions.add(
          this.lineService.GetLinesBySiteId(this.card.siteId).subscribe((lines: LineView[]) => {
            this.lines = lines;
          }, (error) => {
            console.log(error);
          })
        );
      } else {
        this.card.siteId = -1;
      }
    }

    this.UpdateSelections(); // Updates metrics selection dropdown

    this.subscriptions.add(
      this.siteService.GetSitesByBusinessId(this.businessId).subscribe((sites: SiteView[]) => {
        this.sites = sites;
      }, (error) => {
        console.log(error);
      })
    );

    this.subscriptions.add(
      this.technologyPlanService.GetTechnologyPlanCategoriesByBusinessId(this.businessId).subscribe((categories: TechnologyPlanCategoryView[]) => {
        this.categories = categories;
      }, (error) => {
        console.log(error);
      })
    );

    this.subscriptions.add(
      this.accountService.GetAllCompanyAccounts().subscribe((accounts: AccountSmallView[]) => {
        this.accounts = accounts;
      }, (error) => {
        console.log(error);
      })
    );

    this.subscriptions.add(
      this.technologyPlanService.GetTechnologyPlanPriorities().subscribe((priorities: TechnologyPlanPriorityView[]) => {
        this.priorities = priorities;
      }, (error) => {
        console.log(error);
      })
    );

    this.eventFunction = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        this.EditCard();
      }
    }

    document.addEventListener('keyup', this.eventFunction, false); // Submitting popup on press of Enter key
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    document.removeEventListener('keyup', this.eventFunction, false); // Removing event listener
  }

  InitilizeCard() {
    this.selectedMetrics = [];
    this.metrics.uptime -= this.card.uptime;
    this.metrics.fpfq -= this.card.fpfq;
    this.metrics.maxCapacity -= this.card.maxCapacity;
    this.metrics.rawMaterialCostPerTon -= this.card.rawMaterialCostPerTon;
    this.metrics.fixedCost -= this.card.fixedCost;
    this.metrics.otherVariableCostPerTon -= this.card.otherVariableCostPerTon;
    this.metrics.totalCompany -= this.card.totalCompany;
    this.metrics.totalContract -= this.card.totalContract;
    this.metrics.oshaRecordable -= this.card.oshaRecordable;

    this.card.uptime *= 100; // Converting decimal to %
    this.card.fpfq *= 100; // Converting decimal to %

    for (let i = 0; i < this.metricSelections.length; i++) {
      if (this.metricSelections[i].alias === "uptime") {
        if (this.card.uptime !== 0) {
          this.selectedMetrics.push(this.metricSelections[i]);
        }
      } else if (this.metricSelections[i].alias === "fpfq") {
        if (this.card.fpfq !== 0) {
          this.selectedMetrics.push(this.metricSelections[i]);
        }
      } else if (this.metricSelections[i].alias === "maxCapacity") {
        if (this.card.maxCapacity !== 0) {
          this.selectedMetrics.push(this.metricSelections[i]);
        }
      } else if (this.metricSelections[i].alias === "rawMaterialCostPerTon") {
        if (this.card.rawMaterialCostPerTon !== 0) {
          this.selectedMetrics.push(this.metricSelections[i]);
        }
      } else if (this.metricSelections[i].alias === "fixedCost") {
        if (this.card.fixedCost !== 0) {
          this.selectedMetrics.push(this.metricSelections[i]);
        }
      } else if (this.metricSelections[i].alias === "otherVariableCostPerTon") {
        if (this.card.otherVariableCostPerTon !== 0) {
          this.selectedMetrics.push(this.metricSelections[i]);
        }
      } else if (this.metricSelections[i].alias === "totalCompany") {
        if (this.card.totalCompany !== 0) {
          this.selectedMetrics.push(this.metricSelections[i]);
        }
      } else if (this.metricSelections[i].alias === "totalContract") {
        if (this.card.totalContract !== 0) {
          this.selectedMetrics.push(this.metricSelections[i]);
        }
      } else if (this.metricSelections[i].alias === "oshaRecordable") {
        if (this.card.oshaRecordable !== 0) {
          this.selectedMetrics.push(this.metricSelections[i]);
        }
      }
    }

    this.RefreshSelections();
  }

  UpdateSelections() {
    this.metricSelections = [];
    if ((this.card.siteId && this.card.siteId !== -1) && (this.card.lineId && this.card.lineId !== -1)) {
      this.metricSelections = this.allMetricSelections;
    } else if ((this.card.siteId && this.card.siteId !== -1) && (!this.card.lineId || this.card.lineId === -1)) {
      this.metricSelections.push(this.allMetricSelections[3]);
      this.metricSelections.push(this.allMetricSelections[4]);
      this.metricSelections.push(this.allMetricSelections[5]);
      this.metricSelections.push(this.allMetricSelections[6]);
      this.metricSelections.push(this.allMetricSelections[7]);

      this.card.uptime = 0;
      this.card.fpfq = 0;
      this.card.maxCapacity = 0;
      this.card.oshaRecordable = 0;

      this.UnselectMetric('uptime');
      this.UnselectMetric('fpfq');
      this.UnselectMetric('maxCapacity');
      this.UnselectMetric('oshaRecordable');

    } else if ((!this.card.siteId || this.card.siteId === -1) && (!this.card.lineId || this.card.lineId === -1)) {
      this.metricSelections.push(this.allMetricSelections[3]);
      this.metricSelections.push(this.allMetricSelections[4]);
      this.metricSelections.push(this.allMetricSelections[5]);
      this.metricSelections.push(this.allMetricSelections[6]);
      this.metricSelections.push(this.allMetricSelections[7]);
      this.metricSelections.push(this.allMetricSelections[8]);

      this.card.uptime = 0;
      this.card.fpfq = 0;
      this.card.maxCapacity = 0;

      this.UnselectMetric('uptime');
      this.UnselectMetric('fpfq');
      this.UnselectMetric('maxCapacity');
    }
  }

  GetSiteData() {
    if (this.card.siteId && this.card.siteId !== -1) {
      // this.subscriptions.add(
      //   forkJoin([this.metricsService.GetMetricsBySiteAndBusinessId(this.card.siteId, this.businessId), this.lineService.GetLinesBySiteAndBusinessId(this.card.siteId, this.businessId)]).subscribe(results => {
      //     this.metrics = results[0];
      //     this.lines = results[1];
      //     this.UpdateSelections(); // Updates metrics selection dropdown
      //     this.RefreshSelections();
      //   }, (error) => {
      //     console.log(error);
      //   })
      // );
    } else if (this.card.siteId === -1) {
      this.card.lineId = -1;
      // this.subscriptions.add(
      //   this.metricsService.GetMetricsBySiteAndBusinessId(this.card.siteId, this.businessId).subscribe((metrics: MetricsView) => {
      //     this.metrics = metrics;
      //     this.lines = [];
      //     this.UpdateSelections(); // Updates metrics selection dropdown
      //     this.RefreshSelections();
      //   }, (error) => {
      //     console.log(error);
      //   })
      // );
    }
  }

  GetLineData() {
    if (this.card.lineId && this.card.lineId !== -1) {
      // this.subscriptions.add(
      //   this.metricsService.GetLineMetrics(this.card.lineId).subscribe((metrics: MetricsView) => {
      //     this.metrics = metrics;
      //     this.UpdateSelections(); // Updates metrics selection dropdown
      //     this.RefreshSelections();
      //   }, (error) => {
      //     console.log(error);
      //   })
      // );
    } else if (this.card.lineId && this.card.lineId === -1) {
      // this.subscriptions.add(
      //   this.metricsService.GetMetricsBySiteAndBusinessId(this.card.siteId, this.businessId).subscribe((metrics: MetricsView) => {
      //     this.metrics = metrics;
      //     this.UpdateSelections(); // Updates metrics selection dropdown
      //     this.RefreshSelections();
      //   }, (error) => {
      //     console.log(error);
      //   })
      // );
    }
  }

  CheckSelected(alias: string) {
    for (let selectedMetric of this.selectedMetrics) {
      if (selectedMetric.alias === alias) {
        return true;
      }
    }
    return false;
  }

  UnselectMetric(alias: string) {
    for (let i = 0; i < this.selectedMetrics.length; i++) {
      if (this.selectedMetrics[i].alias === alias) {
        this.selectedMetrics.splice(i, 1);
      }
    }
  }

  RefreshSelections() {
    let tempArray = [];

    for (let selectedMetric of this.selectedMetrics) {
      tempArray.push(selectedMetric);
    }

    this.selectedMetrics = tempArray;
    this.selectedMetric.update.emit(this.selectedMetrics);
  }

  GetNpv() {
    this.npv = -this.card.investment + (this.card.cashFlow1 / (1 + this.card.rate / 100)) + (this.card.cashFlow2 / Math.pow(1 + this.card.rate / 100, 2)) + (this.card.cashFlow3 / Math.pow(1 + this.card.rate / 100, 3)) + (this.card.cashFlow4 / Math.pow(1 + this.card.rate / 100, 4)) + (this.card.cashFlow5 / Math.pow(1 + this.card.rate / 100, 5));
  }

  Cancel() {
    this.dialogRef.close();
    document.removeEventListener('keyup', this.eventFunction, false); // Removing event listener
  }

  EditCard() {
    if (this.modalForm.valid) {
      if (this.card.siteId === -1) {
        this.card.siteId = null;
      }

      if (this.card.lineId === -1) {
        this.card.lineId = null;
      }

      this.card.uptime /= 100; // Converting % to decimal
      this.card.fpfq /= 100; // Converting % to decimal
      this.card.rate /= 100; // Converting % to decimal
      this.dialogRef.close(this.card);
      document.removeEventListener('keyup', this.eventFunction, false); // Removing event listener
    }
  }

  TrackByFunction(index, item) {
    return item.id;
  }
}
