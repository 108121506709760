// Angular Imports
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Subscription } from 'rxjs';

// Services
import { LineService } from 'src/platform-app/services/apis/line.service';
import { UtilityService } from 'src/platform-app/services/static/utility.service';

// Models
import { LineView } from 'src/platform-app/models/line.model';

// Third Party Imports
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'filter-scheduler-lines-modal',
  templateUrl: './filter-scheduler-lines.component.html',
  styleUrls: ['./filter-scheduler-lines.component.scss']
})

export class FilterSchedulerLinesModalComponent implements OnInit, OnDestroy {
  public eventFunction: any;
  public lines: LineView[] = [];
  public selectedLines: LineView[] = [];

  public subscriptions = new Subscription();

  constructor(
    private dialogRef: MatDialogRef<FilterSchedulerLinesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private lineService: LineService,
    private snackBar: MatSnackBar,
    private utilityService: UtilityService
  ) { }

  ngOnInit() {
    if (this.data.familyId) {
      this.subscriptions.add(
        this.lineService.GetLinesByFamilyId(this.data.familyId).subscribe((lines: LineView[]) => {
          this.lines = lines;
        }, (error) => {
          console.log(error);
          this.snackBar.open('Something went wrong. Please, try again later.', 'close', { duration: 2500, panelClass: ['mfg-background-danger'] });
        })
      );
    }

    this.selectedLines = this.data.selectedLines;

    this.eventFunction = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        this.Save();
      }
    }

    document.addEventListener('keyup', this.eventFunction, false); // Submitting popup on press of Enter key
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    document.removeEventListener('keyup', this.eventFunction, false); // Removing event listener
  }

  Cancel() {
    this.dialogRef.close();
    document.removeEventListener('keyup', this.eventFunction, false); // Removing event listener
  }

  Save() {
    this.dialogRef.close(this.selectedLines);
    document.removeEventListener('keyup', this.eventFunction, false); // Removing event listener
  }

  TrackByFunction(index, item) {
    return item.id;
  }

  CompareWithFunction(item1, item2) {
    return item1 && item2 ? item1.name === item2.name : item1 === item2;
  }
}