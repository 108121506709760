// Angular Imports
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from "rxjs/operators";
import { environment } from '../../../environments/environment';

// Models
import { AccountSmall, AccountSmallView, DashboardCards } from 'src/platform-app/models/account.model';

// Services
import { UtilityService } from '../static/utility.service';

@Injectable()

export class AccountService {
    public baseUrl: string = environment.endpoint;

    constructor(
        private http: HttpClient,
        private utilityService: UtilityService
    ) { }

    GetAllAccounts(): Observable<AccountSmallView[]> {
        const url: string = this.baseUrl + '/api/Account/GetAll';
        return this.http.get(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetAllCompanyAccounts(): Observable<AccountSmallView[]> {
        const url: string = this.baseUrl + '/api/Account/Company/GetAll';
        return this.http.get(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetLoggedInAccount(): Observable<AccountSmallView> {
        const url: string = this.baseUrl + '/api/Account/GetLoggedIn';
        return this.http.get(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    EditLoggedInAccount(account: AccountSmallView): Observable<any> {
        const url: string = this.baseUrl + '/api/Account/Edit';
        return this.http.put(url, account).pipe(
            map(this.utilityService.extractData)
        );
    }


    EditDefaultCompany(companyId: number): Observable<any> {
        const url: string = this.baseUrl + '/api/Admin/Account/Company/' + companyId + '/Edit';
        return this.http.put(url, null).pipe(
            map(this.utilityService.extractData)
        );
    }

    UpdateModularity(dashboardCards: DashboardCards): Observable<any> {
        const url: string = this.baseUrl + '/api/Account/Modularity';
        return this.http.post(url, dashboardCards).pipe(
            map(this.utilityService.extractData)
        );
    }
}