// Angular Imports
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from "rxjs/operators";
import { environment } from '../../../environments/environment';

// Services
import { UtilityService } from '../static/utility.service';
import { VersionView, Version } from 'src/platform-app/models/version.model';

// Models


@Injectable()
export class VersionService {
    public baseUrl: string = environment.endpoint;

    constructor(
        private http: HttpClient,
        private utilityService: UtilityService
    ) { }

    GetAllVersions(): Observable<VersionView[]> {
        const url: string = this.baseUrl + '/api/ReleaseNotes/GetAll';
        return this.http.get<VersionView[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }
}