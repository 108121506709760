// Angular Imports
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from "rxjs/operators";
import { environment } from '../../../environments/environment';

// Services
import { UtilityService } from '../static/utility.service';

// Models
import { ActionPlanInstance, ActionPlanInstanceView, ActionPlanCategoryView, ActionPlanCategory, ActionPlanInstanceSmall, ActionPlanInstanceSmallView } from 'src/platform-app/models/action-plan.model';

@Injectable()
export class ActionPlanService {
    public baseUrl: string = environment.endpoint;

    constructor(
        private http: HttpClient,
        private utilityService: UtilityService
    ) { }

    // Action Plan Instance

    CreateActionPlanInstanceBySiteId(siteId: number, instance: ActionPlanInstanceSmall): Observable<ActionPlanInstanceSmallView> {
        const url: string = this.baseUrl + '/api/ActionPlan/Site/' + siteId + '/Instance/Create';
        return this.http.post(url, instance).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetActionPlanInstancesBySiteId(siteId: number): Observable<ActionPlanInstanceView[]> {
        const url: string = this.baseUrl + '/api/ActionPlan/Site/' + siteId + '/Instance/GetAll';
        return this.http.get<ActionPlanInstanceView[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetActionPlanDefaultInstanceBySiteId(siteId: number): Observable<ActionPlanInstanceView> {
        const url: string = this.baseUrl + '/api/ActionPlan/Site/' + siteId + '/Instance/GetDefault';
        return this.http.get<ActionPlanInstanceView>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetActionPlanInstanceById(instanceId: number): Observable<ActionPlanInstanceView> {
        const url: string = this.baseUrl + '/api/ActionPlan/Instance/' + instanceId + '/Get';
        return this.http.get<ActionPlanInstanceView>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    SetActionPlanDefaultInstance(instanceId: number): Observable<any> {
        const url: string = this.baseUrl + '/api/ActionPlan/Instance/' + instanceId + '/SetDefault';
        return this.http.put(url, {}).pipe(
            map(this.utilityService.extractData)
        );
    }

    CopyActionPlanInstance(instanceId: number, name: string): Observable<any> {
        const url: string = this.baseUrl + '/api/ActionPlan/Instance/' + instanceId + '/Copy?name=' + name;
        return this.http.post(url, null).pipe(
            map(this.utilityService.extractData)
        );
    }

    EditActionPlanInstance(instanceId: number, instance: ActionPlanInstanceSmall): Observable<ActionPlanInstanceSmallView> {
        const url: string = this.baseUrl + '/api/ActionPlan/Instance/' + instanceId + '/Edit';
        return this.http.put(url, instance).pipe(
            map(this.utilityService.extractData)
        );
    }

    DeleteActionPlanInstance(instanceId: number): Observable<any> {
        const url: string = this.baseUrl + '/api/ActionPlan/Instance/' + instanceId + '/Delete';
        return this.http.delete(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    // Action Plan Categories

    CreateActionPlanCategoryBySiteId(siteId: number, category: ActionPlanCategory): Observable<ActionPlanCategoryView> {
        const url: string = this.baseUrl + '/api/ActionPlan/Site/' + siteId + '/Category/Create';
        return this.http.post<ActionPlanCategoryView[]>(url, category).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetActionPlanCategoriesBySiteId(siteId: number): Observable<ActionPlanCategoryView[]> {
        const url: string = this.baseUrl + '/api/ActionPlan/Site/' + siteId + '/Category/GetAll';
        return this.http.get<ActionPlanCategoryView[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    EditActionPlanCategory(categoryId: number, category: ActionPlanCategory): Observable<ActionPlanCategoryView> {
        const url: string = this.baseUrl + '/api/ActionPlan/Category/' + categoryId + '/Edit';
        return this.http.put<ActionPlanCategoryView[]>(url, category).pipe(
            map(this.utilityService.extractData)
        );
    }

    DeleteActionPlanCategory(categoryId: number): Observable<any> {
        const url: string = this.baseUrl + '/api/ActionPlan/Category/' + categoryId + '/Delete';
        return this.http.delete<ActionPlanCategoryView[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    // Action Plan Year

    // EditActionPlanYear(yearId: number, year: ActionPlanYear): Observable<any> {
    //     const url: string = this.baseUrl + '/api/ActionPlan/Year/' + yearId + '/Edit';
    //     return this.http.put(url, year).pipe(
    //         map(this.utilityService.extractData)
    //     );
    // }

    // Action Plan Card

    // CreateActionPlanCard(instanceId: number, card: ActionPlanCard): Observable<any> {
    //     const url: string = this.baseUrl + '/api/ActionPlan/Instance/' + instanceId + '/Card/Create';
    //     return this.http.post(url, card).pipe(
    //         map(this.utilityService.extractData)
    //     );
    // }

    GetActionPlanCardsBySiteId(siteId: number): Observable<any[]> {
        const url: string = this.baseUrl + '/api/ActionPlan/Site/' + siteId + '/Card/GetAll';
        return this.http.get<any[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    // EditActionPlanCard(cardId: number, card: ActionPlanCard): Observable<any> {
    //     const url: string = this.baseUrl + '/api/ActionPlan/Card/' + cardId + '/Edit';
    //     return this.http.put(url, card).pipe(
    //         map(this.utilityService.extractData)
    //     );
    // }

    DeleteActionPlanCard(cardId: number): Observable<any> {
        const url: string = this.baseUrl + '/api/ActionPlan/Card/' + cardId + '/Delete';
        return this.http.delete(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    // Action Plan Metrics

    // GetActionPlanMetricsByInstanceId(instanceId: number): Observable<ActionPlanMetricsObject[]> {
    //     const url: string = this.baseUrl + '/api/ActionPlan/Instance/' + instanceId + '/Metrics/GetAll';
    //     return this.http.get<ActionPlanMetricsObject[]>(url).pipe(
    //         map(this.utilityService.extractData)
    //     );
    // }
}