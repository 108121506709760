// Angular Imports
import { ErrorHandler, Injectable } from '@angular/core';
import { Subscription } from "rxjs";

// Services
import { ErrorObject, ErrorService } from '../apis/error.service';

@Injectable()

export class ErrorHandlerService implements ErrorHandler {
    public subscriptions = new Subscription();

    constructor(
        private errorService: ErrorService
    ) { }

    handleError(error: Error) {
        let errorObject = new ErrorObject;
        errorObject.url = window.location.href;
        errorObject.errorType = 'client-side';
        errorObject.errorMessage = error.message || 'Error does not have content';

        console.log('Client-side error occured: ', error.message);

        this.subscriptions.add(
            this.errorService.LogError(errorObject).subscribe((response: any) => {})
        );

        throw error;
    }
}