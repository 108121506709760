// Angular Imports
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';

// Services
import { UtilityService } from 'src/platform-app/services/static/utility.service';
import { DataService } from 'src/platform-app/services/static/data.service';

// Third Party Imports
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Components

@Component({
  selector: 'dashboard-card-modal',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})

export class DashboardCardModalComponent implements OnInit, OnDestroy {
  public eventFunction: any;
  public selectedCards = [];
  public allCards = [];
  public allowedCardTypes = [];
  public allowedCards = [];
  public selectedCard;

  constructor(
    private dialogRef: MatDialogRef<DashboardCardModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private utilityService: UtilityService,
    private dataService: DataService
  ) { }

  ngOnInit() {
    this.selectedCards = this.data.selectedCards;
    // this.allCards = this.dataService.GetAllCards();
    this.selectedCard = this.allCards[0];
    this.allowedCardTypes = this.dataService.GetDefaultDashboardCards(this.data.cardsType);
    this.allowedCards = this.allCards.filter(card => this.allowedCardTypes.includes(card.type));

    for (let card of this.allowedCards) {
      for (let selectedCard of this.selectedCards) {
        if (selectedCard.type === card.type) {
          card.checked = true;
        }
      }
    }

    this.eventFunction = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        this.Save();
      }
    }

    document.addEventListener('keyup', this.eventFunction, false); // Submitting popup on press of Enter key
  }

  ngOnDestroy() {
    document.removeEventListener('keyup', this.eventFunction, false); // Removing event listener
  }

  TrackByFunction(index, item) {
    return item.id;
  }

  Cancel() {
    this.dialogRef.close();
  }

  Save() {
    let finalCardArray = [];

    for (let selectedCard of this.selectedCards) {
      for (let i = 0; i < this.allowedCards.length; i++) {
        if (selectedCard.type === this.allowedCards[i].type && this.allowedCards[i].checked) {
          finalCardArray.push(selectedCard);
          this.allowedCards.splice(i, 1);
        }
      }
    }

    for (let allowedCard of this.allowedCards) {
      if (allowedCard.checked) {
        let card = {
          type: allowedCard.type,
          width: 1
        }

        finalCardArray.push(card);
      }
    }
    this.dialogRef.close(finalCardArray);
    document.removeEventListener('keyup', this.eventFunction, false); // Removing event listener
  }
}
