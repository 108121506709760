// Angular Imports
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';

// Services
import { StorageService } from 'src/platform-app/services/static/storage.service';
import { UtilityService } from 'src/platform-app/services/static/utility.service';
import { DataService } from 'src/platform-app/services/static/data.service';

// Models
import { CompanyView } from 'src/platform-app/models/company.model';
import { SiteView } from 'src/platform-app/models/site.model';
import { BusinessView } from 'src/platform-app/models/business.model';

@Component({
  selector: 'left-navigation',
  templateUrl: './left-navigation.component.html',
  styleUrls: ['./left-navigation.component.scss']
})

export class LeftNavigationComponent implements OnInit, OnDestroy {
  @Input() public currentRoute: string;
  public rollOut = false;
  public company: CompanyView;
  public sites: SiteView[] = [];
  public businesses: BusinessView[] = [];
  public siteId: number;
  public businessId: number;
  public modules = [];
  public subscriptions = new Subscription();

  constructor(
    private storageService: StorageService,
    private utilityService: UtilityService,
    private dataService: DataService
  ) { }

  ngOnInit() {
    this.subscriptions.add(
      this.storageService.company.subscribe(company => {
        this.company = company;
        if (this.company) {
          this.modules = this.dataService.GetNavigationArray(this.company.id);
        }

        this.subscriptions.add(
          this.storageService.companySites.subscribe(sites => {
            this.sites = sites;
          }, (error) => {
            console.log(error);
          })
        );

        this.subscriptions.add(
          this.storageService.companyBusinesses.subscribe(businesses => {
            this.businesses = businesses;
          }, (error) => {
            console.log(error);
          })
        );

        this.subscriptions.add(
          this.storageService.currentSiteId.subscribe(siteId => {
            this.siteId = siteId;
          }, (error) => {
            console.log(error);
          })
        );

        this.subscriptions.add(
          this.storageService.currentBusinessId.subscribe(businessId => {
            this.businessId = businessId;
          }, (error) => {
            console.log(error);
          })
        );
      }, (error) => {
        console.log(error);
      })
    );

    this.subscriptions.add(
      this.storageService.rollOut.subscribe((rollOut) => {
        this.rollOut = rollOut;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ToggleRollOut() {
    this.rollOut = !this.rollOut;
    this.storageService.rollOut.next(this.rollOut);
  }

  TrackByFunction(index, item) {
    return item.id;
  }
}
