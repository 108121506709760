export class Ingredient {
    type: string;
    materialId: number;
    quantity: number;
}

export class IngredientView {
    id: number;
    type: string;
    material: any;
    quantity: number;
}