// Angular Imports
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from "rxjs/operators";
import { environment } from '../../../environments/environment';

// Models
import { AccountSmall, AccountSmallView, DashboardCards, RoleView } from 'src/platform-app/models/account.model';

// Services
import { UtilityService } from '../static/utility.service';

@Injectable()

export class AdminService {
    public baseUrl: string = environment.endpoint;

    constructor(
        private http: HttpClient,
        private utilityService: UtilityService
    ) { }

    GetAllRoles(): Observable<RoleView[]> {
        const url: string = this.baseUrl + '/api/Role/GetAll';
        return this.http.get<RoleView[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    EditAccount(accountId: string, account: AccountSmall): Observable<any> {
        const url: string = this.baseUrl + '/api/Admin/Account/' + accountId + '/Edit';
        return this.http.put(url, account).pipe(
            map(this.utilityService.extractData)
        );
    }
}