// Model Imports
import { ProductView } from "./product.model";
import { LineView } from "./line.model";
import { RegionView } from "./region.model";

// Batch
export class Batch {
    order: number;
    quantity: number;
    fromTime: string;
    toTime: string;
    rate: number;
    maxRate: number;
    duration: number;
    productId: number;
    lineId: number;
    regionId: number;
}

export class BatchView {
    id: number;
    order: number;
    quantity: number;
    fromTime: string;
    toTime: string;
    rate: number;
    duration: number;
    product: ProductView;
    line: LineView;
    maxRate: number;
    region: RegionView;
    cxo: number;
}

export class CalendarBatchView extends BatchView {
    calendarFromTime: string;
    calendarToTime: string;
}

// Multiple

export class BatchMultipleEdit {
    quantity: number;
    ids: number[] = [];
}

// Product Wheel Batch

export class ProductWheel {
    line: LineView;
    batches: ProductWheelBatchView[];
}

export class ProductWheelBatch {
    productId: number;
    quantity: number;
}

export class ProductWheelBatchView {
    product: ProductView;
    quantity: number;
}