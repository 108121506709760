// Angular Imports
import { Component, OnInit, Inject } from '@angular/core';

// Third Party Imports
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Models
import { UploadSettings } from 'src/platform-app/models/utilitymodels';

@Component({
  selector: 'file-upload-modal',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})

export class FileUploadModalComponent implements OnInit {
  public originalUrl: string;
  public uploadSettings: UploadSettings;
  public cropperSettings: any;

  constructor(
    private dialogRef: MatDialogRef<FileUploadModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    if (this.data.uploadSettings) {
      this.uploadSettings = this.data.uploadSettings;
    }

    if (this.data.originalUrl) {
      this.originalUrl = this.data.originalUrl;
    }

    if (this.data.cropperSettings) {
      this.cropperSettings = this.data.cropperSettings;
    }
  }

  Cancel() {
    this.dialogRef.close();
  }

  Upload(event) {
    this.dialogRef.close(event);
  }
}
