// Model Imports
import { TechnologyPlanYearView } from "./technology-plan.model";
import { BatchView, CalendarBatchView } from "./batch.model";
import { FamilyView } from "./family.model";
import { ProductView } from "./product.model";
import { RegionView } from "./region.model";
import { RawMaterialView } from "./raw-material.model";
import { SiteView } from "./site.model";

// Scheduler
export class SchedulerInstance {
    name: string;
}

export class SchedulerInstanceSmallView {
    id: number;
    name: string;
    default: boolean;
}

export class SchedulerInstanceView {
    id: number;
    name: string;
    lines: TechnologyPlanYearView[];
    default: boolean;
}

export class FamilyScheduleView {
    id: number;
    name: string;
    lines: LineScheduleView[];
    startDate: string;
    endDate: string;
}

export class LineScheduleView {
    id: number;
    name: string;
    batches: BatchView[];
    region: RegionView;
    site: SiteView;
    hasProductWheel?: boolean;
}

export class CalendarLineScheduleView {
    id: number;
    name: string;
    batches: CalendarBatchView[];
    region: RegionView;
    site: SiteView;
}

// Transfer

export class SchedulerTransfer {
    productId: number;
    fromDate: Date;
    fromRegionId: number;
    toRegionId: number;
    quantity: number;
}

export class SchedulerTransferView {
    instanceId: number;
    family: FamilyView;
    product: ProductView;
    fromDate: string;
    toDate: string;
    fromRegion: RegionView;
    toRegion: RegionView;
    quantity: number;
    cost: number;
}

export class BillOfMaterials {
    rawMaterial: RawMaterialView;
    quantity: number;
    cost: number;
}