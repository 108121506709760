// Angular Imports
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from "rxjs/operators";
import { environment } from '../../../environments/environment';

// Services
import { UtilityService } from '../static/utility.service';

// Models
import { SiteView, Site } from 'src/platform-app/models/site.model';

@Injectable()
export class SiteService {
    public baseUrl: string = environment.endpoint;

    constructor(
        private http: HttpClient,
        private utilityService: UtilityService
    ) { }

    CreateSite(site: Site): Observable<SiteView> {
        const url: string = this.baseUrl + '/api/Site/Create';
        return this.http.post<Site>(url, site).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetAllSites(): Observable<SiteView[]> {
        const url: string = this.baseUrl + '/api/Site/GetAll';
        return this.http.get<SiteView[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetSiteById(siteId: number): Observable<SiteView> {
        const url: string = this.baseUrl + '/api/Site/' + siteId + '/Get';
        return this.http.get<SiteView>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetSitesByBusinessId(businessId: number): Observable<SiteView[]> {
        const url: string = this.baseUrl + '/api/Site/Business/' + businessId + '/GetAll';
        return this.http.get<SiteView[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    EditSite(siteId: number, site: Site): Observable<SiteView> {
        const url: string = this.baseUrl + '/api/Site/' + siteId + '/Edit';
        return this.http.put<SiteView>(url, site).pipe(
            map(this.utilityService.extractData)
        );
    }

    DeleteSite(siteId: number): Observable<any> {
        const url: string = this.baseUrl + '/api/Site/' + siteId + '/Delete';
        return this.http.delete(url).pipe(
            map(this.utilityService.extractData)
        );
    }
}