// Angular Imports
import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';

// Services
import { UtilityService } from 'src/platform-app/services/static/utility.service';

// Components

// Models
import { SiteView } from 'src/platform-app/models/site.model';
import { RawMaterialPrice, RawMaterialView, RawMaterialPriceView } from 'src/platform-app/models/raw-material.model';

// Third Party Imports
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'raw-material-price-modal',
  templateUrl: './raw-material-price.component.html',
  styleUrls: ['./raw-material-price.component.scss']
})

export class RawMaterialPriceModalComponent implements OnInit, OnDestroy {
  public eventFunction: any;
  public rawMaterialPrice: RawMaterialPrice;
  public rawMaterialPriceId: number;
  public rawMaterialPrices: RawMaterialPriceView[];
  public rawMaterials: RawMaterialView[] = [];
  public sites: SiteView[] = [];
  public formValid = true;
  @ViewChild('ModalForm', { static: true }) public modalForm: NgForm;
  public subscriptions = new Subscription();

  constructor(
    private dialogRef: MatDialogRef<RawMaterialPriceModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private utilityService: UtilityService
  ) { }


  ngOnInit() {
    if (this.data.rawMaterialPrice && this.data.rawMaterialPriceId) {
      this.rawMaterialPrice = this.data.rawMaterialPrice;
      this.rawMaterialPriceId = this.data.rawMaterialPriceId;
    } else {
      this.rawMaterialPrice = new RawMaterialPrice();
    }

    this.sites = this.data.sites;
    this.rawMaterials = this.data.rawMaterials;
    this.rawMaterialPrices = this.data.rawMaterialPrices;

    this.eventFunction = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        this.Save();
      }
    }

    document.addEventListener('keyup', this.eventFunction, false); // Submitting popup on press of Enter key
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  PriceExists() {
    let found = false;
    for (let price of this.rawMaterialPrices) {
      if (price.id !== this.rawMaterialPriceId) {
        if (price.rawMaterial.id === this.rawMaterialPrice.rawMaterialId && price.site.id === this.rawMaterialPrice.siteId) {
          found = true;
        }
      }
    }

    if (found) {
      this.formValid = false;
    } else {
      this.formValid = true;
    }
  }

  Cancel() {
    this.dialogRef.close();
    document.removeEventListener('keyup', this.eventFunction, false); // Removing event listener
  }

  Save() {
    if (this.modalForm.valid) {
      this.dialogRef.close(this.rawMaterialPrice);
    }
  }

  TrackByFunction(index, item) {
    return item.id;
  }
}
