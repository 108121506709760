// Angular Imports
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from "rxjs/operators";
import { environment } from '../../../environments/environment';

// Services
import { UtilityService } from '../static/utility.service';

@Injectable()
export class ErrorService {
    public baseUrl: string = environment.endpoint;

    constructor(
        private http: HttpClient,
        private utilityService: UtilityService
    ) { }

    LogError(error: ErrorObject): Observable<any> {
        const url: string = this.baseUrl + '/api/ErrorLog/Create';
        return this.http.post<ErrorObject>(url, error).pipe(
            map(this.utilityService.extractData)
        );
    }
}

export class ErrorObject {
    public url: string;
    public errorType: string;
    public errorMessage: string;
    public errorCode: number;
    public requestBody: any;
    public requestMethod: string;
}