// Site
export class Site {
    name: string;
    companyId: number;
    clientId: string;
    siteUrl: string;
    location: SiteLocationView;
    active: boolean;
    personnel: PersonnelObject;
    mtcRav: number;
    bicOee: number;
    bicUptime: number;
    bicMtcRav: number;
    bicScore: number;
    trir: number;
    variableCost: number;
    fixedCost: number;
}

export class SiteView {
    id: number;
    name: string;
    companyId: number;
    clientId: string;
    siteUrl: string;
    location: SiteLocationView;
    active: boolean;
    personnel: PersonnelObject;
    mtcRav: number;
    bicOee: number;
    bicUptime: number;
    bicMtcRav: number;
    bicScore: number;
    trir: number;
    variableCost: number;
    fixedCost: number;
}

export class PersonnelObject {
    total: number;
    other: number;
    quartiles: QuartileObject;
    maintenance: MaintenanceObject;
    technicalSupport: TechnicalSupportObject;
    managementSupport: ManagementSupportObject;
}

export class QuartileObject {
    first: number;
    second: number;
    third: number;
    fourth: number;
}

export class MaintenanceObject {
    mtcCraft: DepartmentObject;
    mtcContract: DepartmentObject;
    mtcOther: DepartmentObject;
}

export class TechnicalSupportObject {
    relEngineer: DepartmentObject;
    prodEngineer: DepartmentObject;
    projEngineer: DepartmentObject;
    qa: DepartmentObject;
    training: DepartmentObject;
}

export class ManagementSupportObject {
    management: DepartmentObject;
    she: DepartmentObject;
    hr: DepartmentObject;
    it: DepartmentObject;
    admin: DepartmentObject;
    supplyChain: DepartmentObject;
    otherOps: DepartmentObject;
}

export class DepartmentObject {
    value: number;
    average: number;
    bic: number;
}

// Site Location
export class SiteLocation {
    latitude: number;
    longitude: number;
    address: string;
    country: string;
    state: string;
    zipCode: number;
}

export class SiteLocationView {
    id: number;
    latitude: number;
    longitude: number;
    address: string;
    country: string;
    state: string;
    zipCode: number;
}