// Angular Imports
import { Component, OnInit, OnDestroy, HostListener, Renderer2 } from '@angular/core';
import { Router, RoutesRecognized, NavigationEnd, Event, NavigationStart, RouteConfigLoadStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { forkJoin } from 'rxjs';
import { environment } from '../environments/environment';
import { Title } from "@angular/platform-browser";

// Services
import { AuthenticationService } from 'src/platform-app/services/apis/authentication.service';
import { AccountService } from 'src/platform-app/services/apis/account.service';
import { CompanyService } from 'src/platform-app/services/apis/company.service';
import { SiteService } from 'src/platform-app/services/apis/site.service';
import { BusinessService } from 'src/platform-app/services/apis/business.service';
import { LineService } from 'src/platform-app/services/apis/line.service';
import { StorageService } from 'src/platform-app/services/static/storage.service';
import { SpinnerService } from 'src/platform-app/services/static/spinner.service';
import { RealTimeService } from 'src/platform-app/services/apis/real-time.service';
import { VersionService } from 'src/platform-app/services/apis/version.service';
import { DataService } from './services/static/data.service';

// Models
import { CompanyView } from './models/company.model';
import { AccountSmallView, AccountView } from './models/account.model';
import { SiteView } from './models/site.model';
import { BusinessView } from './models/business.model';
import { LineView } from './models/line.model';
import { PageView } from './models/utilitymodels';
import { VersionView } from './models/version.model';

// Third Party Imports
import { DeviceDetectorService } from 'ngx-device-detector';
import { Intercom } from 'ng-intercom';
import { MatSnackBar } from '@angular/material/snack-bar';

declare var ga: Function;

@Component({
  selector: 'platform-app',
  templateUrl: './platform-app.component.html',
  styleUrls: ['./platform-app.component.scss']
})

export class PlatformAppComponent implements OnInit, OnDestroy {
  public timeStamp: string = environment.timeStamp;
  public isLoggedIn = false;
  public showTopSpinner = false;
  public showGlobalSpinner = false;
  public stickyTopNav = true;
  public showHeaderFooter = true;
  public exceptionPages = ['login', 'not-found', 'not-authorized', 'password-reset'];
  public rollOut = false;
  public company: CompanyView;
  public sites: SiteView[];
  public siteId: number;
  public businesses: BusinessView[];
  public businessId: number;
  public lines: LineView[];
  public lineId: number;
  public currentRoute: string;
  public windowScrollY: number;
  public isScrollBottom: boolean;
  public modules: PageView[];
  public module: PageView;
  public account: AccountSmallView;
  public deviceInfo = null;
  public showBrowserWarning = false;
  public versions: VersionView[] = [];
  public subscriptions = new Subscription();

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private accountService: AccountService,
    private companyService: CompanyService,
    private siteService: SiteService,
    private businessService: BusinessService,
    private lineService: LineService,
    private storageService: StorageService,
    private spinnerService: SpinnerService,
    private snackBar: MatSnackBar,
    private deviceService: DeviceDetectorService,
    private titleService: Title,
    private intercom: Intercom,
    private realTimeService: RealTimeService,
    private versionService: VersionService,
    private renderer: Renderer2,
    private dataService: DataService,
  ) {
    this.GetRouteParameters();
    this.CheckBrowser();

    this.storageService.currentRoute.subscribe((currentRoute) => {
      this.showHeaderFooter = true;

      if (currentRoute) {
        this.currentRoute = currentRoute;
        for (let i = 0; i < this.exceptionPages.length; i++) {
          if (currentRoute.includes(this.exceptionPages[i])) {
            this.showHeaderFooter = false;
          }
        }

        this.GetCurrentModule();
      }
    });

    if (environment.googleAnalyticsId) {
      this.InjectGoogleAnalytics();
    }
  }

  ngOnInit() {
    console.log('Application environment is set to:', environment.environment);
    console.log('Last release timestamp is set to:', this.timeStamp);

    this.realTimeService.Connect();

    // Turning on spinner for lazy loading
    this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
        this.spinnerService.showGlobalSpinner.next(true);
      }
    });

    this.authService.isLoggedIn.next(this.authService.IsLoggedIn());

    this.subscriptions.add(
      this.authService.isLoggedIn.subscribe((isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;

        if (this.isLoggedIn) {
          this.subscriptions.add(
            this.accountService.GetLoggedInAccount().subscribe((account: AccountView) => {
              this.account = account;

              if (!this.account.profileImg) {
                this.account.profileImg = 'assets/img/default-profile-picture.png';
              }

              this.storageService.account.next(this.account);
              this.InitiateChat();

              this.subscriptions.add(
                this.companyService.GetCompany().subscribe((company: any) => {
                  this.storageService.company.next(company);
                  this.subscriptions.add(
                    this.storageService.company.subscribe((company: CompanyView) => {
                      this.company = company;
                      if (this.company) {
                        this.subscriptions.add(
                          forkJoin([this.siteService.GetAllSites(), this.businessService.GetAllBusinesses(), this.lineService.GetAllLines()]).subscribe(results => {
                            this.sites = results[0];
                            this.businesses = results[1];
                            this.lines = results[2];

                            this.storageService.companySites.next(this.sites);
                            this.storageService.companyBusinesses.next(this.businesses);
                            this.storageService.companyLines.next(this.lines);

                            this.subscriptions.add(
                              this.storageService.currentSiteId.subscribe((siteId: number) => {
                                if (siteId) {
                                  for (let site of this.sites) {
                                    if (site.id === siteId) {
                                      this.storageService.currentSite.next(site);
                                    }
                                  }
                                } else {
                                  this.storageService.currentSite.next(null);
                                }
                              })
                            );

                            this.subscriptions.add(
                              this.storageService.currentBusinessId.subscribe((businessId: number) => {
                                if (businessId) {
                                  for (let business of this.businesses) {
                                    if (business.id === businessId) {
                                      this.storageService.currentBusiness.next(business);
                                    }
                                  }
                                } else {
                                  this.storageService.currentBusiness.next(null);
                                }
                              })
                            );

                            this.subscriptions.add(
                              this.storageService.currentLineId.subscribe((lineId: number) => {
                                if (lineId) {
                                  for (let line of this.lines) {
                                    if (line.id === lineId) {
                                      this.storageService.currentLine.next(line);
                                    }
                                  }
                                } else {
                                  this.storageService.currentLine.next(null);
                                }
                              })
                            );

                            // Updating Google Analytics page
                            this.router.events.subscribe(val => {
                              if (val instanceof NavigationEnd) {
                                try {
                                  if (typeof ga === 'function') {
                                    ga('set', 'page', val.urlAfterRedirects);
                                    ga('set', 'userId', this.account.email);
                                    ga('send', 'pageview');
                                  }
                                } catch (error) {
                                  console.log(error);
                                }
                              }
                            });
                          }, (error) => {
                            console.log(error);
                            this.spinnerService.showGlobalSpinner.next(false);
                            this.snackBar.open('Something went wrong. Please, try again later.', 'close', { duration: 2500, panelClass: ['mfg-background-danger'] });
                          })
                        );
                      }
                    }, (error) => {
                      console.log(error);
                      this.spinnerService.showGlobalSpinner.next(false);
                      this.snackBar.open('Something went wrong. Please, try again later.', 'close', { duration: 2500, panelClass: ['mfg-background-danger'] });
                    })
                  )
                }, (error) => {
                  console.log(error);
                  this.spinnerService.showGlobalSpinner.next(false);
                  this.snackBar.open('Something went wrong. Please, try again later.', 'close', { duration: 2500, panelClass: ['mfg-background-danger'] });
                })
              );
            }, (error) => {
              console.log(error);
              this.spinnerService.showGlobalSpinner.next(false);
              this.snackBar.open('Something went wrong. Please, try again later.', 'close', { duration: 2500, panelClass: ['mfg-background-danger'] });
            })
          )

          // Getting App Version
          this.subscriptions.add(
            this.versionService.GetAllVersions().subscribe((versions: VersionView[]) => {
              this.versions = versions;
              this.storageService.versions.next(versions);
            }, (error) => {
              console.log(error);
              this.spinnerService.showGlobalSpinner.next(false);
              this.snackBar.open('Something went wrong. Please, try again later.', 'close', { duration: 2500, panelClass: ['mfg-background-danger'] });
            })
          );
        }
      })
    );

    this.subscriptions.add(
      this.spinnerService.showTopSpinner.subscribe((showTopSpinner) => {
        this.showTopSpinner = showTopSpinner;
      })
    );

    this.subscriptions.add(
      this.spinnerService.showGlobalSpinner.subscribe((showGlobalSpinner) => {
        if (showGlobalSpinner) {
          this.renderer.addClass(document.body, 'spinner-on');
        } else {
          this.renderer.removeClass(document.body, 'spinner-on');
        }
        this.showGlobalSpinner = showGlobalSpinner;
      })
    );

    this.subscriptions.add(
      this.storageService.rollOut.subscribe((rollOut) => {
        this.rollOut = rollOut;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  GetRouteParameters() {
    this.router.events.subscribe(val => {
      if (val instanceof RoutesRecognized) {
        if (val.state.root.firstChild.params['siteId']) {
          this.siteId = Number(val.state.root.firstChild.params['siteId']);
        } else {
          this.siteId = null;
        }

        if (val.state.root.firstChild.params['businessId']) {
          this.businessId = Number(val.state.root.firstChild.params['businessId']);
        } else {
          this.businessId = null;
        }

        if (val.state.root.firstChild.params['lineId']) {
          this.lineId = Number(val.state.root.firstChild.params['lineId']);
        } else {
          this.lineId = null;
        }

        this.storageService.currentSiteId.next(this.siteId);
        this.storageService.currentBusinessId.next(this.businessId);
        this.storageService.currentLineId.next(this.lineId);
      }
    });
  }

  GetCurrentModule() {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
        window.scrollTo(0, 0); // Scrolling to the top of the page after every route change

        this.subscriptions.add(
          this.storageService.company.subscribe((company: CompanyView) => {
            if (company) {
              this.modules = this.dataService.GetPages(company.id);

              if (this.modules) {
                for (let module of this.modules) {
                  if (this.currentRoute.includes(module.alias)) {
                    this.module = module;
                    break;
                  } else {
                    this.module = this.modules[0];
                  }
                }

                this.titleService.setTitle("ProfitFinder | " + this.module.name); // Setting page title
              }
            }
          })
        );
      }
    });
  }

  CheckBrowser() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    if (this.deviceInfo) {
      if (this.deviceInfo.browser !== 'Chrome') {
        this.showBrowserWarning = true;
      } else {
        console.log('%c Stop!', 'font-weight: bold; font-size: 36px; color: red;');
        console.log('%c This is a browser feature is intended for developers only. Please, do not paste anything here.', 'font-size: 22px;');
      }
    }
  }

  InjectGoogleAnalytics() {
    try {
      const script = document.createElement('script');
      script.innerHTML = `
          (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
          (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
          m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
          })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
  
          ga('create', '` + environment.googleAnalyticsId + `', 'auto');
        `;
      document.head.appendChild(script);
    } catch (error) {
      console.error('Error appending google analytics');
      console.error(error);
    }
  }

  ThrowError() {
    let error = new Error('Test error');
    throw error;
  }

  InitiateChat() {
    this.intercom.boot({
      app_id: 't3d9w1ub',
      name: this.account.firstName + ' ' + this.account.lastName,
      email: this.account.email,
      created_at: new Date(),
      widget: {
        "activator": "#intercom"
      },
      hide_default_launcher: true
    });
  }

  DisconnectRealTime() {
    this.realTimeService.Disconnect();
  }

  @HostListener('window:scroll') onScroll() {
    this.windowScrollY = window.scrollY;
    // let scrollPosition = window.innerHeight + window.scrollY;
    // let windowHeight = document.body.offsetHeight;

    // if (windowHeight - scrollPosition < 57) {
    //   this.isScrollBottom = true;
    // } else {
    //   this.isScrollBottom = false;
    // }
  }

  @HostListener('window:resize', ['$event']) onResize(event) {
    if (event.target.innerWidth < 768) {
      this.storageService.rollOut.next(false);
    }
  }
}