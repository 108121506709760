// Angular Imports
import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';

// Services
import { UtilityService } from 'src/platform-app/services/static/utility.service';

// Models
// import { ActionPlanProject } from 'src/platform-app/components/pages/action-plan/action-plan.component';

// Third Party Imports
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActionPlanProject } from 'src/platform-app/models/action-plan.model';

@Component({
  selector: 'action-plan-project-modal',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})

export class ActionPlanProjectModalComponent implements OnInit, OnDestroy {
  public eventFunction: any;
  public project: ActionPlanProject = new ActionPlanProject();
  @ViewChild('ModalForm', { static: true }) public modalForm: NgForm;
  public subscriptions = new Subscription();

  constructor(
    private dialogRef: MatDialogRef<ActionPlanProjectModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private utilityService: UtilityService
  ) { }

  ngOnInit() {
    if (this.data.project) {
      this.project = this.data.project;
    }

    this.eventFunction = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        this.Save();
      }
    }

    document.addEventListener('keyup', this.eventFunction, false); // Submitting popup on press of Enter key
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    document.removeEventListener('keyup', this.eventFunction, false); // Removing event listener
  }

  Cancel() {
    this.dialogRef.close();
    document.removeEventListener('keyup', this.eventFunction, false); // Removing event listener
  }

  Save() {
    if (this.modalForm.valid) {
      this.dialogRef.close(this.project);
      document.removeEventListener('keyup', this.eventFunction, false); // Removing event listener
    }
  }

  TrackByFunction(index, item) {
    return item.id;
  }
}
