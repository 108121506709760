// Angular Imports
import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';

// Third Party Imports
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'naming-dialog-modal',
  templateUrl: './naming-dialog.component.html',
  styleUrls: ['./naming-dialog.component.scss']
})

export class NamingDialogModalComponent implements OnInit, OnDestroy {
  public objectName: string; // Data object sent to modal
  public eventFunction: any;
  @ViewChild('ModalForm', { static: true }) public modalForm: NgForm;

  constructor(
    private dialogRef: MatDialogRef<NamingDialogModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    if (this.data.currentName) {
      this.objectName = this.data.currentName;
    }

    this.eventFunction = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        this.Save();
      }
    }

    document.addEventListener('keyup', this.eventFunction, false); // Submitting popup on press of Enter key
  }

  ngOnDestroy() {
    document.removeEventListener('keyup', this.eventFunction, false); // Removing event listener
  }

  Cancel() {
    this.dialogRef.close();
    document.removeEventListener('keyup', this.eventFunction, false); // Removing event listener
  }

  Save() {
    if (this.modalForm.valid) {
      this.dialogRef.close(this.objectName);
      document.removeEventListener('keyup', this.eventFunction, false); // Removing event listener
    }
  }
}
