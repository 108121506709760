// Angular Imports
import { Injectable } from '@angular/core';

// Models

// Third Party Imports
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

@Injectable()

export class UtilityService {
    constructor() {

    }

    MathPower(value, power: number) {
        return Math.pow(value, power);
    }

    MathRound(value: number) {
        return Math.round(value);
    }

    NumberWithComas(value: number) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    isEmptyObject(obj) {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    }

    ResizeWindow(): void {
        window.dispatchEvent(new Event('resize'));
    }

    extractData(response: any) {
        let body = response;
        return body || {};
    }

    GetCarbonCopy(object: any) {
        return JSON.parse(JSON.stringify(object));
    }

    MoveDragDrop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
        }
    }

    ReverseDragDrop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.currentIndex, event.previousIndex);
        } else {
            transferArrayItem(event.container.data, event.previousContainer.data, event.currentIndex, event.previousIndex);
        }
    }

    GetBrightness(color: string) {
        if (color) {
            let red = this.HexToRgb(color)[0];
            let green = this.HexToRgb(color)[1];
            let blue = this.HexToRgb(color)[2];

            let brightness = ((red * 299) + (green * 587) + (blue * 114)) / 1000;

            return brightness;
        }
    }

    HexToRgb(color: string) {
        if (color) {
            let hex = parseInt(color.substring(1), 16);
            let red = (hex & 0xff0000) >> 16;
            let green = (hex & 0x00ff00) >> 8;
            let blue = hex & 0x0000ff;
            return [red, green, blue];
        }
    }

    GetAverage(array: number[]) {
        let total = array.reduce((acc, c) => acc + c, 0);
        return total / array.length;
    }

    GetStandartDeviation(array: number[]) {
        const length = array.length;
        const average = array.reduce((a, b) => a + b) / length;
        const standardDeviation = Math.sqrt(array.map(x => Math.pow(x - average, 2)).reduce((a, b) => a + b) / length);
        return standardDeviation;
    }
}