// Angular Imports
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from "rxjs/operators";
import { environment } from '../../../environments/environment';

// Services
import { UtilityService } from '../static/utility.service';

@Injectable()
export class BlobService {
    public baseUrl: string = environment.endpoint;

    constructor(
        private http: HttpClient,
        private utilityService: UtilityService
    ) { }

    UploadFile(file): Observable<any> {
        let headers = new HttpHeaders();
        headers.set("Content-Type", "application/x-www-form-urlencoded");
        const url: string = this.baseUrl + '/api/Blob/Upload';
        return this.http.post(url, file, {
            headers: headers
        }).pipe(
            map(this.utilityService.extractData)
        );
    }
}