// Angular Imports
import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { Subscription } from 'rxjs';

// Services
import { UtilityService } from 'src/platform-app/services/static/utility.service';

// Models
// import { ActionPlanTask } from 'src/platform-app/components/pages/action-plan/action-plan.component';

// Third Party Imports
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActionPlanTask } from 'src/platform-app/models/action-plan.model';

@Component({
  selector: 'action-plan-task-modal',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss']
})

export class ActionPlanTaskModalComponent implements OnInit, OnDestroy {
  public eventFunction: any;
  public task: ActionPlanTask = new ActionPlanTask();
  public lines = [];
  public selectedMetrics = []; // List of selected metrics
  public allMetricSelections = [
    {
      alias: 'availability',
      name: 'Availability'
    },
    {
      alias: 'quality',
      name: 'Quality'
    },
    {
      alias: 'performance',
      name: 'Performance'
    },
    {
      alias: 'utilization',
      name: 'Utilization'
    },
    {
      alias: 'capacity',
      name: 'Capacity'
    },
    {
      alias: 'fixedCost',
      name: 'Fixed Cost'
    },
    {
      alias: 'variableCost',
      name: 'Variable Cost'
    },
    {
      alias: 'rawMaterialCost',
      name: 'Raw Material Cost'
    }
  ];
  public metricSelections = [];
  @ViewChild('selectedMetric', { static: false }) selectedMetric: NgModel;
  @ViewChild('ModalForm', { static: true }) public modalForm: NgForm;
  public subscriptions = new Subscription();

  constructor(
    private dialogRef: MatDialogRef<ActionPlanTaskModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private utilityService: UtilityService
  ) { }

  ngOnInit() {
    if (this.data.task) {
      this.task = this.data.task;
      this.task.availability *= 100; // Converting decimal to %
      this.task.quality *= 100; // Converting decimal to %
      this.task.utilization *= 100;
      this.task.progress *= 100;

      if (!this.task.businessId) {
        this.task.businessId = -1;
      } else {
        this.GetBusinessData();
      }

      if (!this.task.lineId) {
        this.task.lineId = -1;
      }

      this.UpdateSelections(); // Updating dropdown selectable options

      if (this.task.businessId || this.task.lineId) {
        this.InitilizeCard(); // Updating dropdown selected options
      }
    }

    this.eventFunction = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        this.Save();
      }
    }

    document.addEventListener('keyup', this.eventFunction, false); // Submitting popup on press of Enter key
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    document.removeEventListener('keyup', this.eventFunction, false); // Removing event listener
  }

  GetBusinessData() {
    if (this.task.businessId > 0) {
      this.lines = this.data.lines.filter(line => line.businessId === this.task.businessId);
    } else {
      this.task.lineId = -1;
      this.lines = [];
    }
  }

  InitilizeCard() { // Updating dropdown selections
    this.selectedMetrics = [];

    for (let i = 0; i < this.metricSelections.length; i++) {
      if (this.metricSelections[i].alias === "availability") {
        if (this.task.availability !== 0) {
          this.selectedMetrics.push(this.metricSelections[i]);
        }
      } else if (this.metricSelections[i].alias === "quality") {
        if (this.task.quality !== 0) {
          this.selectedMetrics.push(this.metricSelections[i]);
        }
      } else if (this.metricSelections[i].alias === "performance") {
        if (this.task.performance !== 0) {
          this.selectedMetrics.push(this.metricSelections[i]);
        }
      } else if (this.metricSelections[i].alias === "utilization") {
        if (this.task.utilization !== 0) {
          this.selectedMetrics.push(this.metricSelections[i]);
        }
      } else if (this.metricSelections[i].alias === "capacity") {
        if (this.task.capacity !== 0) {
          this.selectedMetrics.push(this.metricSelections[i]);
        }
      } else if (this.metricSelections[i].alias === "fixedCost") {
        if (this.task.fixedCost !== 0) {
          this.selectedMetrics.push(this.metricSelections[i]);
        }
      } else if (this.metricSelections[i].alias === "variableCost") {
        if (this.task.variableCost !== 0) {
          this.selectedMetrics.push(this.metricSelections[i]);
        }
      } else if (this.metricSelections[i].alias === "rawMaterialCost") {
        if (this.task.rawMaterialCost !== 0) {
          this.selectedMetrics.push(this.metricSelections[i]);
        }
      }
    }

    this.RefreshSelections();
  }

  UpdateSelections() { // Updating dropdown selection options
    this.metricSelections = [];
    this.selectedMetrics = [];
    if ((this.task.businessId && this.task.businessId === -1)) { // Global
      this.metricSelections.push(this.allMetricSelections[5]);
      this.metricSelections.push(this.allMetricSelections[6]);
    } else if ((this.task.businessId && this.task.businessId > 0) && this.task.lineId === -1) { // Business
      this.metricSelections.push(this.allMetricSelections[5]);
      this.metricSelections.push(this.allMetricSelections[6]);
      this.metricSelections.push(this.allMetricSelections[7]);
    } else if (this.task.lineId && this.task.lineId > 0) {
      this.metricSelections.push(this.allMetricSelections[0]);
      this.metricSelections.push(this.allMetricSelections[1]);
      this.metricSelections.push(this.allMetricSelections[2]);
      this.metricSelections.push(this.allMetricSelections[3]);
      this.metricSelections.push(this.allMetricSelections[4]);
      this.metricSelections.push(this.allMetricSelections[5]);
      this.metricSelections.push(this.allMetricSelections[6]);
      this.metricSelections.push(this.allMetricSelections[7]);
    }
  }

  CheckSelected(alias: string) {
    for (let selectedMetric of this.selectedMetrics) {
      if (selectedMetric.alias === alias) {
        return true;
      }
    }
    return false;
  }

  UnselectMetric(alias: string) {
    for (let i = 0; i < this.selectedMetrics.length; i++) {
      if (this.selectedMetrics[i].alias === alias) {
        this.selectedMetrics.splice(i, 1);
      }
    }
  }

  RefreshSelections() {
    let tempArray = [];

    for (let selectedMetric of this.selectedMetrics) {
      tempArray.push(selectedMetric);
    }

    this.selectedMetrics = tempArray;

    setTimeout(() => {
      this.selectedMetric.update.emit(this.selectedMetrics);
    }, 100);
  }

  Cancel() {
    this.dialogRef.close();
    document.removeEventListener('keyup', this.eventFunction, false); // Removing event listener
  }

  Save() {
    if (this.modalForm.valid) {
      this.task.availability /= 100;
      this.task.quality /= 100;
      this.task.utilization /= 100;
      this.task.progress /= 100;

      if (this.task.businessId === -1) {
        this.task.businessId = null;
      }

      if (this.task.lineId === -1) {
        this.task.lineId = null;
      }

      this.dialogRef.close(this.task);
      document.removeEventListener('keyup', this.eventFunction, false); // Removing event listener
    }
  }

  TrackByFunction(index, item) {
    return item.id;
  }
}
