// Angular Imports
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from "rxjs/operators";
import { environment } from '../../../environments/environment';

// Services
import { UtilityService } from '../static/utility.service';

// Models
import { BusinessView, Business } from 'src/platform-app/models/business.model';

@Injectable()
export class BusinessService {
    public baseUrl: string = environment.endpoint;

    constructor(
        private http: HttpClient,
        private utilityService: UtilityService
    ) { }

    CreateBusiness(business: Business): Observable<BusinessView> {
        const url: string = this.baseUrl + '/api/Business/Create';
        return this.http.post<Business>(url, business).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetAllBusinesses(): Observable<BusinessView[]> {
        const url: string = this.baseUrl + '/api/Business/GetAll';
        return this.http.get<BusinessView[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetBusinessesBySiteId(siteId: number): Observable<BusinessView[]> {
        const url: string = this.baseUrl + '/api/Business/Site/' + siteId + '/GetAll';
        return this.http.get<BusinessView[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetBusinessById(businessId: number): Observable<BusinessView> {
        const url: string = this.baseUrl + '/api/Business/' + businessId + '/Get';
        return this.http.get<BusinessView>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    EditBusiness(businessId: number, business: Business): Observable<BusinessView> {
        const url: string = this.baseUrl + '/api/Business/' + businessId + '/Edit';
        return this.http.put<BusinessView>(url, business).pipe(
            map(this.utilityService.extractData)
        );
    }

    DeleteBusiness(businessId: number): Observable<any> {
        const url: string = this.baseUrl + '/api/Business/' + businessId + '/Delete';
        return this.http.delete(url).pipe(
            map(this.utilityService.extractData)
        );
    }
}