// Angular Imports
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from "rxjs/operators";
import { environment } from '../../../environments/environment';

// Services
import { UtilityService } from '../static/utility.service';

// Models
import { OpportunityView } from 'src/platform-app/models/dashboard.model';

@Injectable()
export class DashboardService {
    public baseUrl: string = environment.endpoint;

    constructor(
        private http: HttpClient,
        private utilityService: UtilityService
    ) { }

    GetGlobalFocusFinder(): Observable<OpportunityView[]> {
        const url: string = this.baseUrl + '/api/Dashboard/FocusFinder/Get';
        return this.http.get<OpportunityView[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetFocusFinderBySite(siteId: number): Observable<OpportunityView[]> {
        const url: string = this.baseUrl + '/api/Dashboard/FocusFinder/Site/' + siteId + '/Get';
        return this.http.get<OpportunityView[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetFocusFinderByBusiness(businessId: number): Observable<OpportunityView[]> {
        const url: string = this.baseUrl + '/api/Dashboard/FocusFinder/Business/' + businessId + '/Get';
        return this.http.get<OpportunityView[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetFocusFinderBySiteAndBusiness(siteId: number, businessId: number): Observable<OpportunityView[]> {
        const url: string = this.baseUrl + '/api/Dashboard/FocusFinder/Site/' + siteId + '/Business/' + businessId + '/Get';
        return this.http.get<OpportunityView[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetFocusFinderByLine(lineId: number): Observable<OpportunityView[]> {
        const url: string = this.baseUrl + '/api/Dashboard/FocusFinder/Line/' + lineId + '/Get';
        return this.http.get<OpportunityView[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    // Suggestions

    GetGlobalRiskForecast(): Observable<any> {
        const url: string = this.baseUrl + '/api/Dashboard/Forecast/Get';
        return this.http.get<any>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetRiskForecastBySite(siteId: number): Observable<any> {
        const url: string = this.baseUrl + `/api/Dashboard/Forecast/Site/${siteId}/Get`;
        return this.http.get<any>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetRiskForecastByBusiness(businessId: number): Observable<any> {
        const url: string = this.baseUrl + `/api/Dashboard/Forecast/Business/${businessId}/Get`;
        return this.http.get<any>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetRiskForecastBySiteAndBusiness(siteId: number, businessId: number): Observable<any> {
        const url: string = this.baseUrl + `/api/Dashboard/Forecast/Site/${siteId}/Business/${businessId}/Get`;
        return this.http.get<any>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetRiskForecastByLine(lineId: number): Observable<any> {
        const url: string = this.baseUrl + `/api/Dashboard/Forecast/Line/${lineId}/Get`;
        return this.http.get<any>(url).pipe(
            map(this.utilityService.extractData)
        );
    }
}