// Angular Imports
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from "rxjs";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

// Services
import { AuthenticationService } from 'src/platform-app/services/apis/authentication.service';
import { ErrorObject, ErrorService } from '../apis/error.service';

@Injectable()
export class ErrorInterceptorService implements HttpInterceptor {
    public subscriptions = new Subscription();

    constructor(
        private authService: AuthenticationService,
        private errorService: ErrorService,
        private router: Router
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    this.authService.Logout();
                    this.router.navigate(['/login']);
                }

                if (!request.url.includes('ErrorLog')) {
                    let errorObject = new ErrorObject;
                    errorObject.url = window.location.href;
                    errorObject.errorType = 'server-side';
                    errorObject.errorMessage = error.message || 'Error does not have content';
                    errorObject.errorCode = error.status;
                    errorObject.requestBody = JSON.stringify(request.body);
                    errorObject.requestMethod = request.method;

                    this.subscriptions.add(
                        this.errorService.LogError(errorObject).subscribe((response: any) => { })
                    );
                }

                console.log('Server-side error occured: ', error.message);
                return throwError(error);
            })
        )
    }
}