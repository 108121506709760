// Angular Imports
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { environment } from '../../../environments/environment';

// Services
import { UtilityService } from '../static/utility.service';

// Models
import { FamilyScheduleView, LineScheduleView, SchedulerInstanceView, SchedulerInstance, SchedulerTransfer, SchedulerTransferView, BillOfMaterials } from 'src/platform-app/models/scheduler.model';
import { Batch, ProductWheel } from 'src/platform-app/models/batch.model';

@Injectable()
export class SchedulerService {
    public baseUrl: string = environment.endpoint;

    constructor(
        private http: HttpClient,
        private utilityService: UtilityService
    ) { }

    CreateSchedulerInstanceByBusinessId(businessId: number, instance: SchedulerInstance): Observable<any> {
        const url: string = `${this.baseUrl}/api/Business/${businessId}/Scheduler/Instance`;
        return this.http.post(url, instance).pipe(
            map(this.utilityService.extractData)
        );
    }

    ImportDemand(demandArray: any[]): Observable<any> {
        const url: string = `${this.baseUrl}/api/Demand/Import`;
        return this.http.post(url, demandArray).pipe(
            map(this.utilityService.extractData)
        );
    }

    ImportSchedule(instanceId: number, scheduleArray: any[]): Observable<any> {
        const url: string = `${this.baseUrl}/api/Scheduler/Instance/${instanceId}/Import`;
        return this.http.post(url, scheduleArray).pipe(
            map(this.utilityService.extractData)
        );
    }

    EditSchedulerInstance(instanceId: number, instance: SchedulerInstance): Observable<any> {
        const url: string = `${this.baseUrl}/api/Scheduler/Instance/${instanceId}/Edit`;
        return this.http.put(url, instance).pipe(
            map(this.utilityService.extractData)
        );
    }

    CopySchedulerInstance(instanceId: number, name: string): Observable<any> {
        const url: string = `${this.baseUrl}/api/Scheduler/Instance/${instanceId}/Copy/Name/${name}`;
        return this.http.post(url, null).pipe(
            map(this.utilityService.extractData)
        );
    }

    DeleteSchedulerInstance(instanceId: number): Observable<any> {
        const url: string = `${this.baseUrl}/api/Scheduler/Instance/${instanceId}`;
        return this.http.delete(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    SetSchedulerDefaultInstance(instanceId: number): Observable<any> {
        const url: string = `${this.baseUrl}/api/Scheduler/Instance/${instanceId}/SetDefault`;
        return this.http.put(url, {}).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetSchedulerInstancesByBusinessId(businessId: number): Observable<SchedulerInstanceView[]> {
        const url: string = `${this.baseUrl}/api/Business/${businessId}/Scheduler/Instances`;
        return this.http.get<SchedulerInstanceView[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetSchedulerInstanceScheduleByIdAndFamilyId(instanceId: number, familyId: number, startDate?: string, endDate?: string): Observable<FamilyScheduleView> {
        let url: string = `${this.baseUrl}/api/Family/${familyId}/Scheduler/Instance/${instanceId}/Schedule`;

        if (startDate && endDate) {
            url += `?startDate=${startDate}&endDate=${endDate}`;
        }

        return this.http.get<SchedulerInstanceView>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    // Inventory 

    GetSchedulerInstanceInventoryByInstanceAndFamilyId(instanceId: number, familyId: number): Observable<any> {
        const url: string = `${this.baseUrl}/api/Family/${familyId}/Scheduler/Instance/${instanceId}/Inventory`;
        return this.http.get<SchedulerInstanceView>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    ChangeBatchLine(familyId: number, batchId: number, lineId: number, previousBatchId: number): Observable<LineScheduleView[]> {
        const url: string = this.baseUrl + '/api/Scheduler/Family/' + familyId + '/ChangeBatch/' + batchId + '/ToLine/' + lineId + '/After/' + previousBatchId;
        return this.http.put<LineScheduleView>(url, {}).pipe(
            map(this.utilityService.extractData)
        );
    }

    CreateBatch(familyId: number, previousProductId: number, batch: Batch, instanceId: number): Observable<any> {
        const url: string = this.baseUrl + '/api/Scheduler/Family/' + familyId + '/AddBatch/After/' + previousProductId + '/Instance/' + instanceId;
        return this.http.post(url, batch).pipe(
            map(this.utilityService.extractData)
        );
    }

    CreateProductWheel(familyId: number, previousProductId: number, batch: Batch, instanceId: number, lineId: number): Observable<any> {
        const url: string = this.baseUrl + '/api/Scheduler/Family/' + familyId + '/AddProductWheel/After/' + previousProductId + '/Instance/' + instanceId + '/Line/' + lineId;
        return this.http.post(url, batch).pipe(
            map(this.utilityService.extractData)
        );
    }

    // {{baseUrl}}/api/Scheduler/Family/34/AddProductWheel/After/0/Instance/89/Line/64

    EditBatch(familyId: number, batchId: number, batch: Batch): Observable<any> {
        const url: string = this.baseUrl + '/api/Scheduler/Family/' + familyId + '/EditBatch/' + batchId;
        return this.http.put(url, batch).pipe(
            map(this.utilityService.extractData)
        );
    }

    DeleteBatch(familyId: number, batchId: number): Observable<any> {
        const url: string = this.baseUrl + '/api/Scheduler/Family/' + familyId + '/DeleteById/' + batchId;
        return this.http.delete(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    EditMultipleBatches(familyId: number, customBatches: any): Observable<any> {
        const url: string = this.baseUrl + '/api/Scheduler/Family/' + familyId + '/MultipleBatches/Edit/';
        return this.http.put(url, customBatches).pipe(
            map(this.utilityService.extractData)
        );
    }

    DeleteMultipleBatches(familyId: number, ids: number[]): Observable<any> {
        let url: string = this.baseUrl + '/api/Scheduler/Family/' + familyId + '/MultipleBatches/Delete';

        for (let i = 0; i < ids.length; i++) {
            if (i === 0) {
                url += `?id=${ids[i]}`
            } else {
                url += `&id=${ids[i]}`
            }
        }

        return this.http.delete(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetSchedulerMetricsByInstanceId(instanceId: number): Observable<any> {
        const url: string = this.baseUrl + '/api/Scheduler/Instance/' + instanceId + '/Metrics';
        return this.http.get<any>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetSchedulerMetricsByBusinessId(businessId: number): Observable<any> {
        const url: string = this.baseUrl + '/api/Scheduler/Metrics/Business/' + businessId;
        return this.http.get<any>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    // Demand

    GetSchedulerDemandByBusinessAndFamilyId(familyId: number, startDate?: string, endDate?: string): Observable<any> {
        let url: string = this.baseUrl + '/api/Scheduler/Family/' + familyId + '/Demand/GetAll';

        if (startDate) {
            url += '?startDate=' + startDate;
        }

        if (endDate) {
            url += '&endDate=' + endDate;
        }

        return this.http.get<any>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    EditSchedulerDemand(familyId: number, demand: any): Observable<any> {
        const url: string = this.baseUrl + '/api/Scheduler/Family/' + familyId + '/Demand/Edit';
        return this.http.put(url, demand).pipe(
            map(this.utilityService.extractData)
        );
    }

    // High Stock

    GetSchedulerHighStockByFamilyId(familyId: number, startDate?: string, endDate?: string): Observable<any> {
        let url: string = this.baseUrl + '/api/Scheduler/Family/' + familyId + '/HighStock/GetAll';

        if (startDate) {
            url += '?startDate=' + startDate;
        }

        if (endDate) {
            url += '&endDate=' + endDate;
        }

        return this.http.get<any>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    EditSchedulerHighStock(familyId: number, highStock: any): Observable<any> {
        const url: string = this.baseUrl + '/api/Scheduler/Family/' + familyId + '/HighStock/Edit';
        return this.http.put(url, highStock).pipe(
            map(this.utilityService.extractData)
        );
    }

    // Safety Level

    GetSchedulerSafetyLevelByFamilyId(familyId: number, startDate?: string, endDate?: string): Observable<any> {
        let url: string = this.baseUrl + '/api/Scheduler/Family/' + familyId + '/SafetyLevel/GetAll';


        if (startDate) {
            url += '?startDate=' + startDate;
        }

        if (endDate) {
            url += '&endDate=' + endDate;
        }

        return this.http.get<any>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    EditSchedulerSafetyLevel(familyId: number, safetyLevel: any): Observable<any> {
        const url: string = this.baseUrl + '/api/Scheduler/Family/' + familyId + '/SafetyLevel/Edit';
        return this.http.put(url, safetyLevel).pipe(
            map(this.utilityService.extractData)
        );
    }

    // Transfer
    GetSchedulerTransfersByInstanceId(instanceId: number): Observable<any> {
        const url: string = this.baseUrl + '/api/Scheduler/Instance/' + instanceId + '/Transfer/GetAll';
        return this.http.get<any>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    CreateTransfer(instanceId: number, transfer: SchedulerTransfer): Observable<SchedulerTransferView> {
        const url: string = this.baseUrl + '/api/Scheduler/Instance/' + instanceId + '/Transfer/Create';
        return this.http.post(url, transfer).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetSchedulerTransfersDetails(information: any): Observable<any> {
        const url: string = this.baseUrl + '/api/Scheduler/Transfer/Detail/Get';
        return this.http.post<any>(url, information).pipe(
            map(this.utilityService.extractData)
        );
    }

    // History

    GetSchedulerHistory(businessId: number, instanceId: number): Observable<any> {
        const url: string = this.baseUrl + '/api/Business/' + businessId + '/Scheduler/Instance/' + instanceId + '/History/GetAll';
        return this.http.get<any>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    // Bill of Materials

    GetBillOfMaterials(instanceId: number): Observable<BillOfMaterials[]> {
        const url: string = this.baseUrl + '/api/Scheduler/Instance/' + instanceId + '/billOfMaterials/GetAll';
        return this.http.get<BillOfMaterials[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    // Contribution Margin
    GetContributionMargin(businessId: number): Observable<any[]> {
        const url: string = this.baseUrl + '/api/Business/' + businessId + '/Product/ContributionMargin/GetAll';
        return this.http.get<any[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    // Product Wheel
    GetProductWheel(familyId: number, lineId: number): Observable<ProductWheel> {
        const url: string = this.baseUrl + '/api/Scheduler/Family/' + familyId + '/Line/' + lineId + '/ProductWheel/GetAll';
        return this.http.get<ProductWheel>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    EditProductWheel(familyId: number, lineId: number, productWheel: any[]): Observable<any[]> {
        const url: string = this.baseUrl + '/api/Scheduler/Family/' + familyId + '/Line/' + lineId + '/ProductWheel/Edit';
        return this.http.put<any[]>(url, productWheel).pipe(
            map(this.utilityService.extractData)
        );
    }
}