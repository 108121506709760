// Angular Imports
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Models
import { AccountSmallView } from '../../models/account.model';
import { CompanyView } from '../../models/company.model';
import { SiteView } from '../../models/site.model';
import { BusinessView } from '../../models/business.model';
import { LineView } from 'src/platform-app/models/line.model';
import { VersionView } from 'src/platform-app/models/version.model';

@Injectable()
export class StorageService {
    // Behaviour Subject Declarations.
    public account = new BehaviorSubject<AccountSmallView>(null); // Company
    public company = new BehaviorSubject<CompanyView>(null); // Company
    public dashboardFilterBy = new BehaviorSubject<string>(null);
    public companySites = new BehaviorSubject<SiteView[]>(null); // Company Businesses
    public currentSite = new BehaviorSubject<SiteView>(null); // Current Site
    public currentSiteId = new BehaviorSubject<number>(null); // Site Id
    public companyBusinesses = new BehaviorSubject<BusinessView[]>(null); // Company Businesses
    public currentBusiness = new BehaviorSubject<BusinessView>(null); // Current Site
    public currentBusinessId = new BehaviorSubject<number>(null); // Site Id
    public companyLines = new BehaviorSubject<LineView[]>(null); // Company Lines
    public currentLine = new BehaviorSubject<LineView>(null); // Current Line
    public currentLineId = new BehaviorSubject<number>(null); // Line Id
    public notificationsStatus = new BehaviorSubject<boolean>(null); // Notification Read Flag
    public accountToken = new BehaviorSubject<string>(null); // AccountToken
    public currentRoute = new BehaviorSubject<string>(null);
    public versions = new BehaviorSubject<VersionView[]>(null);
    public rollOut = new BehaviorSubject<boolean>(false);

    ResetCompanyData() {
        this.companySites.next(null);
        this.currentSite.next(null);
        this.currentSiteId.next(null);
        this.companyBusinesses.next(null);
        this.currentBusiness.next(null);
        this.currentBusinessId.next(null);
        this.companyLines.next(null);
        this.currentLine.next(null);
        this.currentLineId.next(null);
    }
}
