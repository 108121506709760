// Angular Imports
import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';

// Services
import { FamilyService } from 'src/platform-app/services/apis/family.service';
import { BusinessService } from 'src/platform-app/services/apis/business.service';
import { RawMaterialService } from 'src/platform-app/services/apis/raw-material.service';
import { ProductService } from 'src/platform-app/services/apis/product.service';
import { UtilityService } from 'src/platform-app/services/static/utility.service';

// Components

// Models
import { BusinessView } from 'src/platform-app/models/business.model';
import { Product, ProductView } from 'src/platform-app/models/product.model';
import { FamilyView } from 'src/platform-app/models/family.model';
import { Ingredient } from 'src/platform-app/models/ingredient.model';
import { RawMaterialView } from 'src/platform-app/models/raw-material.model';

// Third Party Imports
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'product-modal',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})

export class ProductModalComponent implements OnInit, OnDestroy {
  public eventFunction: any;
  public product: Product;
  public productId: number;
  public products: ProductView[] = [];
  public businesses: BusinessView[] = [];
  public families: FamilyView[] = [];
  public rawMaterials: RawMaterialView[] = [];
  public selectedIngredientType;
  @ViewChild('ModalForm', { static: true }) public modalForm: NgForm;
  public subscriptions = new Subscription();

  constructor(
    private dialogRef: MatDialogRef<ProductModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private businessService: BusinessService,
    private familyService: FamilyService,
    private rawMaterialService: RawMaterialService,
    private productService: ProductService,
    private utilityService: UtilityService
  ) { }

  ngOnInit() {
    if (this.data.product && this.data.productId) {
      this.product = this.data.product;
      this.productId = this.data.productId;
      this.GetBusinessFamilies();
    } else {
      this.product = new Product();
      this.product.ingredients = [];
    }

    this.businesses = this.data.businesses;

    this.eventFunction = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        this.Save();
      }
    }

    document.addEventListener('keyup', this.eventFunction, false); // Submitting popup on press of Enter key
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    document.removeEventListener('keyup', this.eventFunction, false); // Removing event listener
  }

  GetBusinessFamilies() {
    this.subscriptions.add(
      this.familyService.GetFamiliesByBusinessId(this.product.businessId).subscribe((families: FamilyView[]) => {
        this.families = families
      }, (error) => {
        console.log(error);
      })
    );

    this.subscriptions.add(
      this.rawMaterialService.GetAllRawMaterialsByBusinessId(this.product.businessId).subscribe((rawMaterials: RawMaterialView[]) => {
        this.rawMaterials = rawMaterials;
      }, (error) => {
        console.log(error);
      })
    );

    this.subscriptions.add(
      this.productService.GetProductsByBusinessId(this.product.businessId).subscribe((products: ProductView[]) => {
        this.products = products;
      }, (error) => {
        console.log(error);
      })
    );
  }

  AddIngredient() {
    let ingredient = new Ingredient();
    ingredient.type = this.selectedIngredientType;
    ingredient.materialId = null;
    ingredient.quantity = null;
    this.product.ingredients.push(ingredient);
    this.selectedIngredientType = null;
  }

  RemoveIngredient(index) {
    this.product.ingredients.splice(index, 1);
  }

  Cancel() {
    this.dialogRef.close();
    document.removeEventListener('keyup', this.eventFunction, false); // Removing event listener
  }

  Save() {
    if (this.modalForm.valid) {
      this.dialogRef.close(this.product);
      document.removeEventListener('keyup', this.eventFunction, false); // Removing event listener
    }
  }

  TrackByFunction(index, item) {
    return item.id;
  }
}
