// Angular Imports
import { Component, Input } from '@angular/core';

// Models 
import { VersionView } from 'src/platform-app/models/version.model';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent {
  @Input() public latestVersion: VersionView;
  public currentYear = new Date().getFullYear();
}
