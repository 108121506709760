// Angular Imports
import { Component } from '@angular/core';

@Component({
  selector: 'global-spinner',
  templateUrl: './global-spinner.component.html',
  styleUrls: ['./global-spinner.component.scss']
})

export class GlobalSpinnerComponent { }
