// Angular Imports
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from "rxjs/operators";
import { environment } from '../../../environments/environment';

// Services
import { UtilityService } from '../static/utility.service';

// Models

@Injectable()
export class NotificationService {
    public baseUrl: string = environment.endpoint;

    constructor(
        private http: HttpClient,
        private utilityService: UtilityService
    ) { }

    GetNotifications(): Observable<any[]> {
        const url: string = this.baseUrl + '/api/Notification/GetAll';
        return this.http.get<any[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    ReadNotifications(): Observable<any> {
        const url: string = this.baseUrl + '/api/Notification/Read';
        return this.http.put(url, null).pipe(
            map(this.utilityService.extractData)
        );
    }

    UnreadNotifications(): Observable<any> {
        const url: string = this.baseUrl + '/api/Notification/Unread';
        return this.http.put(url, null).pipe(
            map(this.utilityService.extractData)
        );
    }
}