// Angular Imports
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from "rxjs/operators";
import { environment } from '../../../environments/environment';

// Services
import { UtilityService } from '../static/utility.service';

// Models
import { RawMaterial, RawMaterialView, RawMaterialPriceView, RawMaterialPrice } from 'src/platform-app/models/raw-material.model';

@Injectable()
export class RawMaterialService {
    public baseUrl: string = environment.endpoint;

    constructor(
        private http: HttpClient,
        private utilityService: UtilityService
    ) { }

    GetAllRawMaterials(): Observable<RawMaterialView[]> {
        const url: string = this.baseUrl + '/api/Product/RawMaterial/GetAll';
        return this.http.get<RawMaterialView[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetAllRawMaterialsByBusinessId(businessId: number): Observable<RawMaterialView[]> {
        const url: string = this.baseUrl + '/api/Product/Business/' + businessId + '/RawMaterial/GetAll';
        return this.http.get<RawMaterialView[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    CreateRawMaterial(rawMaterial: RawMaterial): Observable<RawMaterialView> {
        const url: string = this.baseUrl + '/api/Product/RawMaterial/Create';
        return this.http.post(url, rawMaterial).pipe(
            map(this.utilityService.extractData)
        );
    }

    EditRawMaterial(rawMaterialId: number, rawMaterial: RawMaterial): Observable<RawMaterialView> {
        const url: string = this.baseUrl + '/api/Product/RawMaterial/' + rawMaterialId + '/Edit';
        return this.http.put(url, rawMaterial).pipe(
            map(this.utilityService.extractData)
        );
    }

    DeleteRawMaterial(rawMaterialId: number): Observable<any> {
        const url: string = this.baseUrl + '/api/Product/RawMaterial/' + rawMaterialId + '/Delete';
        return this.http.delete(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    // Price

    GetAllRawMaterialsPrices(): Observable<RawMaterialPriceView[]> {
        const url: string = this.baseUrl + '/api/Product/RawMaterial/Price/GetAll';
        return this.http.get<RawMaterialPriceView[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    CreateRawMaterialPrice(price: RawMaterialPrice): Observable<RawMaterialPriceView> {
        const url: string = this.baseUrl + '/api/Product/RawMaterial/Price/Create';
        return this.http.post(url, price).pipe(
            map(this.utilityService.extractData)
        );
    }

    EditRawMaterialPrice(priceId: number, price: RawMaterialPrice): Observable<RawMaterialPriceView> {
        const url: string = this.baseUrl + '/api/Product/RawMaterial/Price/' + priceId + '/Edit';
        return this.http.put(url, price).pipe(
            map(this.utilityService.extractData)
        );
    }

    DeleteRawMaterialPrice(priceId: number): Observable<any> {
        const url: string = this.baseUrl + '/api/Product/RawMaterial/Price/' + priceId + '/Delete';
        return this.http.delete(url).pipe(
            map(this.utilityService.extractData)
        );
    }
}