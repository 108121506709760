// Angular Imports
import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';

// Services
import { SchedulerService } from 'src/platform-app/services/apis/scheduler.service';

// Models
import { SchedulerInstanceView, BillOfMaterials } from 'src/platform-app/models/scheduler.model';

// Third Party Imports
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UtilityService } from 'src/platform-app/services/static/utility.service';

@Component({
  selector: 'bill-of-materials-modal',
  templateUrl: './bill-of-materials.component.html',
  styleUrls: ['./bill-of-materials.component.scss']
})

export class BillOfMaterialsModalComponent implements OnInit, OnDestroy {
  public instance: SchedulerInstanceView;
  public billOfMaterialsArray: BillOfMaterials[];
  public totalQuantity: number;
  public totalCost: number;
  public showSpinner = true;

  @ViewChild('ModalForm', { static: false }) public modalForm: NgForm;
  public subscriptions = new Subscription();

  constructor(
    private schedulerService: SchedulerService,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<BillOfMaterialsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private utilityService: UtilityService
  ) { }

  ngOnInit() {
    this.instance = this.data.instance;

    this.subscriptions.add(
      this.schedulerService.GetBillOfMaterials(this.instance.id).subscribe((billOfMaterialsArray: BillOfMaterials[]) => {
        this.billOfMaterialsArray = billOfMaterialsArray;
        this.totalQuantity = this.billOfMaterialsArray.reduce(
          function (accumulator, item) {
            return accumulator + item.quantity;
          }, 0);

        this.totalCost = this.billOfMaterialsArray.reduce(
          function (accumulator, item) {
            return accumulator + item.cost;
          }, 0);

        this.showSpinner = false;
      }, (error) => {
        console.log(error);
        this.showSpinner = false;
        this.snackBar.open('Something went wrong. Please, try again later.', 'close', { duration: 2500, panelClass: ['mfg-background-danger'] });
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  Cancel() {
    this.dialogRef.close();
  }

  TrackByFunction(index, item) {
    return item.id;
  }
}
