// Model Imports
import { AccountSmallView } from "./account.model";
import { SiteView } from "./site.model";
import { BusinessView } from "./business.model";
import { LineView } from "./line.model";
import { TechnologyPlanPriorityView } from "./technology-plan.model";

// Category
export class ActionPlanCategory {
    public name: string;
    public color: string;
}

export class ActionPlanCategoryView {
    public id: number;
    public name: string;
    public color: string;
}

// Instance
export class ActionPlanInstanceSmall {
    name: string;
    default: boolean;
}

export class ActionPlanInstanceSmallView {
    id: number;
    name: string;
    default: boolean;
}

export class ActionPlanInstance {
    public name: string;
    public default: boolean;
    public projectIds: number[];
}

export class ActionPlanInstanceView {
    public id: number;
    public name: string;
    public default: boolean;
    public projects: ActionPlanProjectView[];
}

// Year

export class ActionPlanYear {
    public startDate: string;
    public endDate: string;
    public projects: ActionPlanProjectView[];
}

export class ActionPlanProject {
    public name: string;
    public categoryId: number;
    public yearId: number;
    public userId: number;
    public tasks: number[];
}

export class ActionPlanProjectView {
    public id: number;
    public name: string;
    public category: ActionPlanCategoryView;
    public yearId?: number;
    public user: AccountSmallView;
    public tasks: ActionPlanTaskView[];
}

// Task 

export class ActionPlanTask {
    public name: string;
    public userId: number;
    public progress: number;
    public description: string;
    public businessId: number;
    public lineId: number;
    public availability?: number = 0;
    public quality?: number = 0;
    public performance?: number = 0;
    public utilization?: number = 0;
    public capacity?: number = 0;
    public fixedCost?: number = 0;
    public variableCost?: number = 0;
    public rawMaterialCost?: number = 0;
}

export class ActionPlanTaskView {
    public id: number;
    public name: string;
    public user: AccountSmallView;
    public progress: number;
    public description: string;
    public business: any;
    public line: any;
    public availability?: number = 0;
    public quality?: number = 0;
    public performance?: number = 0;
    public utilization?: number = 0;
    public capacity?: number = 0;
    public fixedCost?: number = 0;
    public variableCost?: number = 0;
    public rawMaterialCost?: number = 0;
}