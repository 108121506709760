// Angular Imports
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'shortNum' })

export class ShortNum implements PipeTransform {
    transform(value: number): string {

        if (value >= 1000 && value < 1000000) {
            return (Math.round(value / 1000) + ' K');
        } else if (value >= 1000000 && value < 1000000000) {
            return (Math.round(value / 1000000) + ' M');
        } else if (value >= 1000000000 && value < 1000000000000) {
            return (Math.round(value / 1000000000) + ' B');
        } else {
            return (Math.round(value)) + '';
        }
    }
}