// Angular Imports
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

// Services
import { AuthenticationService } from 'src/platform-app/services/apis/authentication.service';
import { NotificationService } from 'src/platform-app/services/apis/notification.service';
import { StorageService } from 'src/platform-app/services/static/storage.service';
import { UtilityService } from '../../../services/static/utility.service';

// Models
import { AccountSmallView } from 'src/platform-app/models/account.model';
import { SiteView } from 'src/platform-app/models/site.model';
import { NotificationView } from 'src/platform-app/models/notifications.model';
import { PageView } from 'src/platform-app/models/utilitymodels';

// Third Party Imports
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'top-navigation',
  templateUrl: './top-navigation.component.html',
  styleUrls: ['./top-navigation.component.scss']
})

export class TopNavigationComponent implements OnInit, OnDestroy {
  public account: AccountSmallView;
  public platformName: string = 'ProfitFinder';
  public site: SiteView = new SiteView();
  @Input() public module: PageView;
  @Input() public currentRoute: string;
  @Input() public windowScrollY: number;
  public showLeftNav = false;
  public showSettings = false;
  public showNotifications = false;
  public notifications: NotificationView[];
  public unreadNumber: number;
  public subscriptions = new Subscription();

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private notificationService: NotificationService,
    private storageService: StorageService,
    private snackBar: MatSnackBar,
    private utilityService: UtilityService
  ) { }

  ngOnInit() {
    this.subscriptions.add(
      this.notificationService.GetNotifications().subscribe((notifications: NotificationView[]) => {
        this.notifications = notifications;
        this.CheckRead();
      }, (error) => {
        console.log(error);
        this.snackBar.open('Something went wrong. Please, try again later.', 'close', { duration: 2500, panelClass: ['mfg-background-danger'] });
      })
    );

    this.subscriptions.add(
      this.storageService.account.subscribe((account: AccountSmallView) => {
        this.account = account;
      })
    );

    this.subscriptions.add(
      this.storageService.currentSite.subscribe((currentSite: SiteView) => {
        this.site = currentSite;
      })
    );

    this.subscriptions.add(
      this.storageService.notificationsStatus.subscribe((status: boolean) => {
        if (status) {
          this.ReadNotifications();
        }
      })
    );

    // Hiding dropdown on url change
    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.showNotifications = false;
        this.showSettings = false;
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  Logout() {
    this.authService.Logout();
  }

  CheckRead() {
    if (this.notifications) {
      let unreadNumber = 0;

      for (let notification of this.notifications) {
        if (!notification.isRead) {
          unreadNumber++;
        }
      }
      this.unreadNumber = unreadNumber;
    }
  }

  ToggleNotifications() {
    this.showNotifications = !this.showNotifications;

    if (!this.showNotifications) {
      for (let notification of this.notifications) {
        if (!notification.isRead) {
          this.ReadNotifications();
        }
      }
    }
  }

  ReadNotifications() {
    this.subscriptions.add(
      this.notificationService.ReadNotifications().subscribe((notifications: NotificationView[]) => {
        for (let notification of this.notifications) {
          notification.isRead = true;
        }

        this.CheckRead();
      }, (error) => {
        console.log(error);
        this.snackBar.open('Something went wrong. Please, try again later.', 'close', { duration: 2500, panelClass: ['mfg-background-danger'] });
      })
    );
  }

  CloseAndReadNotifications() {
    if (this.showNotifications) {
      for (let notification of this.notifications) {
        if (!notification.isRead) {
          this.ReadNotifications()
          break;
        }
      }
    }
  }

  TrackByFunction(index, item) {
    return item.id;
  }
}