// Angular Imports
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';

// Third Party Imports
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';

@Component({
  selector: 'batch-multiple-edit',
  templateUrl: './batch-multiple-edit.component.html',
  styleUrls: ['./batch-multiple-edit.component.scss']
})

export class SchedulerBatchMultipleEditModalComponent implements OnInit {
  public eventFunction: any;
  public quantity: number;
  @ViewChild('ModalForm', { static: true }) public modalForm: NgForm;

  constructor(
    private dialogRef: MatDialogRef<SchedulerBatchMultipleEditModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.eventFunction = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        this.Save();
      }
    }

    document.addEventListener('keyup', this.eventFunction, false); // Submitting popup on press of Enter key
  }

  Cancel() {
    this.dialogRef.close();
    document.removeEventListener('keyup', this.eventFunction, false); // Removing event listener
  }

  Save() {
    if (this.modalForm.valid) {
      this.dialogRef.close(this.quantity);
      document.removeEventListener('keyup', this.eventFunction, false); // Removing event listener
    }
  }
}
