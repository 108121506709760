// Angular Imports
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError, map } from "rxjs/operators";
import { environment } from '../../../environments/environment';
import { TechnologyPlanInstanceView, TechnologyPlanInstance, TechnologyPlanCategoryView, TechnologyPlanCategory, TechnologyPlanPriorityView, TechnologyPlanYear, TechnologyPlanCard, TechnologyPlanCardView, TechnologyPlanMetricsObject } from 'src/platform-app/models/technology-plan.model';

// Services
import { UtilityService } from '../static/utility.service';

// Models

@Injectable()
export class TechnologyPlanService {
    public baseUrl: string = environment.endpoint;

    constructor(
        private http: HttpClient,
        private utilityService: UtilityService
    ) { }

    // Technology Plan Instance

    CreateTechnologyPlanInstanceByBusinessId(businessId: number, instance: TechnologyPlanInstance): Observable<any> {
        const url: string = this.baseUrl + '/api/TechnologyPlan/Business/' + businessId + '/Instance/Create';
        return this.http.post(url, instance).pipe(
            map(this.utilityService.extractData)
        );
    }

    SetTechnologyPlanDefaultInstance(instanceId: number): Observable<any> {
        const url: string = this.baseUrl + '/api/TechnologyPlan/Instance/' + instanceId + '/SetDefault';
        return this.http.put(url, {}).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetTechnologyPlanInstancesByBusinessId(businessId: number): Observable<TechnologyPlanInstanceView[]> {
        const url: string = this.baseUrl + '/api/TechnologyPlan/Business/' + businessId + '/Instance/GetAll';
        return this.http.get<TechnologyPlanInstanceView[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetTechnologyPlanDefaultInstanceByBusinessId(businessId: number): Observable<TechnologyPlanInstanceView> {
        const url: string = this.baseUrl + '/api/TechnologyPlan/Business/' + businessId + '/Instance/GetDefault';
        return this.http.get<TechnologyPlanInstanceView>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetTechnologyPlanInstanceById(instanceId: number): Observable<TechnologyPlanInstanceView> {
        const url: string = this.baseUrl + '/api/TechnologyPlan/Instance/' + instanceId + '/Get';
        return this.http.get<TechnologyPlanInstanceView>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    EditTechnologyPlanInstance(instanceId: number, instance: TechnologyPlanInstance): Observable<any> {
        const url: string = this.baseUrl + '/api/TechnologyPlan/Instance/' + instanceId + '/Edit';
        return this.http.put(url, instance).pipe(
            map(this.utilityService.extractData)
        );
    }

    CopyTechnologyPlanInstance(instanceId: number, name: string): Observable<any> {
        const url: string = this.baseUrl + '/api/TechnologyPlan/Instance/' + instanceId + '/Copy?name=' + name;
        return this.http.post(url, null).pipe(
            map(this.utilityService.extractData)
        );
    }

    DeleteTechnologyPlanInstance(instanceId: number): Observable<any> {
        const url: string = this.baseUrl + '/api/TechnologyPlan/Instance/' + instanceId + '/Delete';
        return this.http.delete(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    // Technology Plan Categories

    CreateTechnologyPlanCategoryByBusinessId(businessId: number, category: TechnologyPlanCategory): Observable<TechnologyPlanCategoryView> {
        const url: string = this.baseUrl + '/api/TechnologyPlan/Business/' + businessId + '/Category/Create';
        return this.http.post<TechnologyPlanCategoryView[]>(url, category).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetTechnologyPlanCategoriesByBusinessId(businessId: number): Observable<TechnologyPlanCategoryView[]> {
        const url: string = this.baseUrl + '/api/TechnologyPlan/Business/' + businessId + '/Category/GetAll';
        return this.http.get<TechnologyPlanCategoryView[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    EditTechnologyPlanCategory(categoryId: number, category: TechnologyPlanCategory): Observable<any> {
        const url: string = this.baseUrl + '/api/TechnologyPlan/Category/' + categoryId + '/Edit';
        return this.http.put<TechnologyPlanCategoryView[]>(url, category).pipe(
            map(this.utilityService.extractData)
        );
    }

    DeleteTechnologyPlanCategory(categoryId: number): Observable<any> {
        const url: string = this.baseUrl + '/api/TechnologyPlan/Category/' + categoryId + '/Delete';
        return this.http.delete<TechnologyPlanCategoryView[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetTechnologyPlanPriorities(): Observable<TechnologyPlanPriorityView[]> {
        const url: string = this.baseUrl + '/api/TechnologyPlan/Priorities';
        return this.http.get<TechnologyPlanCategoryView[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    // Technology Plan Year

    EditTechnologyPlanYear(yearId: number, year: TechnologyPlanYear): Observable<any> {
        const url: string = this.baseUrl + '/api/TechnologyPlan/Year/' + yearId;
        return this.http.put(url, year).pipe(
            map(this.utilityService.extractData)
        );
    }

    CreateTechnologyPlanCard(instanceId: number, card: TechnologyPlanCard): Observable<TechnologyPlanCardView> {
        const url: string = this.baseUrl + '/api/TechnologyPlan/Instance/' + instanceId + '/Card/Create';
        return this.http.post<TechnologyPlanCardView>(url, card).pipe(
            map(this.utilityService.extractData)
        );
    }

    EditTechnologyPlanCard(cardId: number, card: TechnologyPlanCard): Observable<any> {
        const url: string = this.baseUrl + '/api/TechnologyPlan/Card/' + cardId + '/Edit';
        return this.http.put(url, card).pipe(
            map(this.utilityService.extractData)
        );
    }

    DeleteTechnologyPlanCard(cardId: number): Observable<any> {
        const url: string = this.baseUrl + '/api/TechnologyPlan/Card/' + cardId + '/Delete';
        return this.http.delete(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    // Technology Plan Metrics

    GetTechnologyPlanMetricsByInstanceId(instanceId: number): Observable<TechnologyPlanMetricsObject[]> {
        const url: string = this.baseUrl + '/api/TechnologyPlan/Instance/' + instanceId + '/Metrics/GetAll';
        return this.http.get<TechnologyPlanMetricsObject[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }

    GetTechnologyPlanCardsByBusinessId(businessId: number): Observable<any[]> {
        const url: string = this.baseUrl + '/api/TechnologyPlan/Business/' + businessId + '/Card/GetAll';
        return this.http.get<TechnologyPlanCardView[]>(url).pipe(
            map(this.utilityService.extractData)
        );
    }
}