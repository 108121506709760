// Angular Imports
import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { forkJoin } from 'rxjs';

// Services
import { ProductService } from '../../../../services/apis/product.service';
import { RegionService } from '../../../../services/apis/region.service';
import { UtilityService } from 'src/platform-app/services/static/utility.service';

// Models
import { ProductView } from 'src/platform-app/models/product.model';
import { RegionView } from 'src/platform-app/models/region.model';
import { Batch } from 'src/platform-app/models/batch.model';

// Third Party Imports
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';

@Component({
  selector: 'scheduler-batch-modal',
  templateUrl: './batch.component.html',
  styleUrls: ['./batch.component.scss']
})

export class SchedulerBatchModalComponent implements OnInit, OnDestroy {
  public eventFunction: any;
  public products: ProductView[];
  public regions: RegionView[];
  public region: RegionView;
  public batch: Batch;
  public lineId: number;
  public firstBatch: boolean;
  public startDate: Date;
  public startTime: string;
  @ViewChild('ModalForm', { static: true }) public modalForm: NgForm;
  public subscriptions = new Subscription();

  constructor(
    private productService: ProductService,
    private regionService: RegionService,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<SchedulerBatchModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private utilityService: UtilityService
  ) { }

  ngOnInit() {
    this.firstBatch = this.data.firstBatch;
    let familyId = this.data.familyId;
    this.lineId = this.data.lineId;

    if (this.data.batch) {
      this.batch = this.data.batch;

      if (this.firstBatch) {
        let batchDate = moment.utc(this.batch.fromTime).local().format();
        this.startDate = moment(batchDate).toDate();
        this.startTime = moment(batchDate.split("T")[1], ['HH:mm:ss']).format("hh:mm A")
      }
    } else {
      this.batch = new Batch();
    }

    this.subscriptions.add(
      forkJoin([this.productService.GetProductsByFamilyAndLineId(familyId, this.lineId), this.regionService.GetAllRegions()]).subscribe(results => {
        this.products = results[0];
        this.regions = results[1];
      }, (error) => {
        console.log(error);
        this.snackBar.open('Something went wrong. Please, try again later.', 'close', { duration: 2500, panelClass: ['mfg-background-danger'] });
      })
    );

    this.eventFunction = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        this.Save();
      }
    }

    document.addEventListener('keyup', this.eventFunction, false); // Submitting popup on press of Enter key
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    document.removeEventListener('keyup', this.eventFunction, false); // Removing event listener
  }

  RefreshBatch() {
    for (let product of this.products) {
      if (this.batch.productId === product.id) {
        this.batch.rate = product.maxRate;
        this.batch.maxRate = product.maxRate;
      }
    }
  }

  Cancel() {
    this.dialogRef.close();
    document.removeEventListener('keyup', this.eventFunction, false); // Removing event listener
  }

  Save() {
    if (this.modalForm.valid) {
      let dateTime: string;
      if (this.firstBatch) {
        let stringDate = moment(this.startDate).format('YYYY-MM-DD') + 'T' + moment(this.startTime, ["hh:mm A"]).format('HH:mm:ss');
        let date = new Date(stringDate);
        dateTime = moment.utc(moment(date)).format();
      } else {
        dateTime = "2018-12-10T00:00:00";
      }

      this.batch.fromTime = dateTime;
      this.dialogRef.close(this.batch);
      document.removeEventListener('keyup', this.eventFunction, false); // Removing event listener
    }
  }

  TrackByFunction(index, item) {
    return item.id;
  }
}
