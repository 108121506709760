// Angular Imports
import { Component, Input, Output, EventEmitter, OnChanges, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

// Services
import { BlobService } from 'src/platform-app/services/apis/blob.service';

// Models
import { UploadSettings } from 'src/platform-app/models/utilitymodels';

// Third Party Imports
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})

export class FileUploadComponent implements OnChanges, OnDestroy {
  @Input() public uploadSettings: UploadSettings;
  @Input() public originalUrl: any;
  @Input() public cropperSettings: any;

  // New
  public croppedImage: any = '';

  public allowedExtensions = ['.png', '.jpg', '.jpeg', '.JPEG'];
  public uploadActions: Array<string> = ['file', 'a file', 'file', 'Select', 'Upload'];
  public maxSize = 5000000;
  public isImage = false;
  public newUrl: any;

  public dropzoneActive: boolean = false;
  public selectedFile: File;
  public errorMessage: string;
  public showSpinner: boolean = false;

  @Output() passFile: EventEmitter<File> = new EventEmitter;
  @ViewChild('fileInput', { static: false }) public fileinput: any;

  public subscriptions = new Subscription();

  ngOnChanges() {
    if (this.uploadSettings) {
      this.allowedExtensions = this.uploadSettings.allowedExtensions;
      this.uploadActions = this.uploadSettings.uploadActions;
      this.maxSize = this.uploadSettings.maxSize;
    };
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  OnDragOver(event) {
    event.preventDefault();
    this.dropzoneActive = true;
  }

  OnDragLeave(event) {
    event.preventDefault();
    this.dropzoneActive = false;
  }

  OnDrop(event) {
    event.preventDefault();
    if (event.dataTransfer.files.length > 1) {
      this.selectedFile = null;
      this.errorMessage = 'You can upload one file at a time';
    } else if (event.dataTransfer.files.length === 1) {
      this.selectedFile = event.dataTransfer.files[0];
      this.ValidateFile();
    }
  }

  DetectFiles(event) {
    if (event.target.files.length > 1) {
      this.selectedFile = null;
      this.errorMessage = 'You can upload one file at a time';
    } else if (event.target.files.length === 1) {
      this.selectedFile = event.target.files[0];
      let reader = new FileReader();
      reader.onload = e => this.newUrl = reader.result;
      reader.readAsDataURL(this.selectedFile);
      this.ValidateFile();
    }
  }

  ValidateFile() {
    const size = this.selectedFile.size;
    const extension: string = '.' + this.selectedFile.name.split('.').pop();
    if (extension === '.jpeg' || extension === '.jpg' || extension === '.png') {
      this.isImage = true;
    }

    if (size > this.maxSize) {
      this.selectedFile = null;
      this.errorMessage = 'File size is too big';
    } else if (this.allowedExtensions.length > 0 && !this.allowedExtensions.includes(extension)) {
      this.selectedFile = null;
      this.errorMessage = 'File extension is not allowed';
    }
  }

  ImageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event;
  }

  DataURItoBlob(dataURI, fileName) {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([ab], { type: mimeString });
    const b: any = blob;
    b.lastModifiedDate = new Date();
    b.name = fileName;

    return <File>blob;
  }

  ClearFiles() {
    this.errorMessage = null;
    this.selectedFile = null;
    this.dropzoneActive = false;
    this.originalUrl = null;
    this.newUrl = null;

    setTimeout(() => {
      if (this.fileinput) {
        this.fileinput.nativeElement.value = '';
      }
    }, 200);
  }

  UploadFile() {
    this.showSpinner = true;
    let finalFile: File;
    if (this.cropperSettings) {
      finalFile = new File([this.croppedImage.file], this.selectedFile.name, { type: this.selectedFile.type }); // Convert blob to a file
    } else {
      finalFile = this.selectedFile
    }
    this.passFile.emit(finalFile);
    this.ClearFiles();
    this.showSpinner = false
  }
}